/**
 * Version 1.0
 * Vikas W
 * 07.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import IntlMessages from '../../util/IntlMessages';
import { SingleSelect } from 'react-select-material-ui';
import Checkbox from '@material-ui/core/Checkbox';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import { ErrorMessage } from '@hookform/error-message';
import InvoiceRow from './InvoiceRow';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import TableRows from './TableRows';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select2, { SelectChangeEvent } from '@mui/material/Select';
import { CustomeDatePicker } from '../DatePicker';

const CreateCloneQuotation = ({
  modal,
  accTeamId,
  details,
  toggle,
  onInvoicesClose,
  invoices,
  getUserProfileData,
  filterSelectedData,
}) => {
  const {
    InvoiceNumber,
    ClientName,
    CompanyName,
    IBAN,
    InvoicePrefix,
    IsDraft,
    IsPaid,
    IssueDate,
    DueDate,
    child,
  } = details;

  const dispatch = useDispatch();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setValue,
    setError,
  } = useForm({});

  const [products, setProducts] = useState([]);
  const [clients, setClients] = useState([]);
  const [bankAccountNames, setBankAccountNames] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [checked, setChecked] = useState(false);
  const [rowList, setRowList] = useState([]);
  const [quantity, setQuantity] = useState([]);
  const [netAmount, setNetAmount] = useState([]);
  const [productRate, setProductRate] = useState([]);
  const [vatRate, setVatRate] = useState([]);
  const [nextQuoteId, setNextQuoteId] = useState(0);
  const [isCommonVat, setIsCommonVat] = useState(false);
  const [currDueDate, setCurrDueDate] = useState(null);

  const [rowsData, setRowsData] = useState([]);
  const [currency, setCurrency] = useState('euro');
  const [issueDate, setIssueDate] = useState(null);
  const dueDateref = useRef(null);
  const [selectClients, setSelectClients] = useState(true);
  const [selectCompanies, setSelectCompanies] = useState(true);
  const [selectBankAcc, setSelectBankAcc] = useState(true);
  const currencyOptions = [
    { label: 'euro', value: 'euro' },
    { label: 'pound', value: 'pound' },
  ];

  //add date modal
  const [open, setOpen] = React.useState(false);
  const [endOpen, setEndOpen] = React.useState(false);

  const clientNameRef = useRef(null);
  const companyNameRef = useRef(null);
  const bankAccountNamesRef = useRef(null);

  useEffect(() => {
    if (details.length !== 0) {
      setIssueDate(new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' }));
      setCurrDueDate(
        new Date(Date.now() + 30 * 24 * 60 * 60 * 1000).toLocaleDateString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
        }),
      );
      if (child.length > 0 && child[0]['CurrencyCode']) {
        setCurrency(child[0]['CurrencyCode']);
      }
    }
  }, [details]);

  // date function
  const handlestartDateHandler = (dateChange, cardId) => {
    if (details.length === 0) {
      getNextDueDate(dateChange);
    }
    setValue('issueDate', dateChange);
    setIssueDate(dateChange);
    setOpen(false);
  };

  const handledueDateDateHandler = dateChange => {
    setValue('dueDate', dateChange);
    setCurrDueDate(dateChange);
    setEndOpen(false);
  };

  /**
   * Function called when we submit the form
   *
   * @param formData The responses of the form
   */
  const onSubmit = formData => {
    // console.log(isCommonVat);
    // console.log(formData.commonVat);
    // console.log(formData, rowsData, 'formData clone invoice');
    let QuoteItems = [];
    if (details.length !== 0) {
      /* invoice items */
      rowsData.forEach(data => {
        let newQuoteItems = {
          // InvoiceId: data.data,
          Product: data.product['label'],
          ProductId: data.product['value'],
          Quantity: data.quantity,
          ProductRate: data.productRate,
          VatRate: isCommonVat ? formData.commonVat : data.vat,
          Description: data.description,
        };
        QuoteItems.push(newQuoteItems);
      });

      let cloneInvoice = {
        // InvoiceID: details.InvoiceId,
        // InvoiceParams: [
        //   {
        //     TableName: 'Acct_Invoices',
        //     ColumnName: 'IssueDate',
        //     NewValue: issueDate,
        //     PrimaryKeyValue: details.InvoiceId.toString(),
        //   },
        //   {
        //     TableName: 'Acct_Invoices',
        //     ColumnName: 'DueDate',
        //     NewValue: currDueDate,
        //     PrimaryKeyValue: details.InvoiceId.toString(),
        //   },
        //   {
        //     TableName: 'Acct_Invoices',
        //     ColumnName: 'IsPaid',
        //     NewValue: (+checked).toString(),
        //     PrimaryKeyValue: details.InvoiceId.toString(),
        //   },
        // ],
        // InvoiceItems: InvoiceItems,
        // Quote: {
        //   CompanyId: formData.CompanyId.value,
        //   ClientId: formData.ClientId.value,
        //   IssueDate: issueDate,

        //   QuoteNumber: nextQuoteId,
        //   AcctTeamId: accTeamId,
        //   UserEmail: 'test@mail.com',

        // },
        Quote: {
          CompanyId: formData.CompanyId.value,
          ClientId: formData.ClientId.value,
          ClientName: formData.clientName,
          UserEmail: 'test@mail.com',
          IssueDate: issueDate,
          QuoteNumber: quoteValue,
          AcctTeamId: accTeamId,
          CurrencyCode: currency,
          // QuoteItems: QuoteItems,
        },

        QuoteItems: QuoteItems,
      };

      dispatch({ type: FETCH_START });
      // console.log(cloneInvoice, 'cloneInvoice');
      customAxios
        .post('/Quotes/AddQuoteWithItems', cloneInvoice, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          toggle();
          if (filterSelectedData.length === 0) {
            getUserProfileData();
          } else {
            getUserProfileData('editing');
          }
          onInvoicesClose();
        })
        .catch(function(error) {
          if (error.response.status === '400') {
            alert(error);
            var errorList = error.response.data.errors;
            if (errorList) {
              //iterate through errors {} and add key values
              Object.keys(errorList).forEach((key, index) => {
                setError('apiError', {
                  message: errorList[key],
                });
              });
            }
          }
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  const handleChange = event => {
    setChecked(!checked);
  };

  /**
   * For the id's like "product-0", it will increment the last digit of the id (the number)
   */
  const addTableRows = () => {
    const rowsInput = {
      control: control,
      errors: errors,
      products: products,
      product: [],
      quantity: '',
      productRate: '',
      vat: '',
      description: '',
      register: register,
    };
    setRowsData([...rowsData, rowsInput]);
  };
  const deleteTableRows = index => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleRowsChange = (index, event) => {
    const { name, value } = event.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };
  const handleRowsProductChange = (index, value) => {
    const rowsInput = [...rowsData];
    rowsInput[index]['product'] = value;
    setRowsData(rowsInput);
  };

  /**
   * If "isCommonVat" is false, change it to "true", else change it to "false"
   */
  function changeIsCommonVatState() {
    setIsCommonVat(!isCommonVat);
  }

  /**
   * useEffect used to get all the datas from the DB and put them into the good states
   */

  useEffect(() => {
    //Add default row
    const rowsInput = {
      control: control,
      errors: errors,
      products: products,
      product: [],
      quantity: '',
      productRate: '',
      vat: '',
      description: '',
      register: register,
    };
    setRowsData([...rowsData, rowsInput]);

    dispatch({ type: FETCH_START });
    //GET PRODUCTS
    getProductsItem();
    //GET NEXT INVOICE NUMBER
    customAxios
      .get('/Quotes/GetNextQuoteNumber', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setNextQuoteId(data);
          setQuoteValue(data);

          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    //GET CLIENTS
    customAxios
      .get('/Client/GetClients/' + accTeamId, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var client = data.map(c => ({ value: c.Id, label: c.ClientName }));
          setClients(client);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
    //GET ACCOUNT NAMES
    customAxios
      .get('/Bank/GetBankAccounts', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var accounts = data.map(c => ({ value: c.Id, label: c.BankName, iban: c.IBAN }));
          setBankAccountNames(accounts);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    //GET COMPANIES
    customAxios
      .get('/Company/GetCompanies/' + accTeamId, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var companies = data.map(c => ({ value: c.Id, label: c.CompanyName }));
          setCompanies(companies);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    if (IsPaid) {
      setChecked(IsPaid == 'Yes' ? true : false);
    }
  }, []);

  useEffect(() => {
    if (selectClients) {
      if (clients.length > 0) {
        let client = clients.find(c => c.value == clientNameRef.current.getValue().find(c => c.value).value);
        setValue('ClientId', { ...client, label: client.label });
      }
    }
  }, [clients]);

  // useEffect(() => {
  //   if (selectBankAcc) {
  //     if (bankAccountNames.length > 0) {
  //       let bankAccount = bankAccountNames.find(
  //         c => c.value == bankAccountNamesRef.current.getValue().find(c => c.value).value,
  //       );
  //       setValue('BankAccountId', { ...bankAccount, label: bankAccount.label });
  //     }
  //   }
  // }, [bankAccountNames]);

  useEffect(() => {
    if (selectCompanies) {
      if (companies.length > 0) {
        let comapanyId = companies.find(c => c.value == companyNameRef.current.getValue().find(c => c.value).value);
        setValue('CompanyId', { ...comapanyId, label: comapanyId.label });
      }
    }
  }, [companies]);

  useEffect(() => {
    if (child) {
      let data = [];
      child.forEach(child => {
        let rowsInput = {
          control: control,
          errors: errors,
          products: products,
          product: { label: child.Product, value: child.ProductId },
          quantity: child.Quantity,
          productRate: child.ProductRate,
          vat: child.VatRate,
          description: child.QuoteDescription,
          register: register,
        };
        data.push(rowsInput);
      });
      setRowsData([...data]);
    }
    // return () => {
    //   second
    // }
  }, [child, details]);

  /** Get 30+ date **/
  function getNextDueDate(currDate) {
    let tempDate = new Date(currDate);
    tempDate.setDate(tempDate.getDate() + 30);
    let newDueDate = tempDate.toISOString().split('T')[0];
    // DueDate = newDueDate;
    setCurrDueDate(newDueDate);
    setValue('dueDate', newDueDate);

    //  control._fields.dueDate._f.value = newDueDate

    // console.log(dateString);
  }

  /** GET PRODUCTS **/
  function getProductsItem() {
    customAxios
      .get('/Invoice/GetProductItems/' + accTeamId, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var products = data.map(c => ({ value: c.ID, label: c.ItemName }));
          setProducts(products);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }
  /** ADD PRODUCTS **/
  function addProductsItem(value) {
    let newProductName = {
      ProductName: value.toString().trim(),
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Invoice/AddProductItem', newProductName, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getProductsItem();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error);
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  function totalNetAmount() {
    let netAmountAdded = 0;
    let count = 0;
    if (rowsData) {
      rowsData.forEach((data, index) => {
        count = parseInt(+data.quantity * +data.productRate);
        netAmountAdded += count;
      });
    }
    return netAmountAdded;
  }
  function totalTaxAmount() {
    let taxAmountAdded = 0;
    let count = 0;
    if (rowsData) {
      rowsData.forEach((data, index) => {
        if (data.vat !== 0) {
          count = parseInt(+data.quantity * +data.productRate);
          taxAmountAdded += (count * +data.vat) / 100;
        }
      });
    }
    return taxAmountAdded;
  }
  // console.log(details, 'details');

  const selectValueHandler = (value, onChange, selectField) => {
    onChange(value);
    if (selectField == 'clientName') {
      setValue('ClientId', { ...value, label: value.label });
    } else if (selectField == 'companyName') {
      setValue('CompanyId', { ...value, label: value.label });
    } else if (selectField == 'accountName') {
      setValue('BankAccountId', { ...value, label: value.label });
    }
  };

  const min = nextQuoteId;
  const max = 999999999;
  const [quoteValue, setQuoteValue] = useState();

  return (
    <Modal className="invoice modal-box" size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        <IntlMessages id="quotation.cloneQuotation" />
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      <form
        name="myForm"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
          <div className="modal-box-content">
            <div className="row no-gutters">
              <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.quotationNumber" />
                </InputLabel>

                <Controller
                  name="QuoteNumber"
                  control={control}
                  // defaultValue={nextQuoteId}
                  defaultValue={nextQuoteId}
                  render={({ field }) => (
                    // <TextField
                    //   {...field}
                    //   // label={<IntlMessages id="appModule.invoiceNumber" />}
                    //   value={nextQuoteId}
                    //   InputProps={{
                    //     readOnly: true,
                    //   }}
                    //   margin="none"
                    // />
                    <TextField
                      fullWidth
                      type="number"
                      inputProps={{ min, max }}
                      value={quoteValue}
                      onChange={e => {
                        var value = parseInt(e.target.value, 10);

                        if (value > max) value = max;
                        if (value < min) value = min;

                        setQuoteValue(value);
                      }}
                    />
                  )}
                />

                <ErrorMessage
                  errors={errors}
                  name="invoiceNumber"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.clientName" />
                </InputLabel>
                <Controller
                  name="clientName"
                  control={control}
                  rules={{ required: 'This is required' }}
                  defaultValue={details.ClientName ? details.ClientName : ''}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={clients}
                      value={clients.find(c => c.label == value)}
                      ref={clientNameRef}
                      onChange={val => {
                        selectValueHandler(val, onChange, 'clientName');
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="clientName"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div>
              <div className="col-lg-5 d-flex flex-column order-lg-1 invoice_label">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.companyName" />
                </InputLabel>
                <Controller
                  name="companyName"
                  control={control}
                  rules={{ required: 'This is required' }}
                  defaultValue={details.CompanyName ? details.CompanyName : ''}
                  render={({ field: { onChange, value } }) => (
                    <Select
                      options={companies}
                      value={companies.find(c => c.label == value)}
                      ref={companyNameRef}
                      onChange={val => {
                        selectValueHandler(val, onChange, 'companyName');
                      }}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="companyName"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              {/* <div className="col-lg-6 d-flex flex-column order-lg-1 invoice_label">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.accountName" />
                </InputLabel>
                <Controller
                  name="accountName"
                  control={control}
                  rules={{ required: 'This is required' }}
                  defaultValue={IBAN ? IBAN : ''}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      options={bankAccountNames}
                      value={bankAccountNames.find(c => c.iban == value)}
                      onChange={val => {
                        selectValueHandler(val, onChange, 'accountName');
                      }}
                      ref={bankAccountNamesRef}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="accountName"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div> */}
              <div className="col-lg-5 d-flex flex-column invoice_label mb-4 order-lg-1">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.issueDate" />
                </InputLabel>
                {details.length === 0 ? (
                  <Controller
                    name="issueDate"
                    control={control}
                    rules={{ required: 'This is required' }}
                    defaultValue={issueDate}
                    render={({ field }) => {
                      return (
                        // <TextField
                        //   {...field}
                        //   onChange={e => {
                        //     field.onChange(e);
                        //     if (details.length === 0) {
                        //       getNextDueDate(e.target.value);
                        //     }
                        //   }}
                        //   type="date"
                        //   margin="normal"
                        //   style={{ marginTop: '0px' }}
                        // />
                        <div className="DateContainer">
                          <CustomeDatePicker
                            format="DD-MM-YYYY"
                            open={open}
                            OpenDate={() => setOpen(true)}
                            CloseDate={() => setOpen(false)}
                            value={issueDate}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handlestartDateHandler}
                          />
                        </div>
                      );
                    }}
                  />
                ) : (
                  <Controller
                    name="issueDate"
                    control={control}
                    rules={{ required: 'This is required' }}
                    defaultValue={IssueDate ? IssueDate : ''}
                    render={({ field }) => {
                      return (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            format="DD-MM-YYYY"
                            open={open}
                            OpenDate={() => setOpen(true)}
                            CloseDate={() => setOpen(false)}
                            value={issueDate}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handlestartDateHandler}
                          />
                        </div>
                      );
                    }}
                  />
                )}
                <ErrorMessage
                  errors={errors}
                  name="issueDate"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div>

              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              {/* <div className="col-lg-6 d-flex flex-column invoice_label mb-4 order-lg-1">
                <br />
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.dueDate" />
                </InputLabel>
                {details.length === 0 ? (
                 
                  <Controller
                    name="dueDate"
                    control={control}
                    rules={{ required: 'This is required' }}
                    defaultValue={currDueDate}
                    render={({ field }) => {
                      return (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={endOpen}
                            OpenDate={() => setEndOpen(true)}
                            CloseDate={() => setEndOpen(false)}
                            value={currDueDate}
                           
                            handleDateHandler={handledueDateDateHandler}
                          />
                        </div>
                      );
                    }}
                  />
                ) : (
                  <Controller
                    name="dueDate"
                    control={control}
                    rules={{ required: 'This is required' }}
                    defaultValue={currDueDate}
                    render={
                      ({ field }) => {
                        return (
                          <div className="DateContainer">
                            <CustomeDatePicker
                              open={endOpen}
                              OpenDate={() => setEndOpen(true)}
                              CloseDate={() => setEndOpen(false)}
                              value={currDueDate}
                              
                              handleDateHandler={handledueDateDateHandler}
                            />
                          </div>
                        );
                      }
                    
                    }
                  />
                )}
                <ErrorMessage
                  errors={errors}
                  name="dueDate"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div> */}

              <div className="col-lg-12 d-flex flex-column order-lg-1"></div>
            </div>
            <div className="row">
              <table className="table">
                <thead>
                  <tr>
                    <th style={{ width: '90%' }}>&nbsp;</th>
                    <th>
                      <a className="btn btn-outline-success" onClick={addTableRows}>
                        +
                      </a>
                    </th>
                  </tr>
                </thead>
              </table>
              <table className="table">
                <thead>
                  <tr style={{ background: '#d7d3d3' }}>
                    <th>Product</th>
                    <th>Quantity</th>
                    <th>Rate</th>
                    {!isCommonVat && <th>VAT %</th>}
                    <th>Descritpion</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  <TableRows
                    rowsData={rowsData}
                    deleteTableRows={deleteTableRows}
                    handleRowsChange={handleRowsChange}
                    handleRowsProductChange={handleRowsProductChange}
                    allProducts={products}
                    isCommonVat={isCommonVat}
                    addProductsItem={addProductsItem}
                  />
                  <tr style={{ background: '#efefef' }}>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>Total NET Amount</th>
                    <th>
                      {currency === 'euro' && <strong>&euro;</strong>}
                      {currency === 'pound' && <strong>&#163;</strong>}
                      {currency === 'dollar' && <strong>$</strong>}
                      {currency === 'rupee' && <strong>&#8377;</strong>}
                      {totalNetAmount()}
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  <tr style={{ background: '#efefef' }}>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>Total Tax Amount</th>
                    <th>
                      {currency === 'euro' && <strong>&euro;</strong>}
                      {currency === 'pound' && <strong>&#163;</strong>}
                      {currency === 'dollar' && <strong>$</strong>}
                      {currency === 'rupee' && <strong>&#8377;</strong>}
                      {totalTaxAmount()}
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                  <tr style={{ background: '#d7d3d3' }}>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                    <th>Total Gross Amount</th>
                    <th>
                      {currency === 'euro' && <strong>&euro;</strong>}
                      {currency === 'pound' && <strong>&#163;</strong>}
                      {currency === 'dollar' && <strong>$</strong>}
                      {currency === 'rupee' && <strong>&#8377;</strong>}
                      {totalNetAmount() + totalTaxAmount()}
                    </th>
                    <th>&nbsp;</th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="row no-gutters" style={{ display: 'flex', justifyContent: 'left', alignItems: 'center' }}>
            <div className="col-lg-3 d-flex flex-column order-lg-1 pt-3">
              <FormGroup>
                <FormControlLabel control={<Switch />} onChange={changeIsCommonVatState} label="Common VAT" />
              </FormGroup>
            </div>

            {isCommonVat && (
              <div className="col-lg-3 d-flex flex-column order-lg-1">
                {/* <Controller
                   name="commonVat"
                   control={control}
                   defaultValue={'5'}
                   render={({ field }) => (
                     <TextField {...field} label={<IntlMessages id="appModule.commonVat" />} value={'5'} margin="none" />
                   )}
                 />
                 <ErrorMessage errors={errors} name="commonVat" render={({ message }) => <p>{me className="formInputError"ssage}</p>} /> */}
                <Controller
                  name="commonVat"
                  control={control}
                  rules={{ required: 'This is required' }}
                  defaultValue={'1'}
                  render={({ field }) => <TextField {...field} type="number" margin="normal" style={{ marginTop: '0px' }} />}
                />
                <ErrorMessage
                  errors={errors}
                  name="commonVat"
                  render={({ message }) => <p className="formInputError">{message}</p>}
                />
              </div>
            )}

            <div className="col-lg-2 d-flex flex-column order-lg-1">
              <label className="invoice_label" style={{ textAlign: 'left', margin: '0' }}>
                Is paid ?
              </label>
            </div>
            <div
              className="col-lg-1 d-flex flex-column ml-lg-n5 mt-xl-n4 order-lg-1"
              style={{ marginLeft: '-100px !important' }}>
              <br />
              <Controller
                name="isPaid"
                control={control}
                defaultValue={details.IsPaid === 'Yes' ? true : false}
                render={({ field: { value, ref, ...field } }) => (
                  <Checkbox {...field} color="primary" checked={value ? true : false} onClick={handleChange} />
                )}
              />
              <ErrorMessage
                errors={errors}
                name="isPaid"
                render={({ message }) => <p className="formInputError">{message}</p>}
              />
            </div>
            <div className="col-lg-1 d-flex flex-column ml-lg-n5 mt-xl-n4 order-lg-1"></div>
            <div className="col-lg-3 d-flex flex-column order-lg-1">
              <FormControl sx={{ m: 1, minWidth: 120 }} size="small">
                <InputLabel id="currency-select">Currency</InputLabel>
                <Select2
                  labelId="currency-select"
                  id="currency"
                  value={currency}
                  label="Currency"
                  onChange={event => setCurrency(event.target.value)}>
                  {/* <MenuItem value={'euro'}>Euro</MenuItem>
                  <MenuItem value={'pound'}>Pound</MenuItem>
                  <MenuItem value={'dollar'}>Dollar</MenuItem> */}
                  <MenuItem sx={{ fontFamily: 'normal' }} value={'euro'}>
                    € Euro
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'normal' }} value={'pound'}>
                    £ Pound
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'normal' }} value={'dollar'}>
                    $ US Dollar
                  </MenuItem>
                  <MenuItem sx={{ fontFamily: 'normal' }} value={'rupee'}>
                    ₹ Rupee
                  </MenuItem>
                </Select2>
              </FormControl>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            // disabled={details.length === 0 ? false : true}
          >
            <IntlMessages id="quotation.cloneQuotation" />
          </Button>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateCloneQuotation;
