import React, { useEffect, useRef, useState } from 'react';
import useStyles from './index.style';
// import CmtCard from '../../../../@coremat/CmtCard';
// import DeleteIcon from '@material-ui/icons/Delete';
// import CheckBox from '@material-ui/icons/CheckBox';
// import { Box } from '@material-ui/core';
import { getDateText, getFormattedDate } from '../../../../@jumbo/utils/dateHelper';
// import { ChatBubbleOutline } from '@material-ui/icons';
// import CmtCardMedia from '../../../../@coremat/CmtCard/CmtCardMedia';
// import DeleteConfirmation from './components/DeleteConfirmation';
// import CompleteConfirmation from '../components/CompleteConfirmation';
// import CmtAvatarGroup from '../../../../../@coremat/CmtAvatarGroup';
// import CmtObjectSummary from '../../../../../@coremat/CmtObjectSummary';
// import clsx from 'clsx';
// import moment from 'moment';
// import IconButton from '@mui/material/IconButton';
// import MoreVertIcon from '@mui/icons-material/MoreVert';
// import {
//     // Avatar, List, ListItem, ListItemAvatar, ListItemText, Button, FormControl,
//     TextField
// } from "@mui/material";
import Select from 'react-select';
// import { Menu, MenuItem } from '@mui/material';
// import ListItemButton from "@mui/material/ListItemButton";
// import Chip from "@mui/material/Chip";
// import Checkbox from '@mui/material/Checkbox';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';
// import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
// import ListItemIcon from '@mui/material/ListItemIcon';
// import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';

/* Date Picker */
// import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
// import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
// // import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { useDispatch, useSelector } from 'react-redux';
import { setChecked, setSinglArchive, setTmCardData, setTmCardDateFlag } from '../../../../redux/actions';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { getStore } from '../../../../redux/store';
import { kanbanBoard } from './components/kanbanBoard';

// import Autocomplete from '@mui/material/Autocomplete';

const propsAreEqual = (prevProps, nextProps) => {
  return false; // JSON.stringify(prevProps.title) === JSON.stringify(nextProps.title);
};

export const Card = React.memo(props => {
  const dispatch = getStore().dispatch;

  const store = getStore().getState();
  const { tmTeamMembers } = store.common;
  const { projectList } = store.common;
  const { tmSelectedTeam } = store.common;
  // const { cardCheck } = useSelector(state => state.common);
  const { cardCheck } = store.common;

  // const classes = useStyles();
  // const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
  const {
    id,
    StatusId,
    onClick,
    onDelete,
    title,
    tDueDate,
    tmTeamId,
    IsCompleted,
    tRefNumber,
    AssignedTo,
    description,
    laneId,
    ProjectId,
    onChange,
    timeManagementHandler,
    handelCardActions,
    PriorityId,
  } = props;

  // console.log(props, PriorityId);

  const [anchorElCard, setAnchorElCard] = React.useState(null);
  const [isComplet, setIsComplet] = React.useState(IsCompleted ? true : false);
  // const openCard = Boolean(anchorElCard);

  /* Avatar Selection */
  const [openTeam, setOpenTeam] = useState(false);
  const [assignTaskTeam, setAssignTaskTeam] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [assigned, setAssigned] = React.useState([]);
  const [tmMember, setTMMember] = React.useState(null);
  const [project, setProject] = useState();

  /* Date Change handler */
  // const [dueDateValue, setDueDateValue] = React.useState(tDueDate ? new Date(tDueDate) : null);
  // const [openCalendar, setOpenCalendar] = React.useState(false);
  const [updateDate, setUpdateDate] = React.useState(null);
  const [shouldBeFixed, setShouldBeFixed] = useState(true);

  const nodeRef = useRef(null);
  const checkBoxRef = useRef(null);
  const ellipsisOptionRef = useRef(null);

  useEffect(() => {
    /* START: Close/open team selection dropdown on outside click or inside click */
    // const drop = document.querySelectorAll('.customAvatar');
    // drop.forEach(drp => {
    //   drp.addEventListener('click', event => {
    //     const boxes = document.querySelectorAll('.dropdown-s');
    //     for (const box of boxes) {
    //       box.classList.remove(
    //         "dropdown-s-active"
    //       );
    //     }
    //     // event.target.nextElementSibling.classList.add("dropdown-s-active");
    //     setTimeout(() => {
    //       // console.log(event.target.className)
    //       event.target.nextElementSibling.classList.add("dropdown-s-active");
    //     }, 1000);
    //   });
    // });
    // document.getElementById('root').onclick = function (e) {
    //   const drop = document.querySelectorAll('.customAvatar');
    //   // console.log(!e.target.className.includes('customAvatar'))
    //   if (!e.target.className.includes('customAvatar')) {
    //     const boxes = document.querySelectorAll('.dropdown-s');
    //     for (const box of boxes) {
    //       box.classList.remove(
    //         "dropdown-s-active"
    //       );
    //     }
    //   }
    // }
    /* END: Close/open team selection dropdown on outside click or inside click */

    if (tmTeamMembers.length > 0) {
      // console.log(tmTeamMembers);
      var users = tmTeamMembers.map(c => ({ value: c.TMUserId, label: c.Email, TeamId: c.TeamId, IsAdmin: c.IsAdmin }));
      setAssignTaskTeam(users);
    }
    if (tmSelectedTeam) {
      // console.log(tmSelectedTeam, 'tmSelectedTeam');
      setSelectedTeam(tmSelectedTeam);
    }
  }, [tmTeamMembers, tmSelectedTeam]);

  function getTasks(id) {
    if (!id) return;
    try {
      // dispatch({ type: FETCH_START });
      customAxios
        .get('/Task/GetTasks/' + id, { headers: authHeader() })
        .then(({ data, status }) => {
          // dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  const customDropdownStyles = {
    menuList: () => ({
      height: '200px',
      overflowX: 'hidden',
      overflowY: 'scroll',
      scrollbarWidth: '0px',
      webkitScrollbarWidth: 'none',
      msScrollbarWidth: 'none',
    }),
    option: (provided, isSelected) => ({
      ...provided,
      // color: isSelected ? 'red' : 'blue',
    }),
    // container: () => ({
    //   width: 300,
    // }),
    // control: () => ({
    //   border: 'none',
    //   display: 'none',
    //   width: 0,
    // }),
    // menu: () => ({
    //   width: '500px',
    //   height: '500px',
    //   overflowX: 'hidden',
    //   overflowY: 'scroll',
    // }),
    // menuList: () => ({
    //   width: 500,
    //   height: 500,
    //   overflowX: 'hidden',
    //   overflowY: 'scroll',
    //   scrollbarWidth: '0px',
    //   webkitScrollbarWidth: 'none',
    //   msScrollbarWidth: 'none',
    // }),
  };

  const handleClickCard = event => {
    setAnchorElCard(event.currentTarget);
  };
  const handleCloseCard = () => {
    setAnchorElCard(null);
  };

  /* Delete Card */
  const handleClickDelete = event => {
    onCardDelete(event);
  };
  const onCardDelete = event => {
    event.stopPropagation();
    onDelete();
  };

  /* handel cone task */

  const handelClone = event => {
    let cloneObj = {
      title: title,
      description: description,
      laneId: laneId,
      assignTo: AssignedTo,
      dueDate: tDueDate,
    };

    handelCardActions(cloneObj, 'CloneTask');
  };

  // Archive car

  //check data

  const handleArchive = event => {
    const archiveObj = {
      tmTeamId: tmTeamId,
      TaskId: id,
      laneId: laneId,
    };
    dispatch(setSinglArchive(archiveObj));
  };

  /* Trim title for 100 charecters */
  function add3Dots(string, limit) {
    var dots = '...';
    if (string.length > limit) {
      // you can also use substr instead of substring
      string = string.substring(0, limit) + dots;
    }
    return string;
  }

  /* Task Complete checkbox handler */
  const taskCompleteHandler = checked => {
    const bodyEdit = {
      ColumnName: 'IsCompleted',
      PrimaryKeyValue: `${id}`,
      NewValue: checked ? 1 : 0,
      TableName: 'TM_Tasks',
      TeamId: tmTeamId,
    };
    const dispatchObj = [
      {
        ColumnName: 'IsCompleted',
        PrimaryKeyValue: `${id}`,
        NewValue: checked ? 1 : 0,
        TableName: 'TM_Tasks',
        TeamId: tmTeamId,
        StatusId: StatusId,
      },
    ];
    if (checked) {
      timeManagementHandler(bodyEdit, dispatchObj, setIsComplet);
    } else {
      setIsComplet(checked);
      dispatch({ type: FETCH_START });
      customAxios
        .post('/Task/EditTask', bodyEdit, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          dispatch(setChecked(dispatchObj));
        })
        .catch(function(error) {
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  /* Avatar Selection */
  const returnAvatarName = teamId => {
    const teamObj = tmTeamMembers.filter(c => c.TMUserId == teamId);

    if (teamObj.length > 0) {
      let AvImg = teamObj[0].AvatarImage;
      if (AvImg) {
        return (
          <img
            src={`data:image/jpeg;base64,${AvImg}`}
            style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '50%' }}
          />
        );
      } else {
        return teamObj[0].Email.substring(0, 2);
      }
    }
    return 'None';
  };
  const chooseTeamHandler = args => {
    const member = tmTeamMembers.filter(member => member.Email === args.label);
    // console.log(args)
    // console.log(member)
    // console.log(id,
    //   StatusId,

    //   title,
    //   tDueDate,
    //   tmTeamId,
    //   IsCompleted,
    //   tRefNumber,
    //   AssignedTo,
    //   laneId,
    //   ProjectId,)

    //   // 1487 21 'test4\n' null 9 null 5 9 '21' null
    const noteObj = {
      UserIdTo: args.value,
      ActivityId: id,
      NotifyMessage: 'Task-' + `${tRefNumber}` + ' Assgined to you!',
      FunctionalityId: StatusId,
      TeamId: tmTeamId,
      IsClicked: 0,
    };
    setTMMember(member);
    setOpenTeam(false);

    // console.log(args.value);
    setAssigned(args.value);

    const changeObj = {
      ColumnName: 'AssignedTo',
      PrimaryKeyValue: id,
      NewValue: member[0].TMUserId,
      TableName: 'TM_Tasks',
      TeamId: tmTeamId,
    };

    const dispatchObj = [
      {
        ColumnName: 'AssignedTo',
        PrimaryKeyValue: id,
        NewValue: member[0].TMUserId,
        TableName: 'TM_Tasks',
        TeamId: tmTeamId,
        StatusId: StatusId,
      },
    ];
    // console.log(changeObj);
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/EditTask', changeObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        dispatch(setTmCardData(dispatchObj));
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const getProjectFontColor = () => {
    return project.pHexFontColor || 'rgba(0, 0, 0, 0.08)';
  };

  /* Change Due Date Handler */
  useEffect(() => {
    // if (AssignedTo) {
    setAssigned(AssignedTo);
    // }
  }, [AssignedTo]);

  // /* Change Due Date Handler */
  useEffect(() => {
    if (ProjectId) {
      const currentProject = projectList.find(instance => instance.pId === ProjectId);
      if (currentProject?.pId) {
        setProject({ ...currentProject, label: currentProject.pName, value: currentProject.pId, isSelectedProject: true });
      }
    }
  }, [ProjectId, projectList]);

  const getProjectBackGroundColor = () => {
    return project.pHexColor || 'rgba(0, 0, 0, 0.08)';
  };

  const openDatePciker = () => {
    const picker = [
      {
        id: laneId,
        cardId: id,
        status: StatusId,
        tDueDate: tDueDate,
        tmTeamId: tmTeamId,
      },
    ];
    dispatch(setTmCardDateFlag(picker));
  };

  const avatarClickHandler = () => {
    setOpenTeam(!openTeam);
    // const avatarObj = [
    //   {
    //     cardId: id,
    //     laneId: StatusId,
    //   }
    // ];
    // dispatch(setTmCardDropdownCheck(avatarObj));
  };
  // const handleClose = () => {
  //   setOpenTeam(prevstate => {
  //     return false;
  //   });
  // };
  const handleClickOutside = event => {
    if (ellipsisOptionRef.current && !ellipsisOptionRef.current.contains(event.target)) {
      ellipsisOptionRef.current.classList.remove('btn-currentActive');
    }
    // console.log(event)
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setOpenTeam(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const handleDropDownBtnClick = () => {
    ellipsisOptionRef.current.classList.toggle('btn-currentActive');
  };

  const getPriorityName = () => {
    let id = PriorityId;

    let name = 'Lowest';
    if (id == 1) {
      name = 'Critical';
    } else if (id == 2) {
      name = 'High';
    } else if (id == 3) {
      name = 'Medium';
    } else if (id == 4) {
      name = 'Low';
    }
    return name;
  };
  const getPriorityColor = () => {
    let id = PriorityId;

    let color = '#0000FF';
    if (id == 1) {
      color = '#FF0000';
    } else if (id == 2) {
      color = '#FFA500';
    } else if (id == 3) {
      color = '#FFFF00';
    } else if (id == 4) {
      color = '#008000';
    }
    return color;
  };
  const getPriorityTextColor = () => {
    let id = PriorityId;
    let color = '#FFFFFF';
    if (id == 1) {
      color = '#FFFFFF';
    } else if (id == 2) {
      color = '#000000';
    } else if (id == 3) {
      color = '#000000';
    } else if (id == 4) {
      color = '#FFFFFF';
    }
    return color;
  };
  // console.log(ProjectId, 'steam');
  return (
    <article className="articleCard">
      <div className="tm-dropdown" style={{ position: 'absolute', right: 5, padding: 5, top: 5, cursor: 'pointer' }}>
        <button className="tm-dropbtn" onClick={handleDropDownBtnClick}>
          <i className="gg-more-vertical-alt"></i>
        </button>
        <div ref={ellipsisOptionRef} className="tm-dropdown-content">
          <a
            href="#"
            onClick={handleClickDelete}
            className={selectedTeam && selectedTeam.IsAdmin ? 'btn-active' : 'btn-disabled'}>
            Delete
          </a>
          <a
            href="#"
            onClick={handleArchive}
            className={selectedTeam && selectedTeam.IsAdmin ? 'btn-active' : 'btn-disabled'}>
            Archive
          </a>
          <a href="#" onClick={handelClone} className="btn-active">
            Clone Task
          </a>
        </div>
      </div>
      <p
        style={{
          marginBottom: 10,
          cursor: 'pointer',
          display: 'flex',
          justifyContent: 'space-between',
          paddingRight: '20px',
        }}
        onClick={onClick}>
        <strong>
          {selectedTeam?.TMPrefix}:{tRefNumber}
        </strong>
        {!PriorityId ? null : (
          <>
            <div
              style={{
                alignItems: 'center',
                cursor: 'pointer',
                backgroundColor: getPriorityColor(),
                width: 'fit-content',
                padding: '3px 9px',
                borderRadius: '13px',
                color: getPriorityTextColor(),
                fontSize: '12px',
                marginBlock: 'auto',
              }}>
              <span>{getPriorityName()}</span>
            </div>
          </>
        )}
      </p>
      <p style={{ marginBottom: 10, cursor: 'pointer' }} onClick={onClick}>
        {add3Dots(title, 100)}{' '}
      </p>
      {!project || !ProjectId ? null : (
        <>
          <div
            style={{
              alignItems: 'center',
              cursor: 'pointer',
              backgroundColor: getProjectBackGroundColor(),
              width: 'fit-content',
              padding: '3px 9px',
              borderRadius: '13px',
              color: getProjectFontColor(),
            }}
            onClick={onClick}>
            <span onClick={onClick}>{project?.label || ''}</span>
          </div>
        </>
      )}

      <div style={{ marginTop: 15, display: 'flex', fontSize: 12, alignItems: 'center' }}>
        <p className="customAvatar" onClick={() => avatarClickHandler()}>
          {assignTaskTeam && returnAvatarName(assigned)}
        </p>
        <span className="dropdown-s" ref={nodeRef}>
          {openTeam && (
            <label
              style={{ position: 'absolute', bottom: -5, left: 38, width: '84%', zIndex: 9 }}
              className="card_selectBox">
              <Select
                styles={customDropdownStyles}
                menuIsOpen={openTeam}
                menuPlacement="auto"
                // menuPosition="fixed"
                // menuPortalTarget={document.body}
                placeholder={'Select Team..'}
                options={assignTaskTeam}
                value={assignTaskTeam.find(c => c.value == (tmMember ? tmMember.value : AssignedTo))}
                onChange={chooseTeamHandler}
              />
            </label>
          )}
        </span>
        <div className="task-list-duedate">
          <CalendarMonthIcon
            style={{
              width: 27,
              height: 27,
              color: '#BDBDBD',
              cursor: 'pointer',
              marginTop: 0,
              paddingRight: 5,
              boxSizing: 'content-box',
            }}
            onClick={openDatePciker}
          />
        </div>
        {updateDate ? (
          <span className="card-date" style={{ marginRight: 5 }}>
            {getDateText(getFormattedDate(updateDate, 'MMM DD, YYYY'))}
          </span>
        ) : (
          tDueDate && (
            <span className="card-date" style={{ marginRight: 5 }}>
              {getDateText(getFormattedDate(tDueDate, 'MMM DD, YYYY'))}
            </span>
          )
        )}

        <label className="tm-container" style={{ position: 'absolute', right: 5, bottom: 22 }}>
          <input
            type="checkbox"
            ref={checkBoxRef}
            // disabled={isComplet}
            checked={isComplet}
            onChange={e => taskCompleteHandler(e.target.checked)}
          />
          <span className="tm-checkmark"></span>
        </label>
      </div>
    </article>
  );
}, propsAreEqual);
