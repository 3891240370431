import React, { useState } from 'react';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import { Button, Tooltip, Typography, Card, CardContent } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import { useForm, Controller } from 'react-hook-form';
import InputLabel from '@material-ui/core/InputLabel';
import { ErrorMessage } from '@hookform/error-message';
import moment from 'moment'; // Import moment.js
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';
import IntlMessages from 'routes/Pages/util/IntlMessages';

const TaskFilterDialog = props => {
  const { setCustomData, setIsFilterApplied, originalData } = props;
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);

  const { control, handleSubmit } = useForm({});

  const [openFilterDialog, setOpenFilterDialog] = useState(false);
  const closeFilterDialog = () => {
    setOpenFilterDialog(false);
  };
  const handleClose = () => {
    closeFilterDialog();
  };
  const [open, setOpen] = useState(false);
  const [endOpen, setEndOpen] = useState(false);
  /**
   * Function called when we submit the form
   *
   * @param formData The responses of the form
   */
  const onSubmit = formData => {
    let data = originalData.filter(row => {
      let filterPass = true;
      const IssueDate = moment(row.ExpenseDateTime); // Parse the date string
      if (startDateFilter && endDateFilter) {
        filterPass =
          filterPass &&
          moment(startDateFilter).isSameOrBefore(IssueDate) && // Check if start date is before or equal
          moment(endDateFilter).isSameOrAfter(IssueDate); // Check if end date is after or equal
      }
      return filterPass;
    });
    console.log({ data });
    setCustomData(data);
    setOpenFilterDialog(false);
    // setIsFilterApplied(true);
  };

  const handleClearButton = () => {
    setStartDateFilter(null);
    setEndDateFilter(null);
    // setIsFilterApplied(false);
    setCustomData(originalData);
  };

  return (
    <div>
      <Tooltip
        title={
          <div>
            <Typography>Advance Filters</Typography>
          </div>
        }
        style={{ background: 'white' }}
        arrow={true}>
        <Button
          variant="contained"
          className="px-2"
          onClick={() => {
            if (!openFilterDialog) {
              setOpenFilterDialog(true);
            }
          }}
          style={{
            height: 40,
            minWidth: '25px',
            background: 'none',
            color: '#3f51b5',
          }}>
          <FilterAltIcon />
        </Button>
      </Tooltip>
      <Dialog maxWidth="md" open={openFilterDialog} onClose={handleClose}>
        <DialogTitle style={{ borderBottom: '1px solid rgba(0,0,0,0.250)' }}>
          <div className="w-100 d-flex justify-content-between ">
            <div>Advance Filters</div>
            <div className="d-flex" style={{ gap: '2rem' }}>
              <div onClick={handleClose} style={{ cursor: 'pointer' }}>
                <CloseRoundedIcon />
              </div>
            </div>
          </div>
        </DialogTitle>
        <DialogContent className="p-0">
          <Card style={{ marginBottom: '1rem', border: 'none', boxShadow: 'none', paddingInline: '0' }}>
            <CardContent component="div" style={{ padding: '1.5rem', width: '600px', height: '200px' }}>
              <div style={{ fontSize: '1.2rem', color: 'black' }}>Expense Date</div>
              <form
                name="myForm"
                onSubmit={handleSubmit(onSubmit)}
                style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
                <div className="row no-gutters">
                  <div className="col-lg-5 d-flex flex-column invoice_label mb-4 order-lg-1">
                    <br />
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.startDate" />
                    </InputLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      render={({ field }) => (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={open}
                            OpenDate={() => setOpen(true)}
                            CloseDate={() => setOpen(false)}
                            value={startDateFilter}
                            handleDateHandler={dateChange => setStartDateFilter(dateChange)}
                          />
                        </div>
                      )}
                    />
                  </div>

                  <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                  <div className="col-lg-6 d-flex flex-column invoice_label mb-4 order-lg-1">
                    <br />
                    <InputLabel htmlFor="age-native-helper">
                      <IntlMessages id="appModule.endDate" />
                    </InputLabel>
                    <Controller
                      name="endDate"
                      control={control}
                      render={({ field }) => (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={endOpen}
                            OpenDate={() => setEndOpen(true)}
                            CloseDate={() => setEndOpen(false)}
                            value={endDateFilter}
                            handleDateHandler={dateChange => setEndDateFilter(dateChange)}
                          />
                        </div>
                      )}
                    />
                  </div>
                </div>
                <Button type="submit" variant="contained" color="primary">
                  <IntlMessages id="invoices.apply" />
                </Button>
                <Button sx={{ ml: 1 }} onClick={handleClearButton}>
                  Clear
                </Button>
              </form>
            </CardContent>
          </Card>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default TaskFilterDialog;
