import * as React from 'react';
import { useState, useEffect } from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineOppositeContent from '@mui/lab/TimelineOppositeContent';
import TimelineDot from '@mui/lab/TimelineDot';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { timelineOppositeContentClasses } from '@mui/lab/TimelineOppositeContent';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import authHeader from '../../../../../services/auth/jwt/auth-header';

// import { convertBase64 } from '../../../../../utils/convertBase64';

export default function TimelineTaskHistory({ selectedCard }) {
  const dispatch = useDispatch();
  const [taskHistory, setTaskHistory] = useState([]);
  useEffect(() => {
    const bodyDescription = {
      taskId: selectedCard.id,
      tmTeamId: selectedCard.tmTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Task/GetTaskHistory', bodyDescription, { headers: authHeader(), responseType: 'array' })
      .then(async res => {
        setTaskHistory(res.data);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }, [selectedCard]);

  const dateFormater = date => {
    const dateString = date;
    const dateObj = new Date(dateString);

    const hours = dateObj
      .getHours()
      .toString()
      .padStart(2, '0');
    const minutes = dateObj
      .getMinutes()
      .toString()
      .padStart(2, '0');
    const day = dateObj
      .getDate()
      .toString()
      .padStart(2, '0');
    const month = (dateObj.getMonth() + 1).toString().padStart(2, '0');
    const year = dateObj.getFullYear().toString();

    return ` ${day}/${month}/${year} ${hours}:${minutes}`;
  };

  return (
    <Timeline
      sx={{
        [`& .${timelineOppositeContentClasses.root}`]: {
          flex: 0.01,
        },
        padding: '0px',
      }}>
      {taskHistory.map((item, index) => {
        return (
          <div key={item.Id}>
            <TimelineItem>
              <TimelineOppositeContent sx={{ m: 'auto 0' }} align="right" variant="body2" color="text.secondary">
                {dateFormater(item.ModifiedAtTime)}
              </TimelineOppositeContent>
              <TimelineSeparator>
                <TimelineConnector />
                <TimelineDot>
                  <ChangeCircleIcon />
                </TimelineDot>
                <TimelineConnector />
              </TimelineSeparator>
              <TimelineContent sx={{ py: '12px', px: 2 }}>
                <Typography variant="h6" sx={{ fontSize: '16px' }} component="span" color="text.secondary">
                  {item.ModifiedByName}
                </Typography>
                <Typography sx={{ fontSize: '13px' }}>{item.ColumnName}</Typography>
              </TimelineContent>
            </TimelineItem>
          </div>
        );
      })}
    </Timeline>
  );
}
