import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

import AppTextInput from '../../../../../@jumbo/components/Common/formElements/AppTextInput';
import { idGenerator } from '../../../../../@jumbo/utils/commonHelper';
import { kanbanBoard } from '../../../../../routes/Pages/Planning/TaskmanagementPage/components/kanbanBoard';
import CmtCard from '../../../../../@coremat/CmtCard';
import useStyles from './index.style';

import customAxios from '../../../util/Api';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import AddNewTask from '@jumbo/components/Common/formElements/AddNewTask';

const AddNewCard = ({ onAdd, onCancel, laneId }) => {
  const user = kanbanBoard.members[0];
  const classes = useStyles();
  const [title, setTitle] = useState('');
  const [dueDate, setDueDate] = useState();
  const [selectMember, setSelectMember] = useState(null);
  const onCardSave = () => {
    if (title) {
      const card = {
        title,
        laneId: laneId,
        description: '',
        dueDate,
        assignTo: selectMember,
        memberIds: [],
        activities: [
          {
            id: idGenerator(),
            user,
            content: 'added this card to Tasks.',
            type: 'activity',
            createdAt: new Date(),
          },
        ],
      };
      onAdd(card);
    }
  };

  const handleDueDateChange = date => {
    setDueDate(date);
  };

  const inputTeamChangeHandler = member => {
    let memberId = member[0].TMUserId;
    setSelectMember(memberId);
  };
  return (
    <CmtCard className={[classes.root, 'tmNewCardForm']}>
      <Box p={4}>
        <Box mb={4}>
          <AddNewTask
            placeholder="Enter a title..."
            autoFocus
            fullWidth
            multiline
            value={title}
            onBlur={onCardSave}
            onChange={e => setTitle(e.target.value)}
            variant="outlined"
            handleDueDateChange={handleDueDateChange}
            inputTeamChangeHandler={inputTeamChangeHandler}
            {...{
              dueDate,
              selectMember,
              onCancel,
            }}
          />
        </Box>
        <Box display="flex" alignItems="center">
          {/* <Button variant="contained" color="primary" size="small" onClick={onCardSave}>
            Add Card
          </Button> */}
          <Box ml={1} style={{ position: 'absolute', top: 21, right: 17 }}>
            <IconButton size="small" onClick={onCancel} className="tmNewCloseButton">
              <Close fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </CmtCard>
  );
};

export default AddNewCard;
