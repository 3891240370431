import React from 'react';
import ContentLoader, { Facebook } from 'react-content-loader';

const CardLane = props => (
  <div style={{ backgroundColor: 'white', width: '250px', margin: '10px auto', borderRadius: '5px' }}>
    <ContentLoader
      speed={2}
      width={250}
      height={160}
      viewBox="0 0 250 160"
      backgroundColor="#d9d9d9"
      foregroundColor="#ededed"
      {...props}>
      <rect x="235" y="6" rx="4" ry="4" width="4" height="4" />
      <rect x="235" y="12" rx="4" ry="4" width="4" height="4" />
      <rect x="235" y="20" rx="4" ry="4" width="4" height="4" />
      <rect x="8" y="6" rx="4" ry="4" width="220" height="20" />
      <rect x="8" y="40" rx="4" ry="4" width="230" height="38" />
      <rect x="8" y="87" rx="20" ry="20" width="120" height="30" />
      <rect x="8" y="128" rx="20" ry="20" width="20" height="20" />
      <rect x="30" y="128" rx="4" ry="4" width="20" height="20" />
      <rect x="55" y="128" rx="4" ry="4" width="80" height="20" />
      <rect x="220" y="128" rx="4" ry="4" width="20" height="20" />
    </ContentLoader>
  </div>
);

const LaneHeader = props => (
  <div style={{ width: '260px', marginTop: '10px' }}>
    <ContentLoader
      speed={2}
      width={260}
      height={80}
      viewBox="0 0 260 80"
      backgroundColor="#fff"
      foregroundColor="#ededed"
      {...props}>
      <rect x="250" y="6" rx="4" ry="4" width="6" height="6" />
      <rect x="250" y="14" rx="4" ry="4" width="6" height="6" />
      <rect x="250" y="22" rx="4" ry="4" width="6" height="6" />
      <rect x="8" y="6" rx="4" ry="4" width="230" height="24" />
      <rect x="8" y="40" rx="4" ry="4" width="245" height="38" />
    </ContentLoader>
  </div>
);

export const CustomContentLoader = props => {
  return (
    <div style={{ backgroundColor: 'rgb(227 227 227)', width: '270px' }}>
      <LaneHeader />
      <CardLane />
      <CardLane />
      <CardLane />
      <CardLane />
      <CardLane />
      <CardLane />
    </div>
  );
};
