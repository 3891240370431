import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { registerLicense } from '@syncfusion/ej2-base';

////Error Boundry
import ErrorBoundary from 'routes/Pages/ErrorPage/ErrorBoundry';
import ErrorPage from 'routes/Pages/ErrorPage/ErrorPage';

// microsoft graph api integrations
import {
  PublicClientApplication,
  EventType,
  // EventMessage,
  // AuthenticationResult
} from '@azure/msal-browser';
import config from './microsoft-context/Config';

const msalInstance = new PublicClientApplication({
  auth: {
    clientId: config.appId,
    redirectUri: config.redirectUri,
  },
  cache: {
    // cacheLocation: 'sessionStorage',
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: true,
  },
});

const accounts = msalInstance.getAllAccounts();
if (accounts && accounts.length > 0) {
  msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback(EventMessage => {
  if (EventMessage.eventType === EventType.LOGIN_SUCCESS && EventMessage.payload) {
    // Set the active account - this simplifies token acquisition
    const authResult = EventMessage.payload;
    msalInstance.setActiveAccount(authResult.account);
  }
});

// Registering Syncfusion license key
registerLicense('ORg4AjUWIQA/Gnt2VVhhQlFaclhJXGFWfVJpTGpQdk5xdV9DaVZUTWY/P1ZhSXxRdkBiWX5ac3xXR2VZUEc=');

ReactDOM.render(
  // <React.StrictMode>
  <>
    <ErrorBoundary fallback={<ErrorPage />}>
      <App pca={msalInstance} />
    </ErrorBoundary>
  </>,
  // </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
