import { Button } from '@material-ui/core';
import React, { useState } from 'react';
import IntlMessages from 'routes/Pages/util/IntlMessages';
import FormModal from './FormModal';
import Reports from './Reports';
import { FormControl } from '@mui/material';
import Select from 'react-select';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import ToggleView from './ToggleView';
import PowerBIReport from './PowerBIReport';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Snackbar from '@mui/material/Snackbar';
import { NavLink } from 'react-router-dom';
import ConfirmationModal from 'routes/Pages/Planning/TaskmanagementPage/ConfirmationModal';

const UploadPBIX = () => {
  const [formModal, setFormModal] = useState(false);
  const [reportsUploadedByYou, setReportsUploadedByYou] = useState([]);
  const [reportsUploadedByTeam, setReportsUploadedByTeam] = useState([]);
  const [accountInfo, setAccountInfo] = useState({});
  const [allUserDetails, setAllUserDetails] = useState([]);
  const [viewType, setViewType] = useState('list'); // State to toggle between list and grid view
  const [tokenConfig, setTokenConfig] = useState({});
  const [loadingTeamReports, setLoadingTeamReports] = useState(true);
  const [fullScreenModalOpen, setFullScreenModalOpen] = useState(false); // State to control the full-screen modal
  const [currentTeamMember, setCurrentTeamMember] = useState(null);
  const [exisitngReportId, setExistingReportId] = useState(null);

  //leave team and leave team confirmation
  //confirmation modal
  const defaultConfirmationModalObj = {
    isModalOpen: false,
    handleSave: null,
    handleClose: null,
    modalTitle: '',
    modalMainText: '',
    hideCloseBtn: false,
    cancelBtnTxt: null,
    saveBtnText: null,
  };
  const [confirmationData, setConfirmationData] = useState({ ...defaultConfirmationModalObj });

  //local storage variables for teams selection
  const [storedTeam, setStoredTeam] = useState(() => {
    const storedValue = localStorage.getItem('storedTeam');
    return storedValue ? JSON.parse(storedValue) : {};
  });
  const handleFullScreenModalOpen = () => {
    setFullScreenModalOpen(true);
  };

  const handleFullScreenModalClose = () => {
    setFullScreenModalOpen(false);
  };

  const toggle = () => {
    setFormModal(preV => !preV);
  };
  const dispatch = useDispatch();
  //Account teams
  const [accTeam, setAccTeam] = useState(null);
  const [accTeamAll, setAccTeamAll] = useState([]);

  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  const action = (
    <React.Fragment>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('EmbeddedAnalytics/GetTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data && data.length > 0) {
            let allteam = data.map(c => ({ value: c.Id, label: c.EATeamName, IsAdmin: c.IsAdmin }));

            // setAccTeam(allteam[0].value);
            setAccTeamAll(allteam);
            const lastSelectedTeam = data?.find(element => element.Id === storedTeam.value);

            // setAccTeam(allteam[0].value);
            if (lastSelectedTeam === undefined) {
              setAccTeam(allteam[0].value);
            } else {
              setAccTeam(lastSelectedTeam.Id ? lastSelectedTeam.Id : allteam[0].value);
            }

            dispatch({ type: FETCH_SUCCESS });
          } else {
            setAccTeam(null);
            dispatch({ type: FETCH_SUCCESS });
          }
          return true;
        })
        .catch(error => {
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getAccountInfo = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('Users/GetUserProfile', { headers: authHeader() })
        .then(({ data }) => {
          setAccountInfo(data);
        })
        .catch(error => {
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getAccessToken = reportId => {
    // dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`EmbeddedAnalytics/GetEmbedModel?TeamId=${accTeam}&ReportId=${reportId}`, { headers: authHeader() })
        .then(({ data }) => {
          if (data.IsValid) {
            setTokenConfig(data);
            handleFullScreenModalOpen();
            setExistingReportId(reportId);
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Failed to load Power-Bi report' });
          }
        })
        .catch(error => {
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getAllUserDetails = () => {
    if (accTeam) {
      dispatch({ type: FETCH_START });
      try {
        customAxios
          .get(`EmbeddedAnalytics/GetTeamMembers?EATeamId=${accTeam}`, { headers: authHeader() })
          .then(({ data }) => {
            setAllUserDetails(data);
            const currUser = data.find(element => element.EAUserId === accountInfo.UserId);
            setCurrentTeamMember(currUser);
          })
          .catch(error => {
            return false;
          });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
    }
  };

  const getTeamReports = () => {
    if (accountInfo) {
      dispatch({ type: FETCH_START });
      try {
        customAxios
          .get(`EmbeddedAnalytics/GetTeamReports?TeamId=${accTeam}`, { headers: authHeader() })
          .then(({ data }) => {
            const yourUserId = accountInfo.UserId;

            const teamReports = data.filter(report => report.UserID !== yourUserId);
            const yourUploadedReports = data.filter(report => report.UserID === yourUserId);

            // console.log(data, accTeam, 'report data');

            setReportsUploadedByYou(yourUploadedReports);
            setReportsUploadedByTeam(teamReports);
            setLoadingTeamReports(false);
          })
          .catch(error => {
            // Handle the error
            setLoadingTeamReports(true);
            console.error(error);
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          });
      } catch (e) {
        // Handle any other exceptions
        console.error(e);
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
    }
  };

  const deleteReport = async reportId => {
    const bodyParameters = {
      TeamId: accTeam,
      ReportId: reportId,
    };

    try {
      const response = await customAxios.post('EmbeddedAnalytics/DeleteReport', bodyParameters, {
        headers: authHeader(),
      });
      getTeamReports();
      console.log('File deleted successfully:', response.data);
      dispatch({ type: FETCH_SUCCESS, payload: 'File Deleted Successfully' });
    } catch (error) {
      console.error('Error deleting file:', error);
      if (error.response) {
        console.log('Error response from the server:', error.response.data);
      } else if (error.request) {
        console.log('No response received:', error.request);

        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      } else {
        console.log('Error details:', error.message);

        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      }
    }
  };

  const onTeamChangeHandler = args => {
    setAccTeam(args.value);

    localStorage.setItem('storedTeam', JSON.stringify(args));
  };

  useEffect(() => {
    const handleStorageChange = event => {
      if (event.key === 'storedTeam') {
        setStoredTeam(JSON.parse(event.newValue));
      }
    };

    window.addEventListener('storage', handleStorageChange);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, []);
  useEffect(() => {
    getTeams(); // This effect initializes accTeamAll and sets the initial value of accTeam
  }, []); // An empty dependency array ensures this effect runs once on component mount

  useEffect(() => {
    getAccountInfo(); // This effect fetches the user account information --- we need to have it in the global scope
  }, []); // An empty dependency array ensures this effect runs once on component mount

  useEffect(() => {
    if (accTeam !== null && Object.keys(accountInfo).length > 0) {
      getTeamReports(); // This effect depends on both accTeam and accountInfo
    }
  }, [accTeam, accountInfo]); // Only runs when accTeam or accountInfo changes

  useEffect(() => {
    getAllUserDetails(); // This effect fetches all user details
  }, [accTeam]); // An empty dependency array ensures this effect runs once on component mount

  const handleReportsView = value => {
    setViewType(value);
  };

  const [accessToken, setAccessToken] = useState('');

  // Function to fetch new access token
  const fetchNewToken = async reportId => {
    if (reportId) {
      try {
        // Fetch the new token
        const response = await customAxios.get(`EmbeddedAnalytics/GetEmbedModel?TeamId=${accTeam}&ReportId=${reportId}`, {
          headers: authHeader(),
        });

        const { data } = response;
        if (data.IsValid) {
          // Update the token configuration and access token
          console.alert('running');
          console.log('running runiingansdfkjnasdjhbfdhjasbdjfhbashj');
          setTokenConfig(data);
          setAccessToken(data.EmbedAccessToken);
        } else {
          // Handle invalid token response
          // You might want to implement your error handling logic here
          console.error('Failed to retrieve a valid token');
        }
      } catch (error) {
        // Handle token fetch errors
        console.error('Error fetching token:', error);
      }
    }
  };

  // Function to check and refresh token before expiry
  const checkAndRefreshToken = () => {
    const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
    const tokenValidityWindow = 60; // Refresh token 60 seconds before expiry

    if (tokenConfig.ExpiryTimestamp - currentTime < tokenValidityWindow) {
      fetchNewToken(tokenConfig.EmbedReportId);
    }
  };

  // UseEffect to handle token refresh
  useEffect(() => {
    if (tokenConfig.EmbedAccessToken) {
      // Set the initial access token from the fetched token configuration
      setAccessToken(tokenConfig.EmbedAccessToken);

      // Check and refresh token periodically
      const interval = setInterval(() => {
        checkAndRefreshToken();
      }, 5000); // Check every 5 seconds (adjust this interval as needed)

      // Cleanup interval on component unmount
      return () => clearInterval(interval);
    }
  }, [checkAndRefreshToken, tokenConfig]);

  // Fetch initial token
  useEffect(() => {
    // Assuming you have the initial report ID to fetch the token

    fetchNewToken(exisitngReportId);
  }, []);

  // leave team and confirmation dialog
  function closeConfirmationModal() {
    setConfirmationData(defaultConfirmationModalObj);
  }

  function openteamLeaveModal() {
    let confirmationObj = { ...confirmationData };
    confirmationObj.handleClose = closeConfirmationModal;
    confirmationObj.modalTitle = 'Leave Team';

    let currTeam = accTeamAll.find(c => c.value === accTeam);

    if (currTeam.IsAdmin) {
      confirmationObj.hideCloseBtn = true;

      confirmationObj.modalMainText = ' You can not leave this team, as you are an admin';
      confirmationObj.saveBtnText = 'Ok';
      confirmationObj.handleSave = closeConfirmationModal;
    } else {
      confirmationObj.hideCloseBtn = false;
      confirmationObj.modalMainText = ' Do you want to leave this team?';
      confirmationObj.saveBtnText = 'Yes';
      confirmationObj.cancelBtnTxt = 'No';
      confirmationObj.handleSave = leaveTeam;
    }

    confirmationObj.isModalOpen = true;
    setConfirmationData(confirmationObj);
  }

  function leaveTeam() {
    let currTeam = accTeamAll.find(c => c.value === accTeam);
    let obj = {
      EATeamId: currTeam.value,
      EAMemberUserId: accountInfo.UserId,
    };
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .post('EmbeddedAnalytics/DeleteTeamMembers', obj, { headers: authHeader() })
        .then(({ data }) => {
          dispatch({ type: FETCH_SUCCESS });

          closeConfirmationModal();
          localStorage.removeItem('storedTeam');
          getTeams();
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  return (
    <div className="app-wrapper">
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center mb-5"
        style={{ justifyContent: 'flex-start', marginTop: 15 }}>
        {/* <div className="content-horizontal__center">
          <h4>
            <IntlMessages id="pages.uploadpbix" />
          </h4>
        </div> */}
        <Snackbar open={open} autoHideDuration={6000} onClose={handleClose} message="New Report Uploaded" action={action} />
        <FormControl style={{ marginRight: 15, minWidth: 250, textAlign: 'left' }} size="small">
          <Select
            style={{ textAlign: 'left' }}
            placeholder={'Select Team..'}
            options={accTeamAll}
            value={accTeamAll.find(c => c.value === accTeam)}
            onChange={onTeamChangeHandler}
          />
        </FormControl>
        <div className="content-horizontal__center" style={{ gap: '10px' }}>
          <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={toggle}>
            Upload Report
          </Button>
          <Button variant="contained" color="primary" style={{ textTransform: 'none' }} onClick={openteamLeaveModal}>
            Leave Team
          </Button>
        </div>
        <FormModal
          formModal={formModal}
          handleClick={handleClick}
          accTeam={accTeam}
          setFormModal={setFormModal}
          toggle={toggle}
          getTeamReports={getTeamReports}
        />
      </div>
      <div>
        <div>
          <ToggleView handleReportsView={handleReportsView} viewType={viewType} />
          {/* <Button variant="contained" onClick={() => handleReportsView('list')} disabled={viewType === 'list'}>
            List View
          </Button>
          <Button variant="contained" onClick={() => handleReportsView('grid')} disabled={viewType === 'grid'}>
            Grid View
          </Button> */}
        </div>
        <div className={`${viewType === 'list' && 'd-flex'}`} style={{ gap: '20px' }}>
          <Reports
            viewType={viewType}
            allUserDetails={allUserDetails}
            setViewType={setViewType}
            isTeam={false}
            reportsData={reportsUploadedByYou}
            getAccessToken={getAccessToken}
            loadingTeamReports={loadingTeamReports}
            deleteReport={deleteReport}
            accTeam={accTeam}
            currentTeamMember={currentTeamMember}
          />
          <Reports
            viewType={viewType}
            allUserDetails={allUserDetails}
            setViewType={setViewType}
            isTeam={true}
            reportsData={reportsUploadedByTeam}
            getAccessToken={getAccessToken}
            loadingTeamReports={loadingTeamReports}
            deleteReport={deleteReport}
            accTeam={accTeam}
            currentTeamMember={currentTeamMember}
          />
        </div>

        {tokenConfig && (
          <Dialog
            fullScreen // Use fullScreen prop for full-screen dialog
            open={fullScreenModalOpen}
            onClose={handleFullScreenModalClose}>
            {/* <DialogTitle>
              <IconButton
                edge="end"
                color="inherit"
                onClick={handleFullScreenModalClose}
                aria-label="close"
                style={{ position: 'absolute', top: 0, left: 0 }}>
                <CloseIcon />
              </IconButton>
            </DialogTitle> */}
            <DialogContent>
              <PowerBIReport
                accessToken={accessToken}
                embedUrl={tokenConfig.EmbedUrl}
                embedReportId={tokenConfig.EmbedReportId}
                reportName={'reportName'}
                handleFullScreenModalClose={handleFullScreenModalClose}
                fetchNewToken={fetchNewToken}
                exisitngReportId={exisitngReportId}
                accTeam={accTeam}
              />
            </DialogContent>
            <DialogActions>
              {/* <Button autoFocus onClick={handleFullScreenModalClose} color="primary">
                Close
              </Button> */}
            </DialogActions>
          </Dialog>
        )}
      </div>
      <ConfirmationModal confirmationObj={confirmationData} />
    </div>
  );
};

export default UploadPBIX;
