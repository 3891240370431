import React from 'react';
import Select from 'react-select';
import Popover from '@mui/material/Popover';

const SelectStagePopover = ({
  anchorEl,
  menuOpen,
  handleMenuClose,
  disableScrollLock,
  classes,
  filterStageData,
  handleStageSelection,
}) => {
  return (
    <Popover
      id="long-menu"
      anchorEl={anchorEl}
      open={menuOpen}
      onClose={handleMenuClose}
      disableScrollLock={disableScrollLock}
      style={{ height: '300px' }}
      className={classes.popUpConatnier}
      transformOrigin={{
        vertical: 'top',
        horizontal: -35,
      }}
      PaperProps={{
        style: {
          width: '17ch',
          boxShadow: 'none',
          background: 'none',
          height: '500px',
          overflow: 'hidden',
        },
      }}>
      <div style={{ height: '100%' }}>
        <Select
          placeholder={'Select Sta..'}
          maxMenuHeight="138px"
          options={filterStageData.map(stage => ({ value: stage.StageId, label: stage.crmStageName }))}
          onChange={e => handleStageSelection(e)}
        />
      </div>
    </Popover>
  );
};

export default SelectStagePopover;
