/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
import IntlMessages from '../../util/IntlMessages';
import Select from 'react-select';

import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
}));

const CreateTeam = ({ modal, details, toggle, onClientsClose, clients }) => {
  const dispatch = useDispatch();
  const [countries, setCountries] = useState([]);
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  /**
   * This function is called after submiting the form
   *
   * @param formData
   */
  const onSubmit = data => {
    // const formData = new FormData();
    // const imagefile = document.querySelector('#file');
    // if (imagefile.files.length > 0) {
    //   formData.append('ImageData', imagefile.files[0]);
    // }
    // formData.append('TeamName', data['TeamName']);
    // formData.append('TeamDescription', data['TeamDescription']);

    let postData = {};
    let url = 'EmbeddedAnalytics/AddTeam';
    if (details.edit) {
      url = 'EmbeddedAnalytics/EditTeam';
      // formData['UserId'] = details.UserId;
      postData = [
        {
          TableName: 'EATeam',
          ColumnName: 'TeamName',
          NewValue: data['TeamName'],
          PrimaryKeyValue: details.Id.toString(),
        },
        {
          TableName: 'EATeam',
          ColumnName: 'TeamDescription',
          NewValue: data['TeamDescription'],
          PrimaryKeyValue: details.Id.toString(),
        },
      ];
    } else {
      postData = {
        EATeamName: data['TeamName'],
        EATeamDescription: data['TeamDescription'],
      };
    }
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        toggle();
        onClientsClose();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error.response.data.title);
          var errorList = error.response.data.errors;
          if (errorList) {
            //iterate through errors {} and add key values
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /**
   * Get the countries and add them to the combobox
   */
  useEffect(() => {
    dispatch({ type: FETCH_START });
    customAxios
      .get('/Bank/GetCountries', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var country = data.map(c => ({ value: c.CountryName, label: c.CountryName }));
          setCountries(country);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }, []);

  return (
    <Modal className="modal-box" size="lg" isOpen={modal} toggle={toggle}>
      <ModalHeader toggle={toggle} className="d-flex">
        {details.length === 0 ? <IntlMessages id="team.addTeam" /> : <IntlMessages id="team.updateTeam" />}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggle}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
          <div className="modal-box-content">
            <div className="row no-gutters">
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="TeamName"
                  control={control}
                  defaultValue={details.TeamName ? details.TeamName : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.TeamName" />} margin="none" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="TeamName"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-5 d-flex flex-column order-lg-1">
                <Controller
                  name="TeamDescription"
                  control={control}
                  defaultValue={details.TeamDescription ? details.TeamDescription : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field }) => (
                    <TextField {...field} label={<IntlMessages id="appModule.TeamDescription" />} margin="none" />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="TeamDescription"
                  render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                />
              </div>
              {/* <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-5 d-flex flex-column order-lg-1 mt-3 selectbox">
                <Controller
                  name="country"
                  control={control}
                  defaultValue={details.Country ? details.Country : ''}
                  rules={{ required: 'This is required' }}
                  render={({ field: { onChange, value, ref } }) => (
                    <Select
                      // style={classes.selectBox}
                      className={classes.selectBox}
                      placeholder={'Select Country..'}
                      // {...field}
                      options={countries}
                      value={countries.find(c => c.value == value)}
                      onChange={val => onChange(val)}
                    />
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="Country"
                  render={({ message }) => <p className={classes.marginErr}>{message}</p>}
                />
              </div>
              <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
              <div className="col-lg-6 d-flex flex-column order-lg-1 mt-3">
                <input id="file" type="file" name="companyProfile" />
              </div> */}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button type="submit" variant="contained" color="primary">
            {details.length === 0 ? <IntlMessages id="team.addTeam" /> : <IntlMessages id="team.updateTeam" />}
          </Button>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default CreateTeam;
