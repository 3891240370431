import React, { useContext, useEffect, useState } from 'react';
import { MenuItem, MenuList, Paper, Popover, Typography } from '@material-ui/core';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import PersonIcon from '@material-ui/icons/Person';
import SettingsIcon from '@material-ui/icons/Settings';
import SidebarThemeContext from '../../../../@coremat/CmtLayouts/SidebarThemeContext/SidebarThemeContext';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import authHeader from 'services/auth/jwt/auth-header';
import customAxios from 'routes/Pages/util/Api';

const useStyles = makeStyles(theme => ({
  root: {
    paddingLeft: '15px',
    borderBottom: props => `solid 1px ${props.sidebarTheme.borderColor}`,
  },
  userInfo: {
    paddingTop: 24,
    transition: 'all 0.1s ease',
    height: 75,
    opacity: 1,
    '.Cmt-miniLayout .Cmt-sidebar-content:not(:hover) &': {
      height: 0,
      paddingTop: 0,
      opacity: 0,
      transition: 'all 0.3s ease',
    },
  },
  userTitle: {
    color: props => props.sidebarTheme.textDarkColor,
    marginBottom: 8,
  },
  userSubTitle: {
    fontSize: 14,
    fontWeight: theme.typography.fontWeightBold,
    letterSpacing: 0.25,
  },
}));

const SidebarHeader = () => {
  const { sidebarTheme } = useContext(SidebarThemeContext);
  const classes = useStyles({ sidebarTheme });
  const [userDetails, setUserDetails] = useState({});
  const { authUser } = useSelector(({ auth }) => auth);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const dispatch = useDispatch();
  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onLogoutClick = () => {
    handlePopoverClose();
    dispatch(AuhMethods[CurrentAuthMethod].onLogout());
  };

  const getUserProfileDetails = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('Users/GetUserProfile', { headers: authHeader() })
        .then(({ data }) => {
          let correctedData = data;
          correctedData['AvatarImage'] = `data:image/jpeg;base64,${data.AvatarImage}`;
          setUserDetails(correctedData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  function stringAvatar(name) {
    return name
      ? {
          // sx: {
          //   bgcolor: stringToColor(name),
          // },

          children: `${name.charAt(0)}${name.charAt(1)}`,
        }
      : null;
  }

  useEffect(() => {
    getUserProfileDetails();
  }, []);

  return (
    <div
      style={{ minHeight: '81px', display: 'flex', justifyContent: 'start', alignItems: 'center' }}
      className={classes.root}>
      <CmtAvatar alt="User Avatar" src={userDetails.AvatarImage} {...stringAvatar(userDetails.EmailAddress)} />
      {/* <img src={`data:image/jpeg;base64,/${userDetails?.AvatarImage}`} height={30} /> */}
      {/* <div className={classes.userInfo} onClick={handlePopoverOpen}>
        <div
          className="pointer"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-end',
          }}>
          <div className="mr-2">
            <Typography className={classes.userTitle} component="h3" variant="h6">
              {authUser ? authUser.Email : ''}
            </Typography>
            <Typography className={classes.userSubTitle}>{authUser ? authUser.Email : ''}</Typography>
          </div>
        </div>
      </div> */}

      {/* {open && (
        <Popover
          open={open}
          anchorEl={anchorEl}
          container={anchorEl}
          onClose={handlePopoverClose}
          anchorOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'center',
            horizontal: 'right',
          }}>
          <Paper elevation={8}>
            <MenuList>
              <MenuItem onClick={handlePopoverClose}>
                <PersonIcon />
                <div className="ml-2">Profile</div>
              </MenuItem>
              <MenuItem onClick={handlePopoverClose}>
                <SettingsIcon />
                <div className="ml-2">Settings</div>
              </MenuItem>
              <MenuItem onClick={onLogoutClick}>
                <ExitToAppIcon />
                <div className="ml-2">Logout</div>
              </MenuItem>
            </MenuList>
          </Paper>
        </Popover>
      )} */}
    </div>
  );
};

export default SidebarHeader;
