import React, { useState } from 'react';
import { makeStyles, Tooltip } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import NoteAddIcon from '@mui/icons-material/NoteAdd';
import PeopleAltIcon from '@mui/icons-material/PeopleAlt';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import SelectStagePopover from './SelectStagePopover';
import AddNotePopover from './AddNotePopover';
import EmailIcon from '@mui/icons-material/Email';

const useStyles = makeStyles(theme => ({
  iconButton: {
    '&:hover': {
      color: theme.palette.primary.main, // Change to your desired hover color
    },
  },
}));

const QuickActionMenu = ({ data, stagesData, currTeam, OpenMailDialog }) => {
  const dispatch = useDispatch();
  const classes = useStyles();

  //add note operations
  const [openDate, setOpenDate] = useState(false);
  const [currNote, setCurrNote] = useState('');
  const [followUpDate, setFollowUpDate] = useState(null);
  const [anchorElNote, setAnchorElNote] = React.useState(null);
  const [notesData, setNotesData] = React.useState(null);
  const [openNote, setOpenNote] = useState(false);

  const handlefollowDateHandler = dateChange => {
    setFollowUpDate(dateChange);
  };

  const handleClickNote = event => {
    setAnchorElNote(event.target);
    setFollowUpDate(null);
    setOpenNote(true);
  };

  const handleCloseNote = () => {
    setAnchorElNote(null);
    setNotesData(null);
    setOpenNote(false);
  };
  const saveNote = () => {
    if (followUpDate) {
      addFollowUpDate();
    }
    addNotes();
    // getContactHandler(userData.crmId);
  };

  const getNotesData = () => {
    let bodyParamenters = {
      crmContactId: data.crmId,
      crmTeamId: data.CrmTeamId,
    };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRMNotes/GetCRMNotes', JSON.stringify(bodyParamenters), { headers: authHeader() })
      .then(({ data }) => {
        setNotesData(data);
        // console.log(data, 'data');
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const addNotes = () => {
    if (currNote === '') {
      handleCloseNote();
      return;
    }
    let dataObj = {
      ContactId: data.crmId,
      AccountId: data.UserId,
      Note: currNote,
      NoteDate: new Date().toISOString(),
      crmTeamId: data.CrmTeamId,
    };

    // setCurrNote('');
    // handleCloseNote();

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/AddCRMNote`, dataObj, {
          headers: authHeader(),
        })
        .then(() => {
          dispatch({ type: FETCH_SUCCESS, payload: 'Note Added Successfully' });
          getNotesData();
          setCurrNote('');
          // handleCloseNote();
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  function addFollowUpDate() {
    const folllowupobj = {
      CRM_ContactId: data.crmId,
      NextContactDate: new Date(followUpDate).toDateString(),
    };
    setFollowUpDate(null);

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`Contacts/AddContactFollowUpDate`, folllowupobj, {
          headers: authHeader(),
        })
        .then(() => {
          // console.log('Follow up date Added')
          setFollowUpDate(null);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  // const openNote = Boolean(anchorElNote);
  const idNote = openNote ? 'simple-popover' : undefined;

  const updateNewNotesText = args => {
    setCurrNote(args);
  };

  //stage operations
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disableScrollLock, setDisableScrollLock] = React.useState(true);
  const menuOpen = Boolean(anchorEl);

  const onStageChange = args => {
    let bodyParamenters = [
      {
        TableName: 'CRM_Contacts',
        ColumnName: 'crmStageId',
        NewValue: `${args.value}`,
        PrimaryKeyValue: `${data.crmId}`,
        crmTeamId: data?.CrmTeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/EditMultipleContacts', JSON.stringify(bodyParamenters), { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS, payload: 'Stage Change Successfully' });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleMenuClose = () => {
    setDisableScrollLock(true);
    setAnchorEl(null);
  };

  const handleStageSelection = args => {
    // console.log(args, 'args', args?.value);
    onStageChange(args);
    handleMenuClose();
  };

  const filterStageData = stagesData.filter(stage => stage.StageId !== data.crmStageId);

  return (
    <div className="mx-1">
      <Tooltip title={'Add Note'} arrow>
        <IconButton
          size="small"
          aria-label="note"
          onClick={event => {
            event.persist();
            event.ignore = true;

            handleClickNote(event);
          }}
          className={classes.iconButton}>
          <NoteAddIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Change Stage'} arrow>
        <IconButton
          size="small"
          onClick={event => {
            event.persist();
            event.ignore = true;
            setAnchorEl(event.target);
          }}
          aria-label="note"
          className={classes.iconButton}>
          <PeopleAltIcon fontSize="small" />
        </IconButton>
      </Tooltip>
      <Tooltip title={'Send Mail'} arrow>
        <IconButton
          size="small"
          onClick={event => {
            event.persist();
            event.ignore = true;
            OpenMailDialog(data);
          }}
          aria-label="note"
          className={classes.iconButton}>
          <EmailIcon fontSize="small" />
        </IconButton>
      </Tooltip>

      <AddNotePopover
        open={openNote}
        notesData={notesData}
        setNotesData={setNotesData}
        id={idNote}
        anchorEl={anchorElNote}
        setAnchorEl={setAnchorElNote}
        handleClose={handleCloseNote}
        saveNote={saveNote}
        currNote={currNote}
        updateNewNotesText={updateNewNotesText}
        followUpDate={followUpDate}
        openDate={openDate}
        setOpenDate={setOpenDate}
        handlefollowDateHandler={handlefollowDateHandler}
        contactData={data}
        getNotesData={getNotesData}
      />
      <SelectStagePopover
        handleStageSelection={handleStageSelection}
        filterStageData={filterStageData}
        classes={classes}
        disableScrollLock={disableScrollLock}
        anchorEl={anchorEl}
        menuOpen={menuOpen}
        handleMenuClose={handleMenuClose}
      />
    </div>
  );
};

export default QuickActionMenu;
