import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const commonStyle = {
  fontFamily: 'normal',
  textTransform: 'none',
};

export default function OverrideFileModal({ handleClickOpen, open, setOpen, setIsOverride, onSubmit }) {
  const handleClose = value => {
    setOpen(false);
    setIsOverride(value);
    onSubmit();
  };

  return (
    <div>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description">
        <DialogTitle sx={commonStyle}>{'The file already exists!'}</DialogTitle>
        <DialogContent>
          <DialogContentText sx={commonStyle} id="alert-dialog-slide-description">
            Do you want to overwrite this file?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button sx={commonStyle} onClick={() => handleClose(false)}>
            Cancel
          </Button>
          <Button sx={commonStyle} type="submit" onClick={() => handleClose(true)}>
            Overwrite File
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
