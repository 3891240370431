import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';

import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  check: {
    marginBottom: 30,
    color: theme.palette.text.secondary,
  },
  textheading: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  verification: {
    marginBottom: 20,
    color: theme.palette.text.secondary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

const VerifyEmail = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  // const { send_forget_password_email } = useSelector(({ auth }) => auth);
  const usertoken = JSON.parse(localStorage.getItem('userEmailVerify'));
  let email = usertoken?.Email;
  let token = usertoken?.Token;

  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  const onFormSubmit = () => {
    // dispatch(AuhMethods[method].onForgotPassword({ email }));
    dispatch(AuhMethods[CurrentAuthMethod].resendConfirmEmail({ email, history, token }));
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/focus-logo/focus-logo.png'} width={'150px'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Check Your Email
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <Typography component="div" variant="h6" className={classes.check}>
          Please check your email we have send you verfication link on this email {''}
          <a href="https://mail.google.com/mail/?view=cm&fs=1&to=email@domain.example" target="_blank" rel="noreferrer">
            {email}
          </a>
        </Typography>

        <Typography component="div" variant="h6" className={classes.verification}>
          Didn't get the verification link ?{' '}
        </Typography>
        <Button onClick={onFormSubmit} variant="contained" color="primary">
          <IntlMessages id="appModule.sendagain" />
        </Button>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default VerifyEmail;
