import React, { useState, useRef } from 'react';
import {
  Grid,
  Typography,
  Paper,
  IconButton,
  Menu,
  MenuItem,
  ListItemText,
  ListItemIcon,
  ListItem,
  Skeleton,
  Button,
  ButtonBase,
  Box,
} from '@mui/material';
import {
  Edit,
  Delete,
  Share as ShareIcon,
  Visibility as VisibilityIcon,
  DateRange as DateRangeIcon,
  Autorenew as AutorenewIcon,
  MoreVert as MoreVertIcon,
} from '@mui/icons-material';
import CmtAvatar from '@coremat/CmtAvatar';
import { styled } from '@mui/material/styles';
import Tooltip from '@mui/material/Tooltip';
import RemoveRedEyeOutlinedIcon from '@mui/icons-material/RemoveRedEyeOutlined';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { NavLink } from 'react-router-dom';

const Img = styled('img')({
  margin: 'auto',
  display: 'block',
  maxWidth: '100%',
  maxHeight: '100%',
});

const menuStyles = {
  fontFamily: 'normal',
};

const SkeletonLoader = () =>
  Array.from({ length: 5 }).map((_, index) => (
    <Grid item xs={12} sm={6} md={5} lg={2} sx={{ width: 300 }} key={index}>
      <Paper elevation={2} className="p-2">
        <div className="my-1 d-flex justify-content-center">
          <Skeleton variant="rectangular" width={128} height={128} />
        </div>
        <div className="d-flex my-1 align-items-center " style={{ gap: '5px' }}>
          <Skeleton variant="circular" width={40} height={33} />
          <Skeleton variant="text" width={'100%'} height={40} />
        </div>
        <Skeleton variant="text" width={'80%'} />
      </Paper>
    </Grid>
  ));

const ReportsGridView = ({
  reportsData,
  isTeam,
  stringAvatar,
  userData,
  handleClick,
  anchorElArray,
  handleClose,
  handleMenuItem,
  allUserDetails,
  loading,
  accTeam,
  currentTeamMember,
}) => {
  function formatDateString(dateString) {
    const date = new Date(dateString);

    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      second: 'numeric',
    };

    return date.toLocaleDateString('en-US', options);
  }

  const containerRef = useRef(null);
  const [scrollLeft, setScrollLeft] = useState(0);

  const handleScroll = scrollOffset => {
    if (containerRef.current) {
      containerRef.current.scrollLeft += scrollOffset;
      setScrollLeft(containerRef.current.scrollLeft);
    }
  };

  return (
    <div className="my-5">
      <h6
        style={{
          boxShadow: 'rgba(0, 0, 0, 0.16) 0px 1px 4px',
          padding: '16px',
          borderRadius: '4px',
          background: '#61b3f2',
          width: '100%',
          color: '#fff',
          fontWeight: 'bold',
        }}>
        {isTeam ? 'Reports uploaded by team members' : 'Reports uploaded by you'}
      </h6>
      <div style={{ overflowX: 'auto', whiteSpace: 'nowrap' }}>
        <div ref={containerRef} style={{ display: 'flex', gap: '50px' }}>
          {loading ? (
            <SkeletonLoader />
          ) : reportsData.length === 0 ? (
            <Grid item xs={12} sm={6} md={5} lg={2}>
              <Paper elevation={2} className="p-2">
                <Typography sx={{ fontFamily: 'normal' }} variant="body1">
                  No Reports Found
                </Typography>
              </Paper>
            </Grid>
          ) : (
            reportsData.map((item, index) => {
              let filterAvatarImage;
              if (isTeam) {
                filterAvatarImage = allUserDetails?.find(user => user.EAUserId === item.UserID);
              }
              const creditionals = `reportid=${item.ReportId}&&teamid=${accTeam}&&reportName=${item.ReportName}`;
              const handleReportRouting = () => {
                return (
                  <NavLink to={`/embedded-analytics/report/${creditionals}`}>
                    <p style={{ fontFamily: 'normal', cursor: 'pointer', color: '#333333' }}>View Report</p>
                  </NavLink>
                );
              };

              return (
                <Grid sx={{ margin: '10px 0px' }} item xs={12} sm={6} md={5} lg={2} key={index}>
                  <ListItem
                    sx={{
                      display: 'flex',
                      alignItems: 'flex-start',
                      justifyContent: 'space-between',
                      flexDirection: 'column',
                      height: '100%',
                    }}
                    component={Paper}
                    elevation={2}
                    className="p-2">
                    <NavLink to={`/embedded-analytics/report/${creditionals}`}>
                      <ButtonBase
                        // onClick={() => handleMenuItem(item.ReportId, 'imgClick')}
                        sx={{ width: '100%', height: 128 }}>
                        <Tooltip title="Open Report">
                          {item.ReportImage ? (
                            <Img
                              alt="report"
                              src={`data:image/png;base64,${item.ReportImage}`}
                              onError={e => {
                                e.target.src = '/images/power-bi.png';
                                e.target.alt = 'error';
                              }}
                            />
                          ) : (
                            <Img alt="error" src="/images/power-bi.png" />
                          )}
                        </Tooltip>
                      </ButtonBase>
                    </NavLink>

                    <div className="d-flex justify-content-between align-items-end p-2" style={{ width: '100%' }}>
                      <div style={{ width: '100%' }}>
                        <div className="d-flex align-items-center my-2">
                          <div className="mr-2">
                            {isTeam ? (
                              <CmtAvatar
                                size="small"
                                src={`data:image/jpeg;base64,${filterAvatarImage?.AvatarImage}`}
                                {...stringAvatar(filterAvatarImage?.Email)}
                              />
                            ) : (
                              <CmtAvatar size="small" src={userData.AvatarImage} {...stringAvatar(userData.EmailAddress)} />
                            )}
                          </div>
                          <Typography variant="body1">{item.ReportName}</Typography>
                        </div>
                        <div>{formatDateString(item.UploadTime)}</div>
                      </div>

                      <div>
                        <IconButton
                          aria-label="more"
                          aria-controls={`menu-${index}`}
                          aria-haspopup="true"
                          onClick={event => handleClick(event, item, index)}>
                          <Tooltip title="Open Menu">
                            <MoreVertIcon />
                          </Tooltip>
                        </IconButton>
                        <Menu
                          id={`menu-${index}`}
                          anchorEl={anchorElArray[index]}
                          open={Boolean(anchorElArray[index])}
                          onClose={() => handleClose(index)}>
                          {currentTeamMember?.IsAdmin === 0 ? (
                            <MenuItem sx={menuStyles}>
                              <RemoveRedEyeOutlinedIcon /> {handleReportRouting()}
                            </MenuItem>
                          ) : (
                            <div>
                              <MenuItem sx={menuStyles}>
                                <RemoveRedEyeOutlinedIcon /> {handleReportRouting()}
                              </MenuItem>
                              <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'shareReport')}>
                                <ShareIcon /> Share Report
                              </MenuItem>
                              <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'schedulereport')}>
                                <DateRangeIcon /> Schedule Report
                              </MenuItem>
                              <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'refreshDataset')}>
                                <AutorenewIcon /> Refresh Dataset
                              </MenuItem>
                              <MenuItem sx={menuStyles} onClick={() => handleMenuItem(item, 'deleteReport')}>
                                <DeleteOutlineIcon /> Delete Report
                              </MenuItem>
                            </div>
                          )}
                        </Menu>
                      </div>
                    </div>
                  </ListItem>
                </Grid>
              );
            })
          )}
        </div>
      </div>
      {/* {scrollLeft > 0 && <Button onClick={() => handleScroll(-150)}>{'<'}</Button>}
      {containerRef.current && scrollLeft < containerRef.current.scrollWidth - containerRef.current.clientWidth && (
        <Button onClick={() => handleScroll(150)}>{'>'}</Button>
      )} */}
    </div>
  );
};

export default ReportsGridView;
