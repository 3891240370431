import { AuthenticatedTemplate } from '@azure/msal-react';
import { IconButton, Menu, MenuItem, Button } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import React, { useEffect } from 'react';
import Mail from '@mui/icons-material/Mail';
import Delete from '@material-ui/icons/Delete';
import Edit from '@material-ui/icons/Edit';
import { lighten, makeStyles } from '@material-ui/core/styles';
import IntlMessages from '../../util/IntlMessages';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
const ITEM_HEIGHT = 48;
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  outlookImg: {
    marginRight: '10px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
});
const ActionMenu = ({ info, handelMenuClick }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [disableScrollLock, setDisableScrollLock] = React.useState(true);

  const menuOpen = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(info.target);
    setDisableScrollLock(false);
  };
  const handleMenuClose = () => {
    setDisableScrollLock(true);
    setAnchorEl(null);
  };

  useEffect(() => {
    handleClick();
  }, [info]);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        open={menuOpen}
        onClose={handleMenuClose}
        disableScrollLock={true}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
            paddingRight: '20px',
            marginRight: '10px',
          },
        }}>
        <MenuItem
          onClick={() => {
            handelMenuClick('sendEmail', info.rowData);

            setAnchorEl(null);
          }}>
          <Mail className={classes.pointer} /> Send Email
        </MenuItem>

        <MenuItem
          onClick={() => {
            handelMenuClick('delete', info.rowData);
            setAnchorEl(null);
          }}>
          <Delete className={classes.pointer} /> Delete
        </MenuItem>
        <MenuItem
          onClick={() => {
            handelMenuClick('move', info.rowData);
            setAnchorEl(null);
          }}>
          <DriveFileMoveIcon className={classes.pointer} /> Move Contact
        </MenuItem>
        <MenuItem
          onClick={() => {
            handelMenuClick('copy', info.rowData);
            setAnchorEl(null);
          }}>
          <FileCopyIcon className={classes.pointer} /> Copy Contact
        </MenuItem>
      </Menu>
    </React.Fragment>
  );
};
export const AddMenu = ({ triggerFunction, AddContact, AddMultipleContact, teamInfo }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handelAddContact = e => {
    AddContact();
    handleClose();
  };
  const handelAddMultipleContact = e => {
    AddMultipleContact();
    handleClose();
  };

  return (
    <div>
      <Button
        variant="contained"
        color="primary"
        disabled={teamInfo.length == 0}
        onClick={handleClick}
        style={{ height: 40, textTransform: 'none' }}
        endIcon={<KeyboardArrowDown />}>
        Add
      </Button>
      <Menu
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        transformOrigin={{
          vertical: -50,
          horizontal: 'center',
        }}>
        <MenuItem onClick={handelAddContact}>Add Single Contact</MenuItem>
        <MenuItem onClick={handelAddMultipleContact}>
          {/* {' '}
          <IntlMessages id="crm.excelCSVImport" /> */}
          Add Multiple Contact
        </MenuItem>
      </Menu>
    </div>
  );
};

export default ActionMenu;

// const GeneralMenu = ({menuItemInfo,itemStyle,triggerTemplate,triggerStyle,triggerAction,itemTemplate,menuStyle})=>{
//   const [anchorEl, setAnchorEl] = React.useState(null);
//   const open = Boolean(anchorEl);
//   const handleClick = (event) => {
//     setAnchorEl(event.currentTarget);
//   };
//   const handleClose = () => {
//     setAnchorEl(null);
//   };

//   return (<div>
//     {triggerTemplate ? <div className='triggerWrapper' onClick={(e)=>handleClick(e)}>{triggerTemplate}</div> :
//      <Button

//         {...triggerStyle}
//         variant="contained"
//         disableElevation
//         onClick={handleClick}
//         endIcon={<KeyboardArrowDownIcon />}
//     />}

// <Menu
//         id="long-menu"
//         anchorEl={anchorEl}
//         open={menuOpen}
//         onClose={handleMenuClose}
//         PaperProps={{
//           style: {
//             maxHeight: ITEM_HEIGHT * 4.5,
//             width: '20ch',
//             ...menuStyle
//           },
//         }}>

//          {menuItemInfo.map((item,index)=>{
//           <MenuItem onClick={()=>{
//             item.action(...item.actionData);
//             handleClose()
//           }} style={...itemStyle}>
//               {itemTemplate?itemTemplate:   <Button variant="contained" color="primary" onClick={toggle} style={{ height: 40 }}>
//             <IntlMessages id="crm.excelCSVImport" />
//           </Button>}
//           </MenuItem>
//          })}
//         <MenuItem
//           onClick={() => {
//             console.log(info);
//             handelMenuClick('sendEmail', info);
//             setAnchorEl(null);
//           }}>
//           <Mail className={classes.pointer} /> Send Email
//         </MenuItem>

//       </Menu>
//   </div>)
// }
