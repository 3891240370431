import React, { useState, useRef, useEffect } from 'react';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import PopupState, { bindTrigger, bindMenu } from 'material-ui-popup-state';
import EditIcon from '@material-ui/icons/Edit';
import DeleterIcon from '@material-ui/icons/Delete';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import InputLabel from '@material-ui/core/InputLabel';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

const Snippets = ({ selectedTeam, currMailSubRef, currMaibodyRef, lastTestAreaFocus, setValue }) => {
  const dispatch = useDispatch();
  // snipets start

  const [openAddSnippet, setOpenAddSnippet] = useState(false);
  const [openEditSnippet, setOpenEditSnippet] = useState(false);
  const [snippetsMap, setSnippetsMap] = useState({});
  const [customSnippets, setCustomSnippets] = useState([]);
  const [newSnippetData, setNewSnippetData] = useState({
    SnippetName: '',
    SnippetDescription: '',
    SnippetId: '',
  });
  let rowData = '';
  const defaultSnippetsMap = {
    City: 'crmAddressCity',
    Country: 'crmAddressCountry',
    Town: 'crmAddressTown',
    Zip: 'crmAddressZip',
    CompanyName: 'crmCompany',
    Email: 'crmContactEmail',
    FirstName: 'crmContactFirst',
    LastName: 'crmContactLast',
    MiddleName: 'crmContactMiddle',
    PhoneNo: 'crmContactPhone',
    Website: 'crmContactWebsite',
    FullName: 'FullName',
  };
  const initialSnippetData = {
    SnippetName: '',
    SnippetDescription: '',
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  const EditSnippetModalOpen = args => {
    let snip_data = {
      SnippetName: args.SnippetName,
      SnippetDescription: args.SnippetDescription,
      snippetId: args.Id,
    };
    setOpenEditSnippet(true);
    setNewSnippetData(snip_data);
  };

  const onSnippetSelected = args => {
    if (lastTestAreaFocus) {
      switch (lastTestAreaFocus) {
        case 'TemplateSubject':
          let startPos = currMailSubRef.current.selectionStart;
          let endPos = currMailSubRef.current.selectionEnd;

          console.log(startPos, endPos);
          let currVal = currMailSubRef.current.value;

          let newVal = currVal.substring(0, startPos) + '[' + args + ']' + currVal.substring(endPos, currVal.length);
          console.log(newVal);
          let len = newVal.length;
          currMailSubRef.current.selectionEnd = len + 1;
          let updatedValue = { TemplateSubject: newVal };
          currMailSubRef.current.value = newVal;
          setValue('TemplateSubject', newVal);
          //   setCustomTemplateData(customTemplateData => ({
          //     ...customTemplateData,
          //     ...updatedValue,
          //   }));
          // addSelectedSnippet(currMailSubRef)
          break;
        case 'TemplateBody':
          let range = currMaibodyRef.current.getEditor().getSelection();
          let position = range ? range.index : 0;
          let textInsert = ' ' + '[' + args + ']' + ' ';
          currMaibodyRef.current.getEditor().insertText(position, textInsert);

          // addSelectedSnippet(currMaibodyRef)
          break;
      }
    }
  };

  const saveSnippet = () => {
    if (openEditSnippet) saveEditedSnippet(newSnippetData);
    else saveNewSnippet();
  };

  const saveEditedSnippet = args => {
    let newSnippet = {
      Id: parseInt(args.snippetId),
      CrmTeamId: selectedTeam.value,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/EditSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenEditSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const saveNewSnippet = () => {
    if (newSnippetData.SnippetName == '' || newSnippetData.SnippetDescription == '') return;
    let newSnippet = {
      CrmTeamId: selectedTeam.value,
      SnippetName: newSnippetData.SnippetName,
      SnippetDescription: newSnippetData.SnippetDescription,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post('CRMSnippets/AddSnippets', newSnippet, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setNewSnippetData(initialSnippetData);
          getSnippet();
          setOpenAddSnippet(false);

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const getSnippet = () => {
    let url = 'CRMSnippets/GetSnippets/' + selectedTeam.value;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          setCustomSnippets(data);
          let customSnippetData = data.reduce((a, v) => ({ ...a, [v.SnippetName]: v.SnippetDescription }), {});
          setSnippetsMap({ ...rowData, ...customSnippetData });

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  // utility function start
  function extractContent(s) {
    var span = document.createElement('span');
    span.innerHTML = s;
    return span.textContent || span.innerText;
  }
  String.prototype.interpolate = function(args) {
    try {
      const names = Object.keys(args);
      const vals = Object.values(args);

      let propData = new Function(...names, `return \`${extractContent(this)}\`;`)(...vals);
      return propData;
    } catch (e) {
      return this;
    }
  };

  function interpolateStr(string, obj) {
    return string.replace(/(?<!\[)\[([^[\]]*)](?!\])/g, (m, p1) => obj[p1] ?? `[${p1}]`);
  }
  const handelChange = (dataName, datavalue) => {
    let updatedValue = {};
    if (dataName == 'SnippetName') {
      updatedValue = { SnippetName: datavalue };
    } else {
      updatedValue = { SnippetDescription: datavalue };
    }
    setNewSnippetData(newSnippetData => ({
      ...newSnippetData,
      ...updatedValue,
    }));
  };

  useEffect(() => {
    getSnippet();
  }, []);
  return (
    <div>
      <PopupState variant="popover" popupId="demo-popup-menu">
        {popupState => (
          <div>
            <Button
              size="small"
              variant="contained"
              className="sm-button"
              {...bindTrigger(popupState)}
              style={{
                paddingBlock: '0.5em',
                fontFamily: 'normal',
                fontSize: '0.75rem',
                marginBottom: '15px',
                backgroundColor: '#3f51b5',
              }}>
              snippets
            </Button>
            <Menu {...bindMenu(popupState)} style={{ height: '400px', marginTop: '2px' }} className="info_sidebar pt-0 pb-0">
              <div className="w-100 " style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', paddingInline: '10px' }}>
                <Button size="small" variant="contained" onClick={() => setOpenAddSnippet(true)} className="sm-button">
                  Add Snippets
                </Button>
              </div>
              {openAddSnippet || openEditSnippet ? (
                <div style={{ width: '300px' }}>
                  <div className="w-100 pt-2">
                    <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                      <InputLabel htmlFor="age-native-helper">Snippet Name</InputLabel>
                      <Controller
                        name="SnippetName"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <TextField
                            onChange={e => {
                              handelChange(field.name, e.target.value);
                              field.onChange(e.target.value);
                            }}
                            // label={<IntlMessages id="appModule.invoiceNumber" />}
                            value={newSnippetData.SnippetName}
                            margin="none"
                          />
                        )}
                      />
                    </div>
                    <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                      <InputLabel htmlFor="age-native-helper">Snippet Description</InputLabel>
                      <Controller
                        name="SnippetDescription"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            onChange={e => {
                              handelChange(field.name, e.target.value);
                              field.onChange(e.target.value);
                            }}
                            // label={<IntlMessages id="appModule.invoiceNumber" />}
                            value={newSnippetData.SnippetDescription}
                            margin="none"
                          />
                        )}
                      />
                    </div>
                    <div
                      className="w-100 "
                      style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', paddingInline: '15px 10px' }}>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          saveSnippet();
                        }}
                        className="sm-button">
                        save
                      </Button>
                      <Button
                        size="small"
                        variant="contained"
                        onClick={() => {
                          openEditSnippet ? setOpenEditSnippet(false) : setOpenAddSnippet(false);
                          setNewSnippetData(initialSnippetData);
                        }}
                        className="sm-button ml-2"
                        style={{ backgroundColor: 'white' }}>
                        cancel
                      </Button>
                    </div>
                  </div>
                </div>
              ) : (
                <div>
                  {Object.keys(defaultSnippetsMap).map((item, index) => {
                    return (
                      <MenuItem
                        sx={{ fontFamily: 'normal' }}
                        style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', width: '300px' }}
                        onClick={() => {
                          popupState.close();
                          onSnippetSelected(defaultSnippetsMap[item]);
                        }}
                        key={index}>
                        <div>
                          <span> {item}</span>
                        </div>
                      </MenuItem>
                    );
                  })}

                  {customSnippets.map((item, index) => {
                    return (
                      <MenuItem
                        sx={{ fontFamily: 'normal' }}
                        style={{ borderBottom: '1px solid rgba(0,0,0,0.125)', width: '300px', padding: '0' }}
                        key={index}>
                        <div className="w-100 d-flex justify-content-between">
                          <span
                            style={{ flex: 1, padding: '0.5em 0 0.5em 15px' }}
                            onClick={() => {
                              popupState.close();
                              onSnippetSelected(item.SnippetName);
                            }}>
                            {' '}
                            {item.SnippetName}
                          </span>
                          <Button
                            variant="contained"
                            size="small"
                            style={{
                              background: '#3f51b5',
                              fontFamily: 'normal',
                              fontSize: '0.75rem',
                              padding: '0px',
                              height: '25px',
                              marginBlock: 'auto',
                            }}
                            onClick={() => {
                              EditSnippetModalOpen(item);
                            }}>
                            <EditIcon fontSize="small" />
                          </Button>
                        </div>
                      </MenuItem>
                    );
                  })}
                </div>
              )}
            </Menu>
          </div>
        )}
      </PopupState>
    </div>
  );
};

export default Snippets;
