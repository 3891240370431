import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from 'routes/Pages/constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import PowerBIReport from '../UploadPBIX/PowerBIReport';
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@material-ui/core';
import ErrorMessage from './ErrorMessage';
import styled from 'styled-components';
import RefreshStatusSnackbar from './RefreshStatusSnackbar';

const StyledDialog = styled(Dialog)`
  /* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 20;
  } */
  .MuiDialog-paper {
    overflow: hidden;
  }
  .MuiDialogContent-root {
    overflow: hidden;
  }
  .MuiDialogContent-root:first-child {
    padding: 0;
  }
`;

const EAReport = () => {
  const { creditionals } = useParams(); // Correct usage: invoking useParams as a function
  const dispatch = useDispatch();
  const [tokenConfig, setTokenConfig] = useState({});
  const [accessToken, setAccessToken] = useState(null);
  const [isTeamValid, setIsTeamValid] = useState(1);
  const [datatsetRefreshHistory, setDatatsetRefreshHistory] = useState(null);

  //refresh dataset status snackbar
  const [openStatusSnackbar, setOpenStatusSnackbar] = React.useState(false);
  const [refreshStatusInfo, setRefreshStatusInfo] = useState({
    message: '',
    severity: '',
  });

  const getValue = key =>
    creditionals
      .split('&&')
      .map(pair => pair.split('='))
      .find(([k, v]) => k === key)?.[1];

  const reportId = getValue('reportid');
  const teamId = getValue('teamid');
  const reportName = getValue('reportName');

  //https://api.focus247.online/api/EmbeddedAnalytics/GetTeams
  const getLastUpdatedTime = () => {
    // dispatch({ type: FETCH_START });
    const bodyParameters = {
      TeamId: teamId,
      ReportId: reportId,
      Top: 5,
    };
    try {
      customAxios
        .post(`EmbeddedAnalytics/GetDatatsetRefreshHistory`, JSON.stringify(bodyParameters), { headers: authHeader() })
        .then(({ data }) => {
          setDatatsetRefreshHistory(data);
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Invalid report creditionals' });
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getAccessToken = () => {
    // dispatch({ type: FETCH_START });

    try {
      customAxios
        .get(`EmbeddedAnalytics/GetEmbedModel?TeamId=${teamId}&ReportId=${reportId}`, { headers: authHeader() })
        .then(({ data }) => {
          if (data.IsValid) {
            setTokenConfig(data);
            setAccessToken(data.EmbedAccessToken);
            // handleFullScreenModalOpen();
            // setExistingReportId(reportId);
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Failed to load Power-Bi report' });
          }
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Invalid report creditionals' });
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getAllTeams = () => {
    // dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(`https://api.focus247.online/api/EmbeddedAnalytics/GetTeams`, { headers: authHeader() })
        .then(({ data }) => {
          const findTeam = data.find(element => +element.Id === +teamId);

          if (findTeam === undefined) {
            setIsTeamValid(false);
          } else {
            setIsTeamValid(true);
            // getAccessToken();
          }
        })
        .catch(error => {
          return false;
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const fetchNewToken = () => {
    customAxios
      .get(`EmbeddedAnalytics/GetEmbedModel?TeamId=${teamId}&ReportId=${reportId}`, { headers: authHeader() })
      .then(({ data }) => {
        if (data.IsValid) {
          setTokenConfig(data);
          setAccessToken(data.EmbedAccessToken);
        } else {
          dispatch({ type: FETCH_ERROR, payload: 'Failed to refresh token' });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Error refreshing token' });
      });
  };

  // Function to check token expiry and refresh
  useEffect(() => {
    getAllTeams();
  }, []);
  const checkAndRefreshToken = () => {
    // Check if accessToken exists and token expiry is available in data
    if (accessToken && tokenConfig.ExpiryTimestamp) {
      const currentTime = Math.floor(Date.now() / 1000); // Current time in seconds
      const tokenValidityWindow = 60; // Refresh token 60 seconds before expiry

      if (tokenConfig.ExpiryTimestamp - currentTime < tokenValidityWindow) {
        fetchNewToken(); // Refresh token if it's about to expire
      }
    }
  };

  useEffect(() => {
    getAccessToken();
    getLastUpdatedTime();
    // Check and refresh token periodically
    const interval = setInterval(() => {
      checkAndRefreshToken();
    }, 15000);

    return () => clearInterval(interval);
  }, []);

  const handleFullScreenModalClose = () => {
    console.log('modal');
  };

  return (
    <div>
      {refreshStatusInfo && (
        <RefreshStatusSnackbar
          open={openStatusSnackbar}
          setOpen={setOpenStatusSnackbar}
          refreshStatusInfo={refreshStatusInfo}
        />
      )}
      <StyledDialog
        fullScreen // Use fullScreen prop for full-screen dialog
        open={true}
        onClose={handleFullScreenModalClose}>
        <DialogContent>
          {isTeamValid ? (
            <PowerBIReport
              accessToken={accessToken}
              embedUrl={tokenConfig.EmbedUrl}
              embedReportId={tokenConfig.EmbedReportId}
              reportName={reportName}
              handleFullScreenModalClose={handleFullScreenModalClose}
              fetchNewToken={fetchNewToken}
              exisitngReportId={reportId}
              accTeam={teamId}
              datatsetRefreshHistory={datatsetRefreshHistory}
              getLastUpdatedTime={getLastUpdatedTime}
              setRefreshStatusInfo={setRefreshStatusInfo}
              setOpenStatusSnackbar={setOpenStatusSnackbar}
            />
          ) : (
            <ErrorMessage />
          )}
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleFullScreenModalClose} color="primary">
                Close
              </Button> */}
        </DialogActions>
      </StyledDialog>
    </div>
  ); // Adding a space for clarity
};

export default EAReport;
