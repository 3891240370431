import * as React from 'react';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
// import ViewQuiltIcon from '@mui/icons-material/ViewQuilt';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

export default function ToggleView({ handleReportsView, viewType }) {
  //   const handleChange = (event, nextView) => {
  //     setView(nextView);
  //   };

  return (
    <ToggleButtonGroup orientation="horizontal" value={viewType} exclusive>
      <ToggleButton value="list" aria-label="list" onClick={() => handleReportsView('list')}>
        <ViewListIcon />
      </ToggleButton>
      <ToggleButton value="grid" aria-label="module" onClick={() => handleReportsView('grid')}>
        <ViewModuleIcon />
      </ToggleButton>
    </ToggleButtonGroup>
  );
}
