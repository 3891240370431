/**
 * Version 1.0
 * Vikas W
 * 23.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';

/* Material Components */
import Button from '@material-ui/core/Button';
import Select from 'react-select';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditIcon from '@material-ui/icons/Edit';

import IntlMessages from '../../util/IntlMessages';
import { useForm, Controller } from 'react-hook-form';
import TextField from '@material-ui/core/TextField';

/* Table Config */
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
  table: {
    minWidth: 650,
  },
  pointer: {
    cursor: 'pointer',
  },
}));

const DialogStageTemplate = ({ modal, toggle, Teams, Stages, selectedTeam, stagesUpdateHandler, deleteStageHandler }) => {
  const dispatch = useDispatch();
  const [editStage, setEditStage] = useState(false);
  const [editId, setEditId] = useState('');
  const [stages, setStages] = useState([]);
  const [stageName, setStageName] = useState('');
  const [stageDesc, setStageDesc] = useState('');
  const [warningModalOpen, setWarningModalOpen] = useState(false); //popover  Anchor
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  useEffect(() => {
    if (Stages && selectedTeam) {
      var stages = Stages.map(c => ({ name: c.crmStageName, desc: c.CrmStageDesc, id: c.StageId, CrmTeamId: c.CrmTeamId }));
      stages = stages.filter(t => t.CrmTeamId == selectedTeam.value);
      setStages(stages);
    }
  }, [Teams, Stages, selectedTeam]);

  /* Add Stage Name */
  const addStageName = formData => {
    const stageData = {
      StageName: formData.stageName,
      StageDescription: formData.stageDesc,
      CRM_TeamId: formData.CRM_TeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/Add_CRM_Stage', stageData, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getStageName();
        stagesUpdateHandler();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error);
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  /* update Stage name  */
  const updateStageName = formData => {
    let url = '/CRM/Edit_CRM_Stage';
    let editObj = [
      {
        TableName: 'CRM_Stages',
        ColumnName: 'crmStageName',
        NewValue: stageName,
        PrimaryKeyValue: editId,
        crmTeamId: formData.CRM_TeamId,
      },
      {
        TableName: 'CRM_Stages',
        ColumnName: 'CrmStageDesc',
        NewValue: stageDesc,
        PrimaryKeyValue: editId,
        crmTeamId: formData.CRM_TeamId,
      },
    ];

    dispatch({ type: FETCH_START });
    customAxios
      .post(url, editObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        getStageName();
        stagesUpdateHandler();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error);
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /* Delete Stage Name */
  const deleteStageName = id => {
    const deletedObj = {
      StageId: id,
      TeamId: selectedTeam.value,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post(`CRM/Delete_CRM_Stage`, deletedObj, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });

        getStageName();
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /* Check Stage Contains Contact */
  const checkCanDeleteStage = (row, args) => {
    const checkObj = {
      CrmTeamId: row.CrmTeamId,
      CrmStageId: row.id,
    };
    let totalContact = 0;
    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/CheckStageContacts', checkObj, { headers: authHeader() })
      .then(data => {
        if (data) {
          if (parseInt(data.data[0]) > 0) {
            openWarningModal();
          } else {
            deleteStageName(row.id);
          }
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  /* Edit Stage Name */
  const editStageName = row => {
    setEditStage(true);
    setStageName(row.name);
    setStageDesc(row.desc);
    setEditId(row.id);
  };

  const getStageName = () => {
    dispatch({ type: FETCH_START });
    let url = '/CRM/Get_CRM_Stages/' + selectedTeam.value;
    customAxios
      .get(url, { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          var stages = data.map(c => ({
            name: c.crmStageName,
            desc: c.CrmStageDesc,
            id: c.StageId,
            CrmTeamId: c.CrmTeamId,
          }));
          stages = stages.filter(t => t.CrmTeamId == selectedTeam.value);
          setStages(stages);
          deleteStageHandler(data);
          dispatch({ type: FETCH_SUCCESS });
        }
        clearForm();
        // toggle();
      })
      .catch(function(error) {
        clearForm();
        if (error.response.status == '400') {
          var errorList = error.response.data.errors;
          if (errorList) {
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const clearForm = () => {
    setStageName('');
    setStageDesc('');
    setEditId('');
    setEditStage(false);
  };

  /* Warning Dialog fnc */
  const openWarningModal = () => {
    setWarningModalOpen(true);
  };
  const closeWarningModal = () => {
    setWarningModalOpen(false);
  };

  return (
    <div>
      <Modal className="invoice modal-box" size="lg" isOpen={modal} toggle={toggle}>
        <ModalHeader toggle={toggle} className="d-flex">
          <IntlMessages id="crm.addStageName" />
          <button
            type="button"
            className="close"
            aria-label="Close"
            onClick={toggle}
            style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
            <span aria-hidden="true">×</span>
          </button>
        </ModalHeader>

        <ModalBody>
          <div className="modal-box-content">
            <form
              name="myForm"
              onSubmit={editStage ? handleSubmit(updateStageName) : handleSubmit(addStageName)}
              style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}
              autoComplete="off">
              <div className="row no-gutters">
                <div className="col-lg-2 d-flex flex-column order-lg-1 invoice_label">
                  <Controller
                    name="stageName"
                    control={control}
                    rules={{ required: 'Stage Name is required' }}
                    defaultValue={stageName}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        margin="normal"
                        label="Stage Name"
                        id="stage-name"
                        value={stageName}
                        onInput={e => {
                          setStageName(e.target.value);
                        }}
                        {...register('stageName', { required: true })}
                      />
                    )}
                  />
                  {errors.stageName && (
                    <span role="alert" className="small text-danger">
                      Name is required
                    </span>
                  )}
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-2 d-flex flex-column order-lg-1 invoice_label">
                  <Controller
                    name="stageDesc"
                    control={control}
                    // rules={{ required: 'Description is required' }}
                    defaultValue={stageDesc}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        type="text"
                        margin="normal"
                        label="Stage Description"
                        id="stage-description"
                        value={stageDesc}
                        onInput={e => {
                          setStageDesc(e.target.value);
                        }}
                        {...register('stageDesc', { required: true })}
                      />
                    )}
                  />
                  {errors.stageDesc && (
                    <span role="alert" className="small text-danger">
                      Description is required
                    </span>
                  )}
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-3 d-flex flex-column order-lg-1 invoice_label mt-7">
                  <Controller
                    name="CRM_TeamId"
                    control={control}
                    defaultValue={selectedTeam.value}
                    render={({ field: { onChange, onBlur, value } }) => (
                      <Select
                        placeholder={'Select Team..'}
                        options={Teams}
                        value={Teams.find(c => c.value === (selectedTeam.value ? selectedTeam.value : value))}
                        onChange={val => onChange(val.value)}
                        isDisabled={selectedTeam ? true : false}
                        // defaultValue={Teams.find((c) => c.value === selectedTeam.value)}
                      />
                    )}
                    rules={{ required: true }}
                  />

                  {errors.CRM_TeamId && (
                    <span role="alert" className="small text-danger">
                      Team is required
                    </span>
                  )}
                </div>
                <div className="col-lg-1 d-flex flex-column order-lg-1"></div>
                <div className="col-lg-2 d-flex flex-column order-lg-1 invoice_label mt-7">
                  {editStage ? (
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="crm.updateStage" />
                    </Button>
                  ) : (
                    <Button type="submit" variant="contained" color="primary">
                      <IntlMessages id="crm.addStage" />
                    </Button>
                  )}
                </div>
              </div>
            </form>
            <hr />
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column order-lg-1 invoice_label">
                <TableContainer component={Paper} className={classes.root}>
                  <Table className={classes.table} aria-label="simple table">
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell align="left">Description</TableCell>
                        <TableCell align="left">Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {stages &&
                        stages.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell component="th" scope="row">
                              {row.name}
                            </TableCell>
                            <TableCell align="left">{row.desc}</TableCell>
                            <TableCell align="left">
                              {/* <DeleteOutlineIcon className={classes.pointer} onClick={() => deleteStageName(row.id)} /> */}
                              <DeleteOutlineIcon
                                className={classes.pointer}
                                onClick={e => {
                                  checkCanDeleteStage(row, e);
                                }}
                              />
                              &nbsp;&nbsp;
                              <EditIcon className={classes.pointer} onClick={() => editStageName(row)} />
                            </TableCell>
                          </TableRow>
                        ))}
                      <Modal isOpen={warningModalOpen}>
                        <ModalBody style={{ border: '1px solid rgba(0,0,0,0.25)' }}>
                          <div className="py-2 px-2">
                            <p style={{ fontSize: '1rem' }}>
                              There are contacts in this stage, move them to different stage or delete contacts from the
                              stage
                            </p>
                          </div>
                        </ModalBody>
                        <ModalFooter>
                          <Button
                            variant="contained"
                            style={{ color: '#fff', backgroundColor: '#3f51b5' }}
                            onClick={closeWarningModal}>
                            Ok
                          </Button>
                        </ModalFooter>
                      </Modal>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={toggle}>Cancel</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DialogStageTemplate;
