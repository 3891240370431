import React, { useEffect, useMemo, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactQuill, { Quill } from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'quill-mention';
import 'quill-mention/dist/quill.mention.css';
import 'react-quill/dist/quill.bubble.css';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';

const modules = {
  toolbar: [
    // [{ font: [] }],
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike'],
    [{ color: [] }, { background: [] }],
    [{ script: 'sub' }, { script: 'super' }],
    ['blockquote', 'code-block'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
    ['link', 'image' /* "video"*/],
    // ["clean"],
    // ["fullscreen"]
  ],
};

const DescriptionInput = ({
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onBlur,
  onChange,
  helperText,
  variant,
  error,
  height,
  members,
  handleDescKeyDown,
  isEdtingDescription,
  editorRef,
  tmTeamId,
  tmTaskId,
  setMentionCount,
  getTaskContributors,
  handleDescBlurEditor,
  addNotification,
  selectedCard,
  getCorrectedHTML,
  addNotificaiton,
  kanbanBoard,
  ...rest
}) => {
  const newRef = useRef(null);
  const [contributorId, setContributorId] = useState();
  const { authUser } = useSelector(({ auth }) => auth);
  const dispatch = useDispatch();

  const module = useMemo(() => {
    return {
      toolbar: [
        // [{ font: [] }],
        // [{ header: [1, 2, 3, 4, 5, 6, false] }],
        ['bold', 'italic', 'underline', 'strike'],
        [{ color: [] }, { background: [] }],
        [{ script: 'sub' }, { script: 'super' }],
        ['blockquote', 'code-block'],
        // [{ list:  "ordered" }, { list:  "bullet" }],
        [{ indent: '-1' }, { indent: '+1' }, { align: [] }],
        ['link', 'image', 'video'],
        // ["clean"],
        // ["fullscreen"]
      ],
      history: {
        delay: 500,
        maxStack: 100,
        userOnly: true,
      },
      mention: {
        allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
        mentionDenotationChars: ['@'],
        source: function(searchTerm, renderItem, mentionChar) {
          let values;
          if (mentionChar === '@') {
            values = members;
          }
          if (searchTerm.length === 0) {
            renderItem(values, searchTerm);
          } else {
            const matches = [];
            for (let i = 0; i < values.length; i += 1)
              if (~values[i].value.toLowerCase().indexOf(searchTerm.toLowerCase())) {
                matches.push(values[i]);
              }
            renderItem(matches, searchTerm);
          }
        },
        onSelect: function(item, insertItem) {
          insertItem(item, true);
          let newValues = [];
          // const updateName = {};
          newValues.push(item);

          newValues.forEach((value, key) => {
            setContributorId(+value.id);
          });
          setMentionCount(preV => preV + 1);
        },
      },
    };
  }, [members, editorRef?.current?.state?.value]);

  useEffect(() => {}, [editorRef?.current?.state?.value]);

  useEffect(() => {
    if (contributorId) {
      addTaskContributors();
    }
  }, [contributorId]);

  const addTaskContributors = () => {
    const bodyDescription = {
      tmTeamId: tmTeamId,
      tmTaskId: tmTaskId,
      contributorId: contributorId,
    };

    dispatch({ type: FETCH_START });

    customAxios
      .post('Task/AddTaskContributors', bodyDescription, { headers: authHeader() })
      .then(({ data }) => {
        getTaskContributors();
        const noteObj = {
          UserIdTo: contributorId,
          ActivityId: selectedCard.id,
          NotifyMessage: 'You are Added as Task Contributor on Task Ref:' + selectedCard.tRefNumber,
          FunctionalityId: selectedCard.StatusId,
          TeamId: selectedCard.TeamId,
          IsClicked: 0,
        };
        const emailObj = {
          type: 'taskContributors',
          cardData: selectedCard,
          doneBy: authUser?.Email,
          message: 'task contributor update',
        };
        handleDescBlurEditor();
        addNotification(noteObj, emailObj);
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        return error;
      });
  };

  const checking = () => {
    if (editorRef.current) {
      editorRef.current.focus();
    }
  };

  const quillId = 'myQuill';

  function setFocus() {
    const quillNode = document.getElementById(quillId);
    if (quillNode) {
      quillNode.focus();
    }
  }
  // const testing = document.getElementsByClassName('.description-conatiner');

  useEffect(() => {
    checking();
    setFocus();
  }, [editorRef.current]);

  return (
    <div style={{ width: size, marginBottom: '35px' }}>
      <ReactQuill
        className="description-conatiner"
        ref={editorRef}
        style={{ height: height ? height : '200' }}
        // theme="snow"
        id={quillId}
        // value={value}
        onBlur={isEdtingDescription ? onBlur : null}
        onKeyDown={handleDescKeyDown}
        // onChange={onChange}
        modules={module}
        placeholder="Task Description goes here..."
        defaultValue={value}
        // onChange={(content, delta, source, editor) => {
        //   onChange(content, delta, source, editor);
        // }}
        formats={[
          'header',
          'bold',
          'italic',
          'underline',
          'strike',
          'blockquote',
          'list',
          'bullet',
          'indent',
          'link',
          'image',
          'mention',
          'color',
          'background',
        ]}
      />
    </div>
  );
};

DescriptionInput.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  height: PropTypes.string,
  onBlur: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  members: PropTypes.array,
  editorRef: PropTypes.func,
  handleDescKeyDown: PropTypes.func,
  isEdtingDescription: PropTypes.bool,
};

DescriptionInput.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
};

export default DescriptionInput;

// import React, { useMemo, useRef } from 'react'
// import PropTypes from 'prop-types';
// import ReactQuill from 'react-quill';
// import 'react-quill/dist/quill.snow.css';

// import {
//   FETCH_ERROR,
//   FETCH_START,
//   FETCH_SUCCESS,
//   // INIT_URL,
//   // SIGNOUT_USER_SUCCESS,
//   // USER_DATA,
//   // USER_TOKEN_SET,
// } from '../../../constants/ActionTypes';
// import customAxios from '../../../util/Api';
// import authHeader from '../../../../../services/auth/jwt/auth-header';

// // const modules = {
// //   toolbar: [
// //       [{ font: [] }],
// //       [{ header: [1, 2, 3, 4, 5, 6, false] }],
// //       ["bold", "italic", "underline", "strike"],
// //       [{ color: [] }, { background: [] }],
// //       [{ script:  "sub" }, { script:  "super" }],
// //       ["blockquote", "code-block"],
// //       [{ list:  "ordered" }, { list:  "bullet" }],
// //       [{ indent:  "-1" }, { indent:  "+1" }, { align: [] }],
// //       ["link", "image", "video"],
// //       ["clean"],
// //       ["fullscreen"]
// //   ],
// // };

// const DescriptionInput = ({ type, name, id, fullWidth, size, value, onBlur, helperText, variant, error, height, ...rest }) => {
//   const quillRef = useRef();
//   const imageHandler = (e) => {
//     const editor = quillRef.current.getEditor();
//     console.log(editor)
//     const input = document.createElement("input");
//     input.setAttribute("type", "file");
//     input.setAttribute("accept", "image/*");
//     input.click();

//     input.onchange = async () => {
//       const file = input.files[0];
//       if (/^image\//.test(file.type)) {
//         console.log(file);
//         const bodyAddAttachment = {
//           TaskId: id,
//           AttachmentBlob: "",
//           Attachment: URL.createObjectURL(file),
//           AttachmentName: file.name
//       };

//         // const formData = new FormData();
//         // formData.append("TaskId", id);
//         // formData.append("AttachmentBlob", "");
//         // formData.append("Attachment", file);
//         // formData.append("AttachmentName", file.name);
//         // const res = await ImageUpload(formData); // upload data into server or aws or cloudinary
//         const res = await customAxios.post('/Task/AddAttachment', bodyAddAttachment, { headers: authHeader() })
//         console.log(res);
//         // const url = res?.data?.url;
//         // editor.insertEmbed(editor.getSelection(), "image", url);
//       } else {
//         // ErrorToast('You could only upload images.');
//       }
//     };
//   }
//   const modules = useMemo(() => ({
//     toolbar: {
//       container: [
//         [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
//         ['bold', 'italic', 'underline', "strike"],
//         [{ 'list': 'ordered' }, { 'list': 'bullet' },
//         { 'indent': '-1' }, { 'indent': '+1' }],
//         ['image', "link",],
//         [{ 'color': ['#000000', '#e60000', '#ff9900', '#ffff00', '#008a00', '#0066cc', '#9933ff', '#ffffff', '#facccc', '#ffebcc', '#ffffcc', '#cce8cc', '#cce0f5', '#ebd6ff', '#bbbbbb', '#f06666', '#ffc266', '#ffff66', '#66b966', '#66a3e0', '#c285ff', '#888888', '#a10000', '#b26b00', '#b2b200', '#006100', '#0047b2', '#6b24b2', '#444444', '#5c0000', '#663d00', '#666600', '#003700', '#002966', '#3d1466'] }]
//       ],
//       handlers: {
//         image: imageHandler
//       }
//     },
//   }), [])

//   return (
//     <div style={{ width: size, marginBottom: "35px" }}>
//       <ReactQuill
//         ref={quillRef}
//         style={{ height: height ? height :  "200" }}
//         theme="snow"
//         value={value}
//         onBlur={onBlur}
//         modules={modules}
//         placeholder="Task Description goes here..."
//       />
//     </div>
//   );
// };

// DescriptionInput.prototype = {
//   type: PropTypes.string,
//   name: PropTypes.string,
//   id: PropTypes.string,
//   fullWidth: PropTypes.bool,
//   value: PropTypes.string,
//   variant: PropTypes.string,
//   size: PropTypes.string,
//   height: PropTypes.string,
//   onBlur: PropTypes.func,
//   error: PropTypes.bool,
//   helperText: PropTypes.bool,
// };

// DescriptionInput.defaultProps = {
//   type: 'text',
//   fullWidth: true,
//   size: 'small',
//   error: false,
//   helperText: '',
// };

// export default DescriptionInput;
