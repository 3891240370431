import axios from 'axios';
// import React from 'react';
// import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { AuhMethods } from '../../../services/auth';
import { CurrentAuthMethod } from '../../../@jumbo/constants/AppConstants';
// export default axios.create({
//   baseURL: `https://api.focus247.online/`, //YOUR_API_URL HERE
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

const customAxios = axios.create({
  // baseURL: `http://g-axon.work/jwtauth/api/`, //YOUR_API_URL HERE
  baseURL: `https://api.focus247.online/api/`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

export default customAxios;
