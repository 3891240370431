import * as React from 'react';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export default function RecordToastMessage({
  openRecordToastMessage,
  uploadedRecordsCount,
  setOpenRecordToastMessage,
  handleRecordToastMessage,
}) {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpenRecordToastMessage(false);
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        UNDO
      </Button>
      <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
        <CloseIcon fontSize="small" />
      </IconButton>
    </React.Fragment>
  );

  const message = (
    <div>
      <div>Total Records uploaded: {uploadedRecordsCount.processedRowCount}</div>
      <div>Not uploaded records: {uploadedRecordsCount.notProcessedRowCount}</div>
      <div>Row numbers : {uploadedRecordsCount?.notProcessedRows?.join(', ')}</div>
    </div>
  );

  return (
    <div>
      <Snackbar
        open={openRecordToastMessage}
        autoHideDuration={10000}
        onClose={handleClose}
        message={message}
        action={action}
      />
    </div>
  );
}
