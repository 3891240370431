import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';

import { BrowserRouter as Router, useLocation, useHistory, NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

//variant = 'default', 'standard', 'bgColor'
const ResetPassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const location = useLocation();
  const [open, setOpen] = React.useState(false);
  const [email, setEmail] = useState('');
  const [token, setToken] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passErr, setPassErr] = useState(false);
  const [passConfirmErr, setConfirmPassErr] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  //query params from url
  let query = useQuery();
  // let token = query.get('token');
  // useEffect(() => {
  //   const params = new URLSearchParams(location.search);
  //   if (params.get('email')) {
  //     console.log(params.get('email'));
  //     setEmail(params.get('email'));
  //   }
  // }, [location.search]);

  useEffect(() => {
    if (window.location.href.includes('reset-password')) {
      const array = window.location.href.split('?');
      const alltoken = array[array.length - 1].split('&');
      let fulltoken = alltoken[0].split('token=');
      let fullemail = alltoken[1].split('=');
      setToken(fulltoken[1]);
      // const email = fullemail[1];
      setEmail(fullemail[1]);
      //   dispatch(AuhMethods[CurrentAuthMethod].onUserVerifyInviteUser({ email, token, history }));
      //   localStorage.setItem()
      //   history.push('/reset-password');
    }
  }, []);

  console.log(token, 'token');

  const onSubmit = () => {
    if (token) {
      dispatch(AuhMethods[CurrentAuthMethod].onResetPassword({ email, password, token, history }));
    }
  };

  const validatePass = val => {
    // let regPassword = /^\s+$|^$/gi; //empty space
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setPassErr(true);
    } else {
      setPassErr(false);
      setPassword(val);
    }
  };
  const validateConfirmPass = val => {
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    // let regPassword = /^(?=.*\d).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setConfirmPassErr(true);
    } else {
      setConfirmPassErr(false);
      setConfirmPassword(val);
    }
  };

  return (
    <AuthWrapper variant={wrapperVariant}>
      {variant === 'default' ? (
        <div className={classes.authThumb}>
          <CmtImage src={'/images/auth/forgot-img.png'} />
        </div>
      ) : null}
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/focus-logo/focus-logo.png'} width={'150px'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Reset Password
        </Typography>
        <Collapse in={open}>
          <Alert
            variant="outlined"
            severity="success"
            className={classes.alertRoot}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={() => {
                  setOpen(false);
                }}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            }>
            A mail has been sent on your email address with reset password link.
          </Alert>
        </Collapse>
        <form>
          {/* <div className={'mb-5'}>
            <TextField
              label={<IntlMessages id="appModule.email" />}
              fullWidth
              onChange={event => setEmail(event.target.value)}
              value={email}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
              disabled={true}
            />
          </div> */}
          {/* <div className={'mb-5'}>
            <TextField
              label={<IntlMessages id="appModule.newPassword" />}
              fullWidth
              onChange={event => setNewPassword(event.target.value)}
              defaultValue={newPassword}
              margin="normal"
              variant="outlined"
              className={classes.textFieldRoot}
            />
          </div> */}

          <div className={'mb-5'}>
            {/* <TextField
              type="password"
              label={<IntlMessages id="appModule.password" />}
              error={passErr}
              fullWidth
              onChange={event => validatePass(event.target.value)}
              defaultValue={password}
              margin="normal"
              variant="outlined"
              helperText={
                passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''
              }
              className={classes.textFieldRoot}
            /> */}
            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.password" />
              </InputLabel>
              <OutlinedInput
                error={passErr}
                fullWidth
                onChange={event => validatePass(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                helperText={
                  passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''
                }
                className={classes.textFieldRoot}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={<IntlMessages id="appModule.password" />}
              />
              <FormHelperText id="component-error-text" className="error-password">
                {passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''}
              </FormHelperText>
            </FormControl>
          </div>
          <div className={'mb-5'}>
            {/* <TextField
              type="password"
              label={<IntlMessages id="appModule.confirmPassword" />}
              error={passConfirmErr || password !== confirmPassword}
              fullWidth
              onChange={event => validateConfirmPass(event.target.value)}
              defaultValue={confirmPassword}
              margin="normal"
              variant="outlined"
              helperText={
                passConfirmErr
                  ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                  : password !== confirmPassword
                  ? 'Password & Confirm Password does not match'
                  : ''
              }
              className={classes.textFieldRoot}
            /> */}
            <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
              <InputLabel htmlFor="outlined-adornment-password">
                <IntlMessages id="appModule.confirmPassword" />
              </InputLabel>
              <OutlinedInput
                error={passConfirmErr || password !== confirmPassword}
                fullWidth
                onChange={event => validateConfirmPass(event.target.value)}
                defaultValue={confirmPassword}
                margin="normal"
                variant="outlined"
                helperText={
                  passConfirmErr
                    ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                    : password !== confirmPassword
                    ? 'Password & Confirm Password does not match'
                    : ''
                }
                className={classes.textFieldRoot}
                id="outlined-adornment-password"
                type={showPassword ? 'text' : 'password'}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
                label={<IntlMessages id="appModule.confirmPassword" />}
              />
              <FormHelperText id="component-error-text" className="error-password">
                {passConfirmErr
                  ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                  : password !== confirmPassword
                  ? 'Password & Confirm Password does not match'
                  : ''}
              </FormHelperText>
            </FormControl>
          </div>

          <div className={'mb-5'}>
            <Button onClick={onSubmit} variant="contained" color="primary">
              <IntlMessages id="appModule.resetPassword" />
            </Button>
          </div>

          <div>
            <Typography>
              Click here to Sign In!
              <span className={'ml-2'}>
                {/* <Link href="#">
                  <IntlMessages id="appModule.contactSupport" />
                </Link> */}
                <NavLink to="/signin">
                  <IntlMessages id="appModule.signIn" />
                </NavLink>
              </span>
            </Typography>
          </div>
        </form>
        <ContentLoader />
      </div>
    </AuthWrapper>
  );
};

export default ResetPassword;
