import React, { useEffect, useRef, useState } from 'react';
import { IconButton, makeStyles, TextField } from '@material-ui/core';
import WarningIcon from '@mui/icons-material/Warning';
import { DatePicker } from '@material-ui/pickers';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import Popover from '@mui/material/Popover';
import Button from '@material-ui/core';
import { useDispatch } from 'react-redux';

const datePickerStyles = makeStyles(theme => ({
  root: {
    '& .MuiTextField-root': {
      width: 200,
    },
  },
}));
const DatePickerItem = ({ data, updateTable }) => {
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);
  useEffect(() => {
    if (data) setAnchorEl(data.event.target);
  }, [data]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const updateFollowUpDate = args => {
    // console.log(new Date(args._d).toISOString(),data.info.crmId);
    handleClose();
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(
          `Contacts/AddContactFollowUpDate`,
          {
            CRM_ContactId: data.info.crmId,
            NextContactDate: new Date(args._d).toISOString(),
          },
          {
            headers: authHeader(),
          },
        )
        .then(() => {
          updateTable(data.info.crmId);
          console.log('new Follow up date Added');

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  const open = Boolean(anchorEl);

  const id = open ? 'datePicker' : undefined;

  return (
    <React.Fragment>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        <DatePicker
          className={datePickerStyles().root}
          disableToolbar={true}
          open={open}
          format="DD-MM-YYYY"
          onClose={handleClose}
          variant="inline"
          defaultValue={data ? data.info.FollowUpDate : null}
          id={id}
          aria-labelledby="FollowUpDate"
          PopoverProps={{ anchorEl: anchorEl }}
          TextFieldComponent={() => null}
          onChange={args => updateFollowUpDate(args)}
        />
      </Popover>
    </React.Fragment>
  );
};

const FollowUpDateView = ({ info, clickevent }) => {
  return (
    <div style={{ color: 'red' }}>
      {info.localFollowUpDate}
      <IconButton
        onClick={event => {
          clickevent({ event: event, info: info });
          event.persist();
          event.ignore = true;
        }}>
        <WarningIcon size="small" variant="outlined" color="error" />
      </IconButton>
    </div>
  );
};

export { FollowUpDateView, DatePickerItem };
