import React, { useEffect, useState } from 'react';
import GridContainer from '../../../@jumbo/components/GridContainer';
import PageContainer from '../../../@jumbo/components/PageComponents/layouts/PageContainer';
import IntlMessages from '../../../@jumbo/utils/IntlMessages';
import Grid from '@material-ui/core/Grid';
import IdeasWidget from '../../Metrics/Classic/IdeasWidget';
import YearlySalesReport from '../../YearlySalesReport';
import AssignmentIcon from '@mui/icons-material/Assignment';
//icons
import WbSunnyIcon from '@material-ui/icons/WbSunny';
import Money from '@material-ui/icons/Money';
import ReceiptIcon from '@material-ui/icons/Receipt';
import EuroIcon from '@material-ui/icons/Euro';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../constants/ActionTypes';
import axios from '../util/Api';
import authHeader from '../../../services/auth/jwt/auth-header';

//react redux
import { useDispatch } from 'react-redux';
import customAxios from '../util/Api';
import PermContactCalendarIcon from '@mui/icons-material/PermContactCalendar';
import AddTaskIcon from '@mui/icons-material/AddTask';

const breadcrumbs = [
  { label: <IntlMessages id={'sidebar.main'} />, link: '/' },
  { label: <IntlMessages id={'pages.dashboard'} />, isActive: true },
];

const tasksIcon = <AddTaskIcon style={{ color: '#ffffff' }} />;
const crmIcon = <PermContactCalendarIcon style={{ color: '#ffffff' }} />;
const reportIcon = <AssignmentIcon style={{ color: '#ffffff' }} />;
const moneyIcon = <Money style={{ color: '#ffffff' }} />;
const sunnyIcon = <WbSunnyIcon style={{ color: '#ffffff' }} />;
const receiptIcon = <ReceiptIcon style={{ color: '#ffffff' }} />;
const euroIcon = <EuroIcon style={{ color: '#ffffff' }} />;
const DashboardPage = () => {
  const dispatch = useDispatch();
  const [data, setData] = useState([]);

  // const gridInstance;
  const getClients = () => {
    dispatch({ type: FETCH_START });
    //var token = localStorage.getItem("token");
    //alert(token);
    //axios.defaults.headers.common['Authorization'] = "Bearer "+token.replace('"',"");
    axios
      .get('Dashboard/GetDashboard', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setData(data);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          //   console.log('payload: data.error', data.error);
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        // console.log('Error****:', error.message);
      });
  };

  useEffect(() => {
    getClients();
  }, []);

  return (
    // <PageContainer heading={<IntlMessages id="pages.dashboard" />} breadcrumbs={breadcrumbs}>
    <PageContainer>
      <GridContainer>
        <Grid item xs={12} md={12} xl={12}>
          <GridContainer>
            {data.length > 0
              ? data.map((item, index) => (
                  <Grid item xs={12} sm={4} key={index}>
                    <IdeasWidget
                      number={item.CardValue}
                      subTitle={item.CardTitle}
                      backgroundColor={
                        item.CardTitle === 'Clients'
                          ? '#0795F4'
                          : item.CardTitle === 'Invoices'
                          ? '#00C4B4'
                          : item.CardTitle === 'Sales'
                          ? 'rgb(255, 168, 0)'
                          : item.CardTitle === 'Expenses'
                          ? 'rgb(41, 207, 107)'
                          : item.CardTitle === 'Total Invoices Amount'
                          ? 'rgb(255, 140, 0)'
                          : item.CardTitle === 'Task Management'
                          ? 'rgb(225, 140, 0)'
                          : item.CardTitle === 'EA Reports'
                          ? 'rgb(185, 140, 0)'
                          : item.CardTitle === 'Contact Overview'
                          ? 'rgb(125, 140, 0)'
                          : ''
                      }
                      icon={
                        item.CardTitle === 'Clients'
                          ? sunnyIcon
                          : item.CardTitle === 'Invoices'
                          ? receiptIcon
                          : item.CardTitle === 'Sales'
                          ? euroIcon
                          : item.CardTitle === 'Expenses'
                          ? euroIcon
                          : item.CardTitle === 'Total Invoices Amount'
                          ? moneyIcon
                          : item.CardTitle === 'Task Management'
                          ? tasksIcon
                          : item.CardTitle === 'EA Reports'
                          ? reportIcon
                          : item.CardTitle === 'Contact Overview'
                          ? crmIcon
                          : ''
                      }
                      linkOnArrow={item.CardHref}
                    />
                  </Grid>
                ))
              : null}

            <Grid item xs={12} xl={5}>
              <YearlySalesReport />
            </Grid>

            {/* <Grid item xs={12} sm={6}>
              <Documents />
            </Grid>

            <Grid item xs={12} md={12}>
              <CitiesBgCard />
            </Grid> */}
          </GridContainer>
        </Grid>
      </GridContainer>
    </PageContainer>
  );
};

export default DashboardPage;
