import React, { useEffect, useState } from 'react';

import { IntlProvider } from 'react-intl';
import Lightbox from 'react-image-lightbox';

const LightboxGallery = ({ data }) => {
  const [photoIndex, setPhotoIndex] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [imagesData, setImagesData] = useState([]);

  const images = [
    { url: 'https://picsum.photos/200/300' },
    { url: 'https://picsum.photos/200' },
    { url: 'https://picsum.photos/id/237/200/300' },
    { url: 'https://picsum.photos/seed/picsum/200/300' },
    { url: 'https://picsum.photos/200' },
    { url: 'https://picsum.photos/200/300?grayscale' },
  ];

  useEffect(() => {
    if (data.length > 0) {
      let newImgs = [];
      data.forEach(elem => {
        if (
          elem.metaData.type.split('/')[1] === 'jpeg' ||
          elem.metaData.type.split('/')[1] === 'jpg' ||
          elem.metaData.type.split('/')[1] === 'gif' ||
          elem.metaData.type.split('/')[1] === 'png'
        ) {
          newImgs.push({ url: elem.fullImage });
        }
      });
      console.log(newImgs);
      // setImagesData(newImgs);
    }
  }, [data]);

  return (
    <>
      <div>
        <div className="row">
          <div className="col">
            {imagesData.length > 0 &&
              imagesData.map((img1, index) => (
                <img
                  className="lightbox-img"
                  src={img1.url}
                  onClick={() => {
                    setIsOpen(true);
                    setPhotoIndex(index);
                  }}
                  width="40px"
                  height="40px"
                />
              ))}
          </div>
        </div>

        {isOpen && (
          <Lightbox
            mainSrc={imagesData[photoIndex].url}
            nextSrc={imagesData[(photoIndex + 1) % imagesData.length].url}
            prevSrc={imagesData[(photoIndex + imagesData.length - 1) % imagesData.length].url}
            onCloseRequest={() => setIsOpen(false)}
            onMovePrevRequest={() => setPhotoIndex((photoIndex + imagesData.length - 1) % imagesData.length)}
            onMoveNextRequest={() => setPhotoIndex((photoIndex + 1) % imagesData.length)}
          />
        )}
      </div>
    </>
  );
};

export default LightboxGallery;
