import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Button } from '@mui/material';
const ConfirmationModal = props => {
  const { isModalOpen, handleSave, handleClose, modalTitle, modalMainText, hideCloseBtn, cancelBtnTxt, saveBtnText } = {
    ...props.confirmationObj,
  };

  return (
    <Dialog open={isModalOpen} onClose={handleClose}>
      {/* //<DialogTitle>Subscribe</DialogTitle> */}

      <DialogTitle>{modalTitle}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{modalMainText}</DialogContentText>
      </DialogContent>

      <DialogActions>
        <Button style={{ display: hideCloseBtn ? 'none' : 'block' }} onClick={handleClose}>
          {cancelBtnTxt || 'Cancel'}
        </Button>
        <Button variant="contained" onClick={handleSave}>
          {saveBtnText || 'Ok'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
