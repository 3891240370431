import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import CmtDropdownMenu from '../../../../@coremat/CmtDropdownMenu';
import CmtAvatar from '../../../../@coremat/CmtAvatar';
import { Box } from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@material-ui/icons/Person';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { useDispatch, useSelector } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import SettingsIcon from '@material-ui/icons/Settings';
import UserProfile from 'routes/Pages/Profile/index';

import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from 'routes/Pages/util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from 'services/auth/jwt/auth-header';

const useStyles = makeStyles(theme => ({
  profileRoot: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: 10,
    position: 'relative',
    [theme.breakpoints.up('md')]: {
      paddingLeft: 20,
    },
    '&:before': {
      content: '""',
      position: 'absolute',
      left: 0,
      top: 2,
      zIndex: 1,
      height: 35,
      width: 1,
      backgroundColor: alpha(theme.palette.common.dark, 0.15),
    },
  },
}));

const UserDropDown = () => {
  const actionsList = [
    {
      icon: <PersonIcon />,
      label: 'Profile',
      onClick: () => {
        console.log(' Profile Clicked');
        openUserProfileDialog();
      },
    },
    {
      icon: <SettingsIcon />,
      label: 'Settings',
    },
    {
      icon: <PersonIcon />,
      label: 'Change Password',
    },
    {
      icon: <ExitToAppIcon />,
      label: 'Logout',
    },
  ];
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { authUser } = useSelector(({ auth }) => auth);
  const [openUserProfile, setOpenUserProfile] = useState(false);
  const [userData, setUserData] = useState({
    AvatarImage: null,
    CompanyId: null,
    ContactNumber: null,
    EmailAddress: null,
    FirstName: null,
    Id: null,
    LastName: null,
    UserId: null,
  });

  let isMounted = true; // Track whether the component is mounted

  const onItemClick = item => {
    if (item.label === 'Logout') {
      dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      clearInterval();
    }
    if (item.label === 'Change Password') {
      history.push('/my-account/change-password');
    }
  };

  const closeUserProfileDialog = () => {
    setOpenUserProfile(false);
  };

  const openUserProfileDialog = () => {
    setOpenUserProfile(true);
  };

  // Cleanup function to cancel asynchronous tasks
  useEffect(() => {
    return () => {
      isMounted = false;
    };
  }, []);

  // Get user profile data
  const getUserProfileData = () => {
    dispatch({ type: FETCH_START });

    customAxios
      .get('Users/GetUserProfile', { headers: authHeader() })
      .then(({ data }) => {
        if (isMounted) {
          // Check if the component is still mounted before updating state
          if (data) {
            let correctedData = data;
            correctedData['AvatarImage'] = `data:image/jpeg;base64,${data.AvatarImage}`;
            setUserData(correctedData);
            dispatch({ type: FETCH_SUCCESS });
          }
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
      });
  };

  function stringAvatar(name) {
    return name
      ? {
          children: `${name.charAt(0)}${name.charAt(1)}`,
        }
      : null;
  }

  useEffect(() => {
    getUserProfileData();
  }, []);

  return (
    <Box className={clsx(classes.profileRoot, 'Cmt-profile-pic')}>
      <CmtDropdownMenu
        onItemClick={onItemClick}
        TriggerComponent={<CmtAvatar size="small" src={userData.AvatarImage} {...stringAvatar(userData.EmailAddress)} />}
        items={[{ label: authUser ? authUser.Email : '' }, ...actionsList]}
      />
      <UserProfile
        profileOpenState={openUserProfile}
        closeProfileDialog={closeUserProfileDialog}
        getProfileData={getUserProfileData}
        data={userData}
        setData={setUserData}
      />
    </Box>
  );
};

export default UserDropDown;
