/**
 * Version 1.0
 * Vikas W
 * 07.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useEffect, useRef, useState } from 'react';
// import ContainerHeader from '../../components/ContainerHeader/index';
// import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../../@jumbo/constants/AppConstants';
import Sidebar from './Sidebar';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// import CmtCardHeader from '../../../../@coremat/CmtCard/CmtCardHeader';

/* Material Icons */
import EditIcon from '@material-ui/icons/Edit';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
// import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import IconButton from '@material-ui/core/IconButton';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@mui/material/Typography';
import InputBase from '@mui/material/InputBase';
import MailIcon from '@mui/icons-material/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import { Close } from '@material-ui/icons';

/* UI Components */
import Button from '@material-ui/core/Button';
import { FETCH_START, FETCH_ERROR, FETCH_SUCCESS } from '@jumbo/constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';

import DialogStageTemplate from './DialogStageTemplate';
import MailForm from './MailForm';

// import { set } from 'lodash';

import ImportExcel from './ImportExcel';
// import Toast from './Toast';
import { makeStyles, Tooltip } from '@material-ui/core';
import Tostify from 'routes/Pages/Accounting/InvoicePage/Tostify';

// genral DialogBox
import { Dialog, DialogTitle, DialogContent, DialogActions } from '@material-ui/core';

/* Tabs Config */
import Badge from '@mui/material/Badge';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { Box, Tab, FormControl } from '@mui/material';
import Select from 'react-select';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import { Draggable } from 'react-beautiful-dnd';
import { styled, alpha } from '@mui/material/styles';
import { Stack } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
// import Avatar from '@mui/material/Avatar';
// import AvatarGroup from '@mui/material/AvatarGroup';
// import { te } from 'date-fns/locale';

// menu
import ActionMenu, { AddMenu } from './ActionMenu';

import AddContactSidebar from './AddContactModal';
import Filters from './Filters';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
// import { template } from 'lodash';
import { NavLink } from 'react-router-dom';
// mui data grid
import { DataGrid } from '@mui/x-data-grid';
import { FollowUpDateView, DatePickerItem } from './crmTableComponents/FollowUpDateView';
import { SalesRepView } from './crmTableComponents/SalesRepView';
import RecordToastMessage from './RecordToastMessage';
import CopyMoveContact from './CopyMoveContact';
// import { TroubleshootTwoTone } from '@mui/icons-material';
import NotProcessedDialog from './NotProcessedDialog';
import TeamDataListView from '../ContactOverview/crmFilterComponents/TeamDataListView';
import ConfirmationModal from 'routes/Pages/Planning/TaskmanagementPage/ConfirmationModal';
import QuickActionMenu from './crmTableComponents/QuickActionMenu';

const useStageStyles = makeStyles(theme => ({
  actionIcon: {
    cursor: 'pointer',
  },
}));

const StyledTabList = styled(TabList)();
const StyledTab = styled(Tab)();
const useTabStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    fontFamily: 'Normal',

    // backgroundColor: theme.palette.background.paper,
  },
  tabBody: {
    padding: 0,

    '& > div': {
      padding: 0,
      marginTop: 10,
    },
  },
  tabFontFam: {
    div: {
      fontFamily: 'normal',
    },
    span: { fontFamily: 'normal' },
  },
  tabsRow: {
    display: 'flex',
    '& .MuiCardContent-root': {
      width: '100%',
    },
  },
  tabsRowPlus: {
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    margin: '17px',
    cursor: 'pointer',
    width: '30px',
    height: '30px',
    float: 'right',
    marginTop: '-35px',
    marginRight: '25px',
    position: 'relative',
  },
  badges: {
    marginTop: '10px',
  },
  noTabs: {
    marginTop: '5px',
    fontSize: '1rem',
    padding: '5px 20px',
  },
}));
function DraggableTab(props) {
  return (
    <Draggable draggableId={`${props.index}`} index={props.index} disableInteractiveElementBlocking>
      {draggableProvided => (
        <div ref={draggableProvided.innerRef} {...draggableProvided.draggableProps} className="tabs-card">
          <Badge badgeContent={props.badgescount} color="primary">
            {React.cloneElement(props.child, {
              ...props,
              ...draggableProvided.dragHandleProps,
            })}
          </Badge>
        </div>
      )}
    </Draggable>
  );
}

/* START: Search Icon */
const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  fontFamily: 'Normal',
  marginLeft: 0,
  width: '100%',
  borderWidth: '1px',
  borderColor: '#f8f9fa',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    border: '1px solid #d8d8d8',
    marginRight: '10px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));
/* End: Search Icon */

/* START : Dialog Wrapper */

const GenralDialogWrapper = ({ children, openState, toggleAction, clearAction, confirmAction }) => {
  const cancel = () => {
    toggleAction(false);
    if (clearAction) {
      clearAction();
    }
  };
  const confirm = () => {
    toggleAction(false);
    if (confirmAction) {
      confirmAction();
    }
    // confirmAction();
  };

  return (
    <Dialog open={openState} maxWidth="sm" fullWidth>
      <DialogTitle>Confirm the action</DialogTitle>
      <Box position="absolute" top={0} right={0}>
        <IconButton onClick={cancel}>
          <Close />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography> {children}</Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" style={{ textTransform: 'none' }} variant="contained" onClick={cancel}>
          Cancel
        </Button>
        <Button color="primary" style={{ textTransform: 'none' }} variant="contained" onClick={confirm}>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};

/* END : Dialog Wrapper */

const ContactsOverview = props => {
  const dispatch = useDispatch();

  /* Stages Add/delete */
  const classes = useStageStyles();

  /* CRM Teams */
  const [Teams, setTeams] = useState([]);
  const [TeamMembers, setTeamMembers] = useState([]);
  const [SelectedTeamMembers, setSelectedTeamMembers] = useState([]);
  const [teamWiseTabs, setTeamWiseTabs] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState({});
  const [Stages, setStages] = useState([]);
  const [showAlert, setShowAlert] = useState(false);

  /* START: Stage Name : Add, Edit, Delete */
  // let stageName = {};
  const [stageModal, setStageModal] = useState(false);
  /* modal open/close */
  const stageToggle = () => {
    setStageModal(!stageModal);
    setAnchorEl(null);
  };
  /* START: Stage Name : Add, Edit, Delete */

  const [modal, setModal] = useState(false);
  const [data, setData] = useState([]);
  const [searchKey, setSearchKey] = useState('');
  const [searchData, setSearchData] = useState([]);

  const [hideDialog, setHideDialog] = useState(false);
  const [hideMailDialog, setHideMailDialog] = useState(true);

  const [mailData, setMailData] = useState({});

  /* Filter start */
  const [columnFilters, setColumnFilters] = useState(false); //column level filter
  const [filterModalOpen, setFilterModalOpen] = useState(false); //filter dialog box
  const [filterApplied, setFilterApplied] = useState(false);
  // const [allTeamMembers, setAllTeamMembers] = useState([]);
  const inputRef = useRef(null);

  /* Copy/Move Contact */
  const [copyMoveContact, setcopyMoveContact] = useState({ open: false, isCopy: true });

  const closeCopyMoveContactModal = () => {
    setcopyMoveContact({ open: false, isCopy: false });
  };

  const closeFilterModel = () => {
    setFilterModalOpen(false);
  };
  const openFilterModel = () => {
    setFilterModalOpen(true);
  };
  const filterModalToggle = () => {
    setFilterModalOpen(!filterModalOpen);
  };
  /* Filter end */

  /* Side Bar Start*/

  const infoSidebar = useRef(null);
  const addContactSidebar = useRef(null);
  const [credisafeCompanyData, setCreditSafeCompanydata] = useState({});
  const [credisafeCompanyDatas, setCreditSafeCompanydatas] = useState({});
  const [emailCommunicationData, setEmailCommunicationData] = useState([]);
  const [notesData, setNotesData] = useState([]);
  const [infoSidebarData, setInfoSidebarData] = useState([]);
  const [addContactToggel, setAddContactToggle] = useState(false);
  const [credisafeCompanyNameData, setCreditSafeCompanyNameData] = useState([]);
  const continuePollingRef = useRef(true);

  //hovering on crm table row state
  const [hoveredRow, setHoveredRow] = useState(null);

  //confirmation modal
  const defaultConfirmationModalObj = {
    isModalOpen: false,
    handleSave: null,
    handleClose: null,
    modalTitle: '',
    modalMainText: '',
    hideCloseBtn: false,
    cancelBtnTxt: null,
    saveBtnText: null,
  };
  const [userDetails, setUserDetails] = useState();
  const [confirmationData, setConfirmationData] = useState({ ...defaultConfirmationModalObj });

  const getUserProfileDetails = () => {
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get('Users/GetUserProfile', { headers: authHeader() })
        .then(({ data }) => {
          let correctedData = data;

          setUserDetails(correctedData);
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  //side bar info panel
  function openSidebarInfoPanel(args) {
    continuePollingRef.current = false;

    dispatch({ type: FETCH_START });

    try {
      customAxios
        .post(
          '/CreditSafe/GetCompanyIdFromName',
          {
            CompanyName: `${args.rowData.crmCompany}`,
            PageSize: 20,
            Page: 1,
          },
          { headers: authHeader() },
        )
        .then(async data => {
          console.log('data', data);
          let currCompanyData = data.data?.companies;

          let companyRegNo = currCompanyData.length > 0 ? currCompanyData[0]['id'] : null;

          if (companyRegNo) {
            setCreditSafeCompanyNameData(currCompanyData);
            customAxios
              .post('/CreditSafe/GetCompanyCreditReport', { RegNo: companyRegNo }, { headers: authHeader() })
              .then(res => {
                console.log(res);
                let companyData = JSON.parse(res.data);
                console.log(companyData);
                let credisafeData = companyData.report;
                let opData = {
                  name: credisafeData.companySummary.businessName,
                  type: credisafeData.companyIdentification.basicInformation.legalForm.description,
                  address: credisafeData.companyIdentification.basicInformation.contactAddress.simpleValue,
                  regNo: credisafeData.companyIdentification.basicInformation.companyRegistrationNumber,
                  safeNo: credisafeData.companySummary.companyNumber,
                  status: credisafeData.companyIdentification.basicInformation.companyStatus.status,
                  statusDescription: credisafeData.companyIdentification.basicInformation.companyStatus.description,
                  creditRating: credisafeData.companySummary.creditRating.commonValue,
                  crmBackgroundInfo: credisafeData.companyIdentification.basicInformation.principalActivity,
                  creditReport: credisafeData.creditScore,
                };
                setCreditSafeCompanydatas(opData);
              });
          }

          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(function(error) {
          console.log(error);
          setCreditSafeCompanydatas({
            name: '',
            type: '',
            address: '',
            regNo: '',
            safeNo: '',
            status: '',
            statusDescription: '',
            creditRating: '',
            crmBackgroundInfo: '',
            creditReport: '',
          });
          // dispatch({ type: FETCH_ERROR, payload: 'Credit Data Not avaliable' });
          dispatch({ type: FETCH_SUCCESS });
        });
    } catch (e) {
      console.log(e);
    }

    getEmailCommunication(args.rowData);

    getNotesData(args.rowData);
    setInfoSidebarData(args.rowData);
    setHideDialog(false);
    infoSidebar.current.element.style.visibility = '';
    infoSidebar.current.show();
  }

  const handleCheckCredits = regNo => {
    let credits = { RegNo: regNo };

    dispatch({ type: FETCH_START });
    customAxios
      .post('/CreditSafe/GetCompanyCreditReport', credits, { headers: authHeader() })

      .then(data => {
        let credisafeData = JSON.parse(data.data).report;

        let opData = {
          name: credisafeData.companySummary.businessName,
          type: credisafeData.companyIdentification.basicInformation.legalForm.description,
          address: credisafeData.companyIdentification.basicInformation.contactAddress.simpleValue,
          regNo: credisafeData.companyIdentification.basicInformation.companyRegistrationNumber,
          safeNo: credisafeData.companySummary.companyNumber,
          status: credisafeData.companyIdentification.basicInformation.companyStatus.status,
          statusDescription: credisafeData.companyIdentification.basicInformation.companyStatus.description,
          creditRating: credisafeData.companySummary.creditRating.commonValue,
          crmBackgroundInfo: credisafeData.companyIdentification.basicInformation.principalActivity,
          creditReport: credisafeData.creditScore,
        };
        setCreditSafeCompanydatas(opData);

        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        setCreditSafeCompanydatas({
          name: '',
          type: '',
          address: '',
          regNo: '',
          safeNo: '',
          status: '',
          statusDescription: '',
          creditRating: '',
          crmBackgroundInfo: '',
          creditReport: '',
        });
        // dispatch({ type: FETCH_ERROR, payload: 'Credit Data Not avaliable' });
        dispatch({ type: FETCH_SUCCESS });
      });
  };

  // Get Email Communication
  function getEmailCommunication(args) {
    if (!args) return;
    let emailObj = {
      crmContactId: args.crmId,
      crmTeamId: args.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRM/GetEmailCommunications`, emailObj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data) {
            setEmailCommunicationData(data);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  // get node start
  function getNotesData(args) {
    let noteObj = {
      crmContactId: args.crmId,
      crmTeamId: args.CrmTeamId,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/GetCRMNotes/`, noteObj, {
          headers: authHeader(),
        })
        .then(({ data }) => {
          if (data) {
            setNotesData(data);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  //send Email notification
  const checkArrAuth = item => {
    if (item) {
      return item.length > 0 ? item : [];
    }
    return [];
  };
  const sendEmailNotification = (toEmail, NotiMessage, subject, ccEmail, bccEmail) => {
    console.log('notificationSend');

    let emailObj = {
      EmailSubject: subject ? subject : 'Focus Notification',
      EmailPlainTextBody: '',
      EmailHtmlBody: NotiMessage ? `<div>${NotiMessage}</div>` : `<div></div>`,
      ToList: checkArrAuth(toEmail),
      CcList: checkArrAuth(ccEmail),
      BccList: checkArrAuth(bccEmail),
    };
    try {
      customAxios
        .post('Invoice/SendEmail', emailObj, { headers: authHeader() })
        .then(({ data }) => {
          console.log('notification email sent successfully');
        })
        .catch(error => {
          console.log(error);
        });
    } catch (e) {}
  };
  /* Side Bar End*/

  /* START : Tab Styles */
  const [uniqueTab, setUniqueTab] = useState([]);
  const [tabs, setTabs] = React.useState([]);
  const tabClasses = useTabStyles();
  const [value, setValue] = React.useState('1');

  const handleChange = (event, newValue) => {
    setValue(newValue);
    localStorage.setItem('crmCurrStage', JSON.stringify(newValue));
  };
  const onDragEnd = result => {
    /* filtering stages based on Team dropdown selection from top */
    const newTabs = Array.from(teamWiseTabs);
    const draggedTab = newTabs.splice(result.source.index, 1)[0];
    newTabs.splice(result.destination?.index, 0, draggedTab);
    // console.log('teamWiseTabs', teamWiseTabs);
    // console.log('selectedTeam', selectedTeam);
    // console.log(newTabs);
    updateStages(newTabs);
    setTeamWiseTabs(newTabs);
  };

  const updateStages = async obj => {
    const responses = [];
    for (let i = 0; i < obj.length; i++) {
      let url = '/CRM/Edit_CRM_Stage';
      let editObj = [
        {
          TableName: 'CRM_Stages',
          ColumnName: 'SortOrder',
          NewValue: i,
          PrimaryKeyValue: obj[i].StageId,
          crmTeamId: obj[i].CrmTeamId,
        },
      ];
      responses.push(await customAxios.post(url, editObj, { headers: authHeader() }));
    }
    if (responses) {
      getStages();
    }
  };

  const _renderTabList = droppableProvided => (
    <StyledTabList onChange={handleChange} variant="scrollable">
      {teamWiseTabs.length > 0 &&
        teamWiseTabs.map((tab, index) => {
          const child = (
            <StyledTab
              label={tab.label}
              value={tab.value}
              key={index}
              style={{
                textTransform: 'capitalize',
                fontFamily: 'Normal ',
              }}
            />
            // <Tab label={<Badge badgeContent={6} color="primary">{tab.label}</Badge>} value={tab.value} key={index}/>
          );
          const tabCount = searchData.length > 0 ? getFilterTabData(searchData, tab.label).length : 0;

          return (
            <DraggableTab
              label={tab.label}
              value={tab.value}
              index={index}
              key={index}
              child={child}
              badgescount={tabCount}
            />
          );
        })}
      {droppableProvided ? droppableProvided.placeholder : null}
    </StyledTabList>
  );

  const _renderTabListWrappedInDroppable = () => (
    <DragDropContext onDragEnd={onDragEnd}>
      <div>
        <Droppable droppableId="1" direction="horizontal">
          {droppableProvided => (
            <div ref={droppableProvided.innerRef} {...droppableProvided.droppableProps}>
              {_renderTabList(droppableProvided)}
            </div>
          )}
        </Droppable>
      </div>
    </DragDropContext>
  );

  /* END : Tab Styles */

  customAxios.interceptors.response.use(
    function(response) {
      return response;
    },
    function(error) {
      if (error.response.status === 401) {
        dispatch(AuhMethods[CurrentAuthMethod].onLogout());
      } else {
        return Promise.reject(error);
      }
    },
  );

  const openAddContact = () => {
    setAddContactToggle(true);
    addContactSidebar.current.element.style.visibility = '';
    addContactSidebar.current.show();
  };

  const [actionMenuData, setActionMenuData] = useState({});
  //tets

  function toastCreated() {
    setShowAlert(true);
  }

  /** Add or Edit Clients **/
  function addNewContact(url, data) {
    for (let key in data) {
      if (data[key] === undefined) {
        data[key] = '';
      } else if (data[key] === null) {
        data[key] = 0;
      }
    }
    let isStageEmpty = false;

    if (data.crmStageName == '') {
      isStageEmpty = true;
    }

    dispatch({ type: FETCH_START });
    customAxios
      .post(url, data, { headers: authHeader() })
      .then(data => {
        getContacts();

        // let newAddedRecord = getContactExtraFields(data);
        // console.log(newAddedRecord);

        // setData((prevState)=>{
        //   let newState = [...prevState];
        //   let currRecordData = [{...newAddedRecord},...newState];
        //   console.log(currRecordData);
        //   return currRecordData;

        // })
        // setSearchData((prevState)=>{
        //   let newState = [...prevState];
        //   let currRecordData = [{...newAddedRecord},...newState];
        //   console.log(currRecordData)
        //   return currRecordData;

        // })

        if (isStageEmpty) getStages();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  /** Delete Client **/
  function deleteContact(obj) {
    let contactID = obj.ContactId;
    dispatch({ type: FETCH_START });
    customAxios
      .post('CRM/DeleteContact', obj, { headers: authHeader() })
      .then(data => {
        // getContacts();
        updateCRMContactList(contactID);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  function updateCRMContactList(contactID) {
    let returnedData = [];
    setData(prevState => {
      returnedData = removeContactFromList(prevState, contactID);
      return returnedData;
    });
    setSearchData(prevState => {
      returnedData = removeContactFromList(prevState, contactID);
      return returnedData;
    });
  }
  function removeContactFromList(prevState, contactID) {
    let newState = [...prevState];
    let dataIndex = newState.filter(con => con.crmId != contactID);
    // newState.splice(dataIndex, 1);

    return dataIndex;
    //   let currRecordData = [{...newAddedRecord},...newState];
    //   console.log(currRecordData);
    //   return currRecordData;
  }

  function deleteMultipleContact(records) {
    let recordIDs = records.map(item => {
      return { ContactId: item.crmId, crmTeamId: selectedTeam.value };
    });
    console.log(recordIDs);
    dispatch({ type: FETCH_START });
    customAxios
      .post('/CRM/DeleteMultipleContacts', recordIDs, { headers: authHeader() })
      .then(data => {
        getContacts();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  }

  function getUniqueTabData(data) {
    let objArr = [];
    objArr = searchData.reduce((acc, cur) => [...acc.filter(obj => obj.crmStageName !== cur.crmStageName), cur], []);
    setUniqueTab(objArr);
  }

  function getFilterTabData(obj, stageName) {
    let objArr = [];
    objArr = obj.filter(obj => obj.crmStageName === stageName && obj.CrmTeamId === selectedTeam.value);

    // setFilterInputData(objArr);
    return objArr;
  }

  /** Get All Client on page load**/
  useEffect(() => {
    getTeams();
    getUserProfileDetails();

    // getStages();
  }, []);

  /** Get All Stages **/
  const getStages = id => {
    let currTeam = id ? id : selectedTeam.value;
    let url = 'CRM/Get_CRM_Stages/' + currTeam;
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            updateCrmStages(data);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ tysetpe: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  useEffect(() => {
    if (selectedItem) {
      getStages();
    }
  }, [selectedTeam]);
  const updateCrmStages = data => {
    setStages(data);
    getUniqueTabData(data);
  };
  useEffect(() => {
    if (Stages && selectedTeam) {
      setSelectedItem([]);
      let data2 = Stages.filter(stage => stage.CrmTeamId == selectedTeam.value);
      let newData = data2.map((row, index) => ({
        id: `tab${index + 1}`,
        label: `${row.crmStageName}`,
        value: `${index + 1}`,
        content: `Content ${row.crmStageName}`,
        SortOrder: row.SortOrder,
        StageId: row.StageId,
        CrmTeamId: row.CrmTeamId,
      }));
      newData.sort(function(a, b) {
        return a.SortOrder - b.SortOrder;
      });

      setTabs(newData);
      setTeamWiseTabs(newData);
      let lastactiveTab = JSON.parse(localStorage.getItem('crmCurrStage'));
      let currDataLen = newData.length;
      if (lastactiveTab && currDataLen > 0) {
        if (currDataLen >= parseInt(lastactiveTab)) {
          setValue(lastactiveTab);
        } else {
          setValue(currDataLen.toString());
          localStorage.setItem('crmCurrStage', currDataLen.toString());
        }
      }
    }
  }, [selectedTeam, Stages]);
  // console.log(tabs, 'tabs');
  useEffect(() => {
    // if(selectedTeam.length> 0)
    setSelectedItem([]);
  }, [value]);

  /** Get All Client **/
  const [crmDataLoading, setCRMDataLoading] = useState(false);
  const getContacts = id => {
    // dispatch({ type: FETCH_START });
    let currTeam = id ? id : selectedTeam.value;
    setCRMDataLoading(true);
    if (currTeam !== undefined) {
      let url = 'CRM/GetContacts/' + currTeam;
      try {
        customAxios
          .get(url, { headers: authHeader() })
          .then(({ data }) => {
            setCRMDataLoading(false);
            if (data.length > 0) {
              let newData = data.map(item => getContactExtraFields(item));
              setData(newData);
              setSearchData(newData);
              dispatch({ type: FETCH_SUCCESS });
              continuePollingRef.current = true;
            } else {
              continuePollingRef.current = false;
            }
          })
          .catch(error => {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
            setCRMDataLoading(false);
          });
      } catch (e) {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        setCRMDataLoading(false);
      }
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      if (continuePollingRef.current) {
        getContacts();
      }
    }, 20000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [selectedTeam]);

  // console.log(test, 'newtest');
  // const continuePollingRef = useRef(true);

  // const getContacts = id => {
  //   const fetchData = () => {
  //     let currTeam = id ? id : selectedTeam.value;
  //     if (currTeam !== undefined) {
  //       let url = 'CRM/GetContacts/' + currTeam;
  //       try {
  //         customAxios
  //           .get(url, { headers: authHeader() })
  //           .then(({ data }) => {
  //             if (data.length > 0) {
  //               let newData = data.map(item => getContactExtraFields(item));
  //               setData(newData);
  //               setSearchData(newData);
  //               dispatch({ type: FETCH_SUCCESS });
  //             } else {
  //               dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //             }
  //           })
  //           .catch(error => {
  //             dispatch({ type: FETCH_ERROR, payload: 'Network Error', error });
  //             // Handle error if needed
  //           });
  //       } catch (e) {
  //         dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
  //         // Handle error if needed
  //       }
  //     }
  //   };

  //   fetchData();

  //   const pollingInterval = setInterval(() => {
  //     // console.log(continuePollingRef.current, 'inside block');
  //     if (continuePollingRef.current) {
  //       fetchData();
  //     } else {
  //       clearInterval(pollingInterval);
  //     }
  //   }, 5000);

  //   const stopPolling = () => {
  //     continuePollingRef.current = false;
  //   };

  //   return stopPolling;
  // };

  // // console.log(continuePollingRef.current, 'outside block');

  // useEffect(() => {
  //   const stopPolling = getContacts();

  //   return () => {
  //     // Cleanup function to stop polling when the component is unmounted
  //     stopPolling();
  //   };
  // }, []);

  // Example usage:
  // const stopPolling = getContacts();

  // To stop the polling (e.g., when component unmounts)
  // stopPolling();

  const getContactExtraFields = item => {
    let followUpDate = item['NextFollowUpDate'] ? item['NextFollowUpDate'] : '';
    let formattedFollowUpDate = followUpDate ? new Date(followUpDate).toLocaleString('en-GB').substring(0, 10) : '';

    let tempDateArr = [item['LastCallMade'], item['LastEmailSent'], item['LastNoteMade']]
      .filter(obj => {
        if (obj) return new Date(obj);
      })
      .sort(function(a, b) {
        return a - b;
      });
    let lastContactDate = tempDateArr.length > 0 ? new Date(tempDateArr[0]).toLocaleString('en-GB').substring(0, 10) : '';
    let fullName = `${item['crmContactFirst']} ${item['crmContactLast']}`;

    let newItem = {
      ...item,
      FollowUpDate: followUpDate,
      LastContactDate: lastContactDate,
      FullName: fullName,
      localFollowUpDate: formattedFollowUpDate,
    };

    return newItem;
  };

  const getSingleContact = contactID => {
    try {
      dispatch({ type: FETCH_START });
      customAxios
        .get(`CRM/GetContacts/${selectedTeam.value}?crmId=${contactID}`, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            let newItem = getContactExtraFields(data[0]);

            // updateSingleContact(newItem);
            setSearchData(prevState => {
              return updateSingleContact(newItem, prevState);
            });
            setData(prevState => {
              return updateSingleContact(newItem, prevState);
            });

            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  // get Single Contact
  const updateSingleContact = (newItem, prevState) => {
    let newState = prevState;
    let recordIndex = newState.findIndex(con => con.crmId == newItem.crmId);
    newState[recordIndex] = { ...newItem };
    let currRecordData = [...newState];
    return currRecordData;
  };

  //custom dialog box setting
  const OpenMailDialog = rowData => {
    if (rowData) {
      setMailData(rowData);
    }
    setHideMailDialog(false);
    continuePollingRef.current = false;
  };
  const CloseMailDialog = () => {
    setHideMailDialog(true);
    continuePollingRef.current = true;
    if (recordSelected) {
      setRecordSelected(false);
    }
  };

  function getContactHandler(contactId) {
    if (contactId) {
      getSingleContact(contactId);
    } else getContacts();
  }

  function handelMenuClick(type, args) {
    if (type === 'delete') {
      let obj = {
        ContactId: args.crmId,
        crmTeamId: args.CrmTeamId,
      };
      deleteContact(obj);
    } else if (type === 'edit') {
    } else if (type === 'sendEmail') {
      setMailData(args);

      OpenMailDialog();
    } else if (type === 'move') {
      let obj = {
        itemObj: args,
        label: 'Move',
        isCopy: false,
        open: true,
      };
      setcopyMoveContact(obj);
      console.log('move');
    } else if (type === 'copy') {
      console.log('copy');
      let obj = {
        itemObj: args,
        label: 'Copy',
        isCopy: true,
        open: true,
      };
      setcopyMoveContact(obj);
    }
  }

  /* modal open/close */
  const toggle = () => {
    setModal(!modal);
    getContacts();
    getStages();
  };

  /* START: Search bar */
  const searchCRMData = event => {
    let searchkey = event.target.value;
    setSearchKey(searchkey);
    // return data.filter(data => option[label].toLowerCase().indexOf(query.toLowerCase()) > -1);
    let filteredRows = [];
    filteredRows = data.filter(row => {
      return (
        row.crmStageName
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.crmContactFirst
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.crmContactMiddle
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.crmContactLast
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.crmContactEmail
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase()) ||
        row.crmCompany
          .toString()
          .toLowerCase()
          .includes(searchkey.toString().toLowerCase())
      );
    });

    if (searchkey.length < 1) {
      setSearchData(data);
    } else {
      setSearchData(filteredRows);
    }
  };
  const clearSearchData = () => {
    inputRef.current.focus();
    setSearchKey('');
    setSearchData(data);
  };
  /* END: Search bar */

  /* START: Teams selection */
  const onTeamChangeHandler = args => {
    setSelectedTeam(args);
    localStorage.setItem('crmTeam', JSON.stringify(args));
    localStorage.setItem('crmCurrStage', JSON.stringify('1'));
    const teamWise = tabs.filter(obj => obj.CrmTeamId === args.value);
    /* Change Team data based on top left Team dropdown selected and change updated drag and drop order */

    const teamW = teamWise.map((obj, index) => {
      var temp = Object.assign({}, obj);
      if (temp.CrmTeamId == args.value) {
        temp.value = `${index + 1}`;
        return temp;
      }
    });

    setTeamWiseTabs(teamW);
    if (args.value) {
      getTeamMembers(args);
    }
    getContacts(args?.value);
  };
  const getTeams = () => {
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .get('CRMTeams/GetCRMTeams', { headers: authHeader() })
        .then(({ data }) => {
          if (data.length > 0) {
            var team = data.map(c => ({ value: c.Id, label: c.crmTeamName, IsAdmin: c.IsAdmin }));
            if (team) {
              setTeams(team);
              let lastTeam = JSON.parse(localStorage.getItem('crmTeam'));
              let currTeam = team[0];
              if (lastTeam) {
                let LastTeam = team.filter(item => item.value === lastTeam.value);
                currTeam = LastTeam ? LastTeam[0] : team[0];
              }

              getStages(currTeam.value);

              setTimeout(() => {
                setSelectedTeam(currTeam);
                getTeamMembers(currTeam);

                getContacts(currTeam.value);
              }, 0);
            }
            dispatch({ type: FETCH_SUCCESS });
          } else {
            dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getTeamMembers = args => {
    customAxios.get('CRMTeams/GetCRMTeamMembers/' + args.value, { headers: authHeader() }).then(({ data }) => {
      if (data) {
        const members = data.filter(obj => obj.TeamId === args.value);
        // setAllTeamMembers(data);
        setSelectedTeamMembers(members);
        let tMemebers = data.map(item => ({ value: item.crmUserId, label: item.Email, ...item }));
        setTeamMembers(tMemebers);
      }
    });
  };

  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${name.charAt(0)}${name.charAt(1)}`,
    };
  }
  /* END: Teams selection */
  const getCurrentTabInfo = () => {
    let tabValue = teamWiseTabs.filter(t => t.value === value);
    return tabValue.length == 0 ? {} : tabValue[0];
  };
  /* START: Stages Add/Delete Menu*/
  const ITEM_HEIGHT = 48;
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleActionMenuClick = event => {
    setAnchorEl(event.currentTarget);
    if (selectedItem.length > 0) {
      setRecordSelected(true);
    } else {
      setRecordSelected(false);
    }
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };
  /* END: Stages Add/Delete Menu*/

  const customizeRow = args => {
    let currDate = new Date();
    let followUpDate = new Date(args.row.FollowUpDate);

    if (currDate - followUpDate > 0 && args.row.NextFollowUpDate) {
      return 'bgRow';
    }
    return '';
  };

  /* Start: Stages Updated from modal */
  const stagesUpdateHandler = () => {
    getTeams();
  };
  /* End: Stages Updated from modal */

  /* Start : Bulk Select */
  const [recordSelected, setRecordSelected] = useState(false);
  const [selectedItem, setSelectedItem] = useState([]);

  const selectMultipleRecord = parms => {
    const selectedIDs = new Set(parms);
    const selectedRows = searchData.filter(row => selectedIDs.has(row.crmId));
    setSelectedItem(selectedRows);
  };

  const selectAction = actionName => {
    if (actionName == 'delete') {
      setOpenConfirmationDialog(true);
    } else if (actionName == 'email') {
      // setMailData(args.rowData);
      OpenMailDialog();
    }
    setAnchorEl(null);
  };

  /* End : Bulk Select  */

  /* START: Genral Confirmation Dialog */
  const [openConfiramtionDialog, setOpenConfirmationDialog] = useState(false);
  const confirMultipleDelete = () => {
    deleteMultipleContact(selectedItem);
    setOpenConfirmationDialog(false);
    setSelectedItem([]);
  };

  /* END: Genral Confirmation Dialog */

  /* Grid Configaration start */

  let todaysDate = new Date();
  const [datePickerData, setDatePickerData] = useState(null);
  const openDatePickerView = args => {
    setDatePickerData(args);
  };

  const Tablecolumns = [
    {
      field: 'crmCompany',
      headerName: 'Company Name',
      flex: 1,
      disableClickEventBubbling: false,
    },
    {
      field: 'FullName',
      headerName: 'Name',
      flex: 1,
      disableClickEventBubbling: true,
      renderCell: values => {
        return values?.id === hoveredRow?.crmId ? (
          <>
            {' '}
            {values.row.crmContactFirst} {values.row.crmContactLast}
            <QuickActionMenu
              OpenMailDialog={OpenMailDialog}
              data={values.row}
              stagesData={Stages}
              currTeam={selectedTeam.value}
            />
          </>
        ) : (
          <>
            {values.row.crmContactFirst} {values.row.crmContactLast}
          </>
        );
      },
    },
    {
      field: 'crmSalesRep',
      headerName: 'SalesRep',
      flex: 1,

      disableClickEventBubbling: true,
      renderCell: values => {
        return (
          <SalesRepView
            data={values.row}
            tmembers={TeamMembers}
            getContactHandler={getContactHandler}
            sendEmailNotification={sendEmailNotification}
          />
        );
      },
    },
    { field: 'crmContactPhone', headerName: 'Phone Number', flex: 1 },
    {
      field: 'FollowUpDate',
      headerName: 'FollowUpDate',
      width: 175,
      disableClickEventBubbling: true,

      renderCell: values => {
        if (todaysDate - new Date(values.row.FollowUpDate) > 0) {
          return (
            <div>
              {' '}
              <FollowUpDateView info={values.row} clickevent={openDatePickerView} />{' '}
            </div>
          );
        } else {
          return <div>{values.row.localFollowUpDate}</div>;
        }
      },
    },
    { field: 'LastContactDate', headerName: 'LastContactDate', width: 175 },
    {
      field: 'Action',
      headerName: 'Action',
      disableColumnMenu: true,
      sortable: false,
      disableClickEventBubbling: true,
      renderCell: values => {
        return (
          <Button
            style={{ border: 'none' }}
            onClick={event => {
              event.persist();
              // console.log(values)
              event.ignore = true;
              let rowData = { target: event.target, rowData: values.row };
              setActionMenuData(rowData);
            }}>
            <MoreVertIcon />
          </Button>
        );
      },
    },
  ];
  /* Grid Configaration end */

  const openSidebar = (event, parms) => {
    let sidebarData = {
      rowData: parms.row,
    };
    setCreditSafeCompanydatas({});
    openSidebarInfoPanel(sidebarData);
  };

  const [openRecordToastMessage, setOpenRecordToastMessage] = React.useState(false);
  const [uploadedRecordsCount, setUploadedRecordsCount] = React.useState({});
  const [notProcessedRows, setNotProcessedRows] = useState([]);
  const [openNotProcessedDialog, setOpenNotProcessedDialog] = useState(false);

  const exportNotProcessedRows = () => {
    // for json file

    // const jsonData = JSON.stringify(notProcessedData, null, 2);
    // const blob = new Blob([jsonData], { type: 'application/json' });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'not_processed_rows.json';
    // a.click();
    // URL.revokeObjectURL(url);

    // for text file
    // const textData = notProcessedData.map(row => Object.values(row).join(', ')).join('\n');
    // const blob = new Blob([textData], { type: 'text/plain' });
    // const url = URL.createObjectURL(blob);
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'not_processed_rows.txt';
    // a.click();
    // URL.revokeObjectURL(url);

    // for csv file

    const csvHeader = Object.keys(notProcessedRows[0]).join(',') + '\n';
    const csvData = notProcessedRows.map(row => Object.values(row).join(',')).join('\n');
    const csvContent = csvHeader + csvData;

    // Create a Blob and trigger the download of the CSV file
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = 'not_processed_rows.csv';
    a.click();
    URL.revokeObjectURL(url);
  };

  const handleClickOpenNotProcessedDialog = () => {
    setOpenNotProcessedDialog(true);
  };

  const handleRecordToastMessage = () => {
    setOpenRecordToastMessage(true);
  };

  function closeConfirmationModal() {
    setConfirmationData(defaultConfirmationModalObj);
  }

  function openteamLeaveModal() {
    let confirmationObj = { ...confirmationData };
    confirmationObj.handleClose = closeConfirmationModal;
    confirmationObj.modalTitle = 'Leave Team';
    if (selectedTeam.IsAdmin) {
      confirmationObj.hideCloseBtn = true;

      confirmationObj.modalMainText = ' You can not leave this team, as you are an admin';
      confirmationObj.saveBtnText = 'Ok';
      confirmationObj.handleSave = closeConfirmationModal;
    } else {
      confirmationObj.hideCloseBtn = false;
      confirmationObj.modalMainText = ' Do you want to leave this team?';
      confirmationObj.saveBtnText = 'Yes';
      confirmationObj.cancelBtnTxt = 'No';
      confirmationObj.handleSave = leaveTeam;
    }

    confirmationObj.isModalOpen = true;
    setConfirmationData(confirmationObj);
  }

  function leaveTeam() {
    let obj = {
      CRMTeamId: selectedTeam.value,
      CRMMemberUserId: userDetails.UserId,
    };
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .post('CRMTeams/DeleteTeamMember', obj, { headers: authHeader() })
        .then(({ data }) => {
          dispatch({ type: FETCH_SUCCESS });

          closeConfirmationModal();
          localStorage.removeItem('crmTeam');
          getTeams();
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  const handlePopperOpen = event => {
    // const id = event.currentTarget.dataset.id;
    // const row = data.rows.find((r) => r.id === id);
    // setValue(row);
    // setAnchorEl(event.currentTarget);
    const id = event.currentTarget.dataset.id;
    const row = searchData.find(row => row.crmId === +id);
    setHoveredRow(row);
  };

  const handlePopperClose = () => {
    // if (
    //   anchorEl == null ||
    //   popperRef.current.contains(event.nativeEvent.relatedTarget)
    // ) {
    //   return;
    // }

    // setAnchorEl(null);
    setHoveredRow(null);
  };

  return (
    <div className="app-wrapper contact-overview">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.contactsOverview" />} /> */}
      <div
        className="justify-content-right pb-3 text-right content-horizontal__center"
        style={{ justifyContent: 'space-between', marginTop: 15 }}>
        <div className="content-horizontal__center">
          <h4>Contact Overview </h4>
          <FormControl style={{ marginLeft: 15, minWidth: 250, textAlign: 'left' }} size="small">
            <Select
              style={{ textAlign: 'left' }}
              placeholder={'Select Team..'}
              options={Teams}
              value={Teams.find(c => c.label === selectedTeam.label)}
              onChange={onTeamChangeHandler}
            />
          </FormControl>
          {selectedTeam && (
            <Button
              onClick={openteamLeaveModal}
              style={{
                marginLeft: '10px',
                backgroundColor: '#3f51b5',
                color: 'white',
                textTransform: 'capitalize',
              }}
              variant="contained">
              Leave Team
            </Button>
          )}
          {/* {SelectedTeamMembers && (
            <AvatarGroup max={6} className="ml-3">
              {SelectedTeamMembers.map((avatar, index) => (
                <Avatar key={index} {...stringAvatar(avatar.Email)} />
              ))}
            </AvatarGroup>
          )} */}
        </div>
        <div className="content-horizontal__center">
          <Tooltip
            title={
              <div>
                <Typography>Advance Filters</Typography>
              </div>
            }
            style={{ background: 'white' }}
            arrow={true}>
            <Button
              variant="contained"
              className="px-2"
              onClick={filterModalToggle}
              style={{
                height: 40,
                minWidth: '25px',
                background: filterApplied ? '#3f51b5' : 'none',
                color: filterApplied ? 'white' : '#3f51b5',
              }}>
              <FilterAltIcon />
            </Button>
          </Tooltip>

          <Search>
            <SearchIconWrapper>
              <SearchIcon />
            </SearchIconWrapper>
            <StyledInputBase
              placeholder="Search…"
              inputProps={{ 'aria-label': 'search' }}
              onChange={e => searchCRMData(e)}
              value={searchKey}
              inputRef={inputRef}
            />
            {searchKey && (
              <CloseIcon
                onClick={clearSearchData}
                style={{ position: 'relative', left: '-38px', fontSize: '18px', cursor: 'pointer' }}
              />
            )}
          </Search>
          <TeamDataListView
            SelectedTeamMembers={SelectedTeamMembers}
            setSearchData={setSearchData}
            searchData={searchData}
            originalData={data}
          />
          <AddMenu AddContact={openAddContact} AddMultipleContact={toggle} teamInfo={teamWiseTabs} />
        </div>
      </div>
      <Box className={tabClasses.root}>
        <Card className={tabClasses.tabsRow} style={{ width: '100%' }}>
          <React.Fragment>
            <CardContent>
              {Teams.length > 0 ? (
                <TabContext value={value}>
                  <Stack>{_renderTabListWrappedInDroppable()}</Stack>
                  {selectedItem.length > 0 || selectedTeam.IsAdmin ? (
                    <div className={tabClasses.tabsRowPlus}>
                      <IconButton onClick={e => handleActionMenuClick(e)}>
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={handleMenuClose}
                        // disableScrollLock={true}
                        PaperProps={{
                          style: {
                            maxHeight: ITEM_HEIGHT * 4.5,
                            width: '20ch',
                            fontFamily: 'Normal',
                          },
                        }}>
                        {selectedTeam.IsAdmin ? (
                          <MenuItem onClick={stageToggle}>
                            <EditIcon className={classes.actionIcon} /> Add/Edit Stage
                          </MenuItem>
                        ) : null}
                        {recordSelected ? (
                          <div>
                            <MenuItem
                              onClick={() => {
                                selectAction('delete');
                              }}>
                              <DeleteIcon className={classes.actionIcon} /> Delete Selected
                            </MenuItem>

                            <MenuItem onClick={() => selectAction('email')}>
                              <MailIcon className={classes.actionIcon} /> Email
                            </MenuItem>
                          </div>
                        ) : null}
                      </Menu>
                    </div>
                  ) : null}
                  {teamWiseTabs.length > 0 &&
                    teamWiseTabs.map((tab, index) => {
                      const filteredData = getFilterTabData(searchData, tab.label); // Call the function once
                      const isLoading = filteredData.length === 0;
                      const hasData = searchData.length !== 0;

                      return (
                        <TabPanel value={tab.value} key={index} className="gridComp" style={{ width: '100%' }}>
                          <div style={{ width: '100%' }}>
                            {isLoading ? (
                              // Show loading indicator when no data is available
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  justifyContent: 'center',
                                  height: '500px', // Same height as the DataGrid
                                }}>
                                <div>
                                  {' '}
                                  {crmDataLoading ? 'Loading...' : <>{hasData ? 'No Data Available' : 'Loading...'}</>}
                                </div>
                              </div>
                            ) : (
                              <DataGrid
                                rows={filteredData}
                                getRowId={row => row.crmId}
                                columns={Tablecolumns}
                                disableSelectionOnClick
                                rowHeight={60}
                                pageSize={10}
                                checkboxSelection
                                onRowClick={(parms, event) => {
                                  if (!event.ignore) {
                                    openSidebar(event, parms);
                                  }
                                }}
                                componentsProps={{
                                  row: {
                                    onMouseEnter: event => handlePopperOpen(event),
                                    onMouseLeave: handlePopperClose,
                                  },
                                }}
                                autoHeight={true}
                                style={{
                                  minHeight: '500px',
                                  cursor: 'pointer',
                                  width: '100%',
                                  minHeight: '66vh',
                                  fontFamily: 'Normal',
                                }}
                                onSelectionModelChange={selectMultipleRecord}
                                getRowClassName={args => customizeRow(args)}
                              />
                            )}
                          </div>
                        </TabPanel>
                      );
                    })}
                </TabContext>
              ) : (
                <div className={tabClasses.noTabs}>
                  You are not part of any team, Start your own CRM team to create contacts. Go to "Manage Sales Team" page.
                  Or create your team <NavLink to="/crm/teams">"Here"</NavLink>{' '}
                </div>
              )}
            </CardContent>
          </React.Fragment>
        </Card>
      </Box>

      {!hideMailDialog ? (
        <MailForm
          rowData={mailData}
          getContactHandler={getContactHandler}
          closeMail={CloseMailDialog}
          onMailSentSuccess={toastCreated}
          getEmailCommunication={getEmailCommunication}
          bulkMail={recordSelected}
          setBulkMail={setRecordSelected}
          bulkData={selectedItem}
        />
      ) : null}

      {modal && (
        <ImportExcel
          modal={modal}
          toggle={toggle}
          getContactsHandler={getContacts}
          Teams={Teams}
          Stages={Stages}
          currentTeam={selectedTeam}
          currTabInfo={getCurrentTabInfo()}
          handleRecordToastMessage={handleRecordToastMessage}
          setUploadedRecordsCount={setUploadedRecordsCount}
          setNotProcessedRows={setNotProcessedRows}
          handleClickOpenNotProcessedDialog={handleClickOpenNotProcessedDialog}
        />
      )}
      <NotProcessedDialog
        open={openNotProcessedDialog}
        setOpen={setOpenNotProcessedDialog}
        handleClickOpen={handleClickOpenNotProcessedDialog}
        exportNotProcessedRows={exportNotProcessedRows}
      />
      {/* Sidebar Component start */}
      {!hideDialog ? (
        <Sidebar
          infoSidebar={infoSidebar}
          infoSidebarData={infoSidebarData}
          notesData={notesData}
          setNotesData={setNotesData}
          emailCommunicationData={emailCommunicationData}
          credisafeCompanyData={credisafeCompanyDatas}
          setMailData={setMailData}
          OpenMailDialog={OpenMailDialog}
          getContactHandler={getContactHandler}
          Teams={Teams}
          selectedTeam={selectedTeam}
          SelectedTeamMembers={SelectedTeamMembers}
          sendEmailNotification={sendEmailNotification}
          handleCheckCredits={handleCheckCredits}
          setCreditSafeCompanydata={setCreditSafeCompanydatas}
          creditSafeCompanyNameData={credisafeCompanyNameData}
          continuePollingRef={continuePollingRef}
        />
      ) : null}
      {/* Sidebar Component end */}

      {/* <Toast/> */}

      <Tostify showMessage={showAlert} setShowMessage={setShowAlert} />

      <RecordToastMessage
        openRecordToastMessage={openRecordToastMessage}
        setOpenRecordToastMessage={setOpenRecordToastMessage}
        handleRecordToastMessage={handleRecordToastMessage}
        uploadedRecordsCount={uploadedRecordsCount}
      />

      {/* stage name modal */}
      {stageModal && (
        <DialogStageTemplate
          modal={stageModal}
          toggle={stageToggle}
          Teams={Teams}
          Stages={Stages}
          setCrmStages={updateCrmStages}
          selectedTeam={selectedTeam}
          stagesUpdateHandler={stagesUpdateHandler}
          deleteStageHandler={updateCrmStages}
        />
      )}
      <ActionMenu info={actionMenuData} handelMenuClick={handelMenuClick} />
      <AddContactSidebar
        infoSidebar={addContactSidebar}
        currentTeam={selectedTeam}
        currentStage=""
        Teams={Teams}
        Stages={Stages}
        currTabInfo={getCurrentTabInfo()}
        setAddContactToggle={setAddContactToggle}
        addContactToggle={addContactToggel}
        addNewContact={addNewContact}
        SelectedTeamMembers={SelectedTeamMembers}
        teamWiseTabs={teamWiseTabs}
      />
      <Filters
        filterInputData={data}
        setSearchData={setSearchData}
        closeFilterModel={closeFilterModel}
        filterModalOpen={filterModalOpen}
        setNormalFilter={setColumnFilters}
        normalFilter={columnFilters}
        setIsFilterApplied={setFilterApplied}
        isFilterApplied={filterApplied}
      />
      <Dialog open={openConfiramtionDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          {' '}
          <Typography sx={{ fontFamily: 'normal' }}>Confirm the action</Typography>
        </DialogTitle>
        <Box position="absolute" top={0} right={0}>
          <IconButton onClick={() => setOpenConfirmationDialog(false)}>
            <Close />
          </IconButton>
        </Box>
        <DialogContent className="pb-2">
          <Typography sx={{ fontFamily: 'normal' }}>
            {' '}
            Do You really want to delete the{' '}
            {selectedItem.length <= 1 ? `selected contact` : `selected ${selectedItem.length} contacts `} ?{' '}
          </Typography>
          {/* <List>
            {selectedItem.map((item,index)=>{
              return <ListItem key={item.crmId}>{item.FullName}</ListItem>
            })}
          </List> */}
        </DialogContent>
        <DialogActions>
          <Button size="small" color="primary" variant="contained" onClick={() => setOpenConfirmationDialog(false)}>
            Cancel
          </Button>
          <Button size="small" color="primary" variant="contained" onClick={confirMultipleDelete}>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <DatePickerItem data={datePickerData} updateTable={getContactHandler} />
      <CopyMoveContact
        updateCRMContactList={updateCRMContactList}
        handleClose={closeCopyMoveContactModal}
        contactObj={copyMoveContact}
        teams={Teams}
        selectedTeam={selectedTeam}
        stages={Stages}
      />
      {/* <TeamMembersMenu tmembers={TeamMembers} popupData={salesViewData} getContactHandler={getContactHandler} /> */}
      <ConfirmationModal confirmationObj={confirmationData} />
    </div>
  );
};

export default ContactsOverview;
