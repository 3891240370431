import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import styled from 'styled-components';
import customAxios from 'routes/Pages/util/Api';
import authHeader from 'services/auth/jwt/auth-header';
import { useDispatch, useSelector } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';
import { Button } from '@material-ui/core';
import { Add } from '@material-ui/icons';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';

const StyledSelect = styled(Select)`
  .css-bpeome-MuiSvgIcon-root-MuiSelect-icon {
    display: none;
  }

  .MuiSvgIcon-root,
  .MuiSvgIcon-fontSizeMedium,
  .MuiSelect-icon,
  .MuiSelect-iconOutlined {
    display: none;
  }

  /* .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.MuiSelect-select {
    height: 20;
  } */
`;

//class="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiSelect-icon MuiSelect-iconOutlined css-hfutr2-MuiSvgIcon-root-MuiSelect-icon"

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 150,
    },
  },
};

function getStyles(name, personName, theme) {
  return {
    fontWeight: personName === name ? theme.typography.fontWeightMedium : theme.typography.fontWeightRegular,
  };
}

export default function SingleSelectPlaceholder({
  mentionTeamMembers,
  selectedCard,
  tmTeamId,
  tmTaskId,
  addNotification,
  getTaskContributors,
}) {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [personName, setPersonName] = React.useState('');
  const { authUser } = useSelector(({ auth }) => auth);

  const addTaskContributors = contributorId => {
    const bodyDescription = {
      tmTeamId: tmTeamId,
      tmTaskId: tmTaskId,
      contributorId: contributorId,
    };

    dispatch({ type: FETCH_START });

    customAxios
      .post('Task/AddTaskContributors', bodyDescription, { headers: authHeader() })
      .then(({ data }) => {
        getTaskContributors();
        const noteObj = {
          UserIdTo: contributorId,
          ActivityId: selectedCard.id,
          NotifyMessage: 'You are Added as Task Contributor on Task Ref:' + selectedCard.tRefNumber,
          FunctionalityId: selectedCard.StatusId,
          TeamId: selectedCard.TeamId,
          IsClicked: 0,
        };
        const emailObj = {
          type: 'taskContributors',
          cardData: selectedCard,
          doneBy: authUser?.Email,
          message: 'task contributor update',
        };
        addNotification(noteObj, emailObj);
        if (data) {
          dispatch({ type: FETCH_SUCCESS });
        }
      })
      .catch(error => {
        dispatch({ type: FETCH_ERROR, payload: 'Network Error test' });
        return error;
      });
  };

  const handleChange = event => {
    addTaskContributors(event.target.value);
    setPersonName(event.target.value);
  };

  return (
    <div>
      <FormControl sx={{ width: 260, mt: 1 }}>
        <AddIcon style={{ marginTop: 10 }} color="primary" />
        {/* <Button color="primary" startIcon={<Add />} className="tmAddTsk"></Button> */}
        <StyledSelect
          value={
            <h1 className="fs-1 text-primary">
              <AddIcon color="primary" />
            </h1>
          }
          sx={{
            height: 20,
          }}
          onChange={handleChange}
          input={<OutlinedInput />}
          renderValue={selected => {
            if (!selected) {
              return <AddIcon style={{ marginTop: 10 }} color="primary" />;
            }

            return selected;
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}>
          {/* <MenuItem disabled value="">
            <h1 className="fs-1">+</h1>
          </MenuItem> */}
          {mentionTeamMembers?.map(element => (
            <MenuItem
              key={element.id}
              value={element.id}
              sx={{ fontFamily: 'normal', fontSize: 14 }}
              style={getStyles(element.label, personName, theme)}>
              {element.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </FormControl>
    </div>
  );
}

// {mentionTeamMembers?.map(element => (
//     <option key={element.id} value={element.id}>
//       {element.label}
//     </option>
//   ))}
