import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  GET_TM_TEAM_MEMBERS,
  GET_TM_TASK_BY_TEAM,
  GET_TM_STATUS_BY_TEAM,
  SET_CARD_DATA,
  SET_CARD_DATE_FLAG,
  SET_SELECTED_TEAM,
  SET_PROJECT_LIST,
  SET_BOARD_DATA,
  SET_SINGHL_ARCHIVE,
  SET_MULTI_ARCHIVE,
  SET_CHECKED,
} from '../../@jumbo/constants/ActionTypes';

export const fetchSuccess = message => {
  return dispatch => {
    dispatch({
      type: FETCH_SUCCESS,
      payload: message || '',
    });
  };
};
export const fetchError = error => {
  return dispatch => {
    dispatch({
      type: FETCH_ERROR,
      payload: error,
    });
  };
};

export const fetchStart = () => {
  return dispatch => {
    dispatch({
      type: FETCH_START,
    });
  };
};

export const setTmTeamMembers = payload => {
  return dispatch => {
    dispatch({
      type: GET_TM_TEAM_MEMBERS,
      payload: payload,
    });
  };
};

export const setTmTaskByTeam = payload => {
  return dispatch => {
    dispatch({
      type: GET_TM_TASK_BY_TEAM,
      payload: payload,
    });
  };
};
export const setTmStatusByTeam = payload => {
  return dispatch => {
    dispatch({
      type: GET_TM_STATUS_BY_TEAM,
      payload: payload,
    });
  };
};

export const setTmCardData = payload => {
  return dispatch => {
    dispatch({
      type: SET_CARD_DATA,
      payload: payload,
    });
  };
};
export const setTmCardDateFlag = payload => {
  return dispatch => {
    dispatch({
      type: SET_CARD_DATE_FLAG,
      payload: payload,
    });
  };
};
export const setTmSelectedTeam = payload => {
  return dispatch => {
    dispatch({
      type: SET_SELECTED_TEAM,
      payload: payload,
    });
  };
};

export const setProjectList = payload => {
  return dispatch => {
    dispatch({
      type: SET_PROJECT_LIST,
      payload: payload,
    });
  };
};

export const setBordeListData = payload => {
  return dispatch => {
    dispatch({
      type: SET_BOARD_DATA,
      payload: payload,
    });
  };
};

export const setSinglArchive = payload => {
  return dispatch => {
    dispatch({
      type: SET_SINGHL_ARCHIVE,
      payload: payload,
    });
  };
};

export const setMultiArchive = payload => {
  return dispatch => {
    dispatch({
      type: SET_MULTI_ARCHIVE,
      payload: payload,
    });
  };
};

export const setChecked = payload => {
  return dispatch => {
    dispatch({
      type: SET_CHECKED,
      payload: payload,
    });
  };
};
