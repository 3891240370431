/**
 * Version 1.0
 * Vikas W
 * 07.07.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { SidebarComponent } from '@syncfusion/ej2-react-navigations';
import Select from 'react-select';
import { Select as MSelect } from '@mui/material';
/* Material Icons */
import CloseIcon from '@material-ui/icons/Close';

import { IconButton, FormControl } from '@mui/material';
import Typography from '@mui/material/Typography';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import 'bootstrap/dist/css/bootstrap.min.css';
import authHeader from '../../../../services/auth/jwt/auth-header';

/* Tabs Config */
// import Badge from '@mui/material/Badge';
// import TabContext from "@mui/lab/TabContext";
// import TabList from "@mui/lab/TabList";
// import TabPanel from "@mui/lab/TabPanel";
import { Box, TextField, Tab, Tabs } from '@mui/material';
// import { styled, alpha } from "@mui/material/styles";
// import { Stack } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';

import Notes from './Notes';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@mui/material/Button';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormLabel } from '@material-ui/core';
function TabPanelSideBar(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography component="div">{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yPropsSideBar(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const AddContactSidebar = ({
  infoSidebar,
  currentTeam,
  currentStage,
  Teams,
  Stages,
  currTabInfo,
  setAddContactToggle,
  addContactToggle,
  addNewContact,
  SelectedTeamMembers,
  teamWiseTabs,
}) => {
  const handleSubmit = () => {
    addNewContact('/CRM/AddContact', obj);
    infoSidebar.current.toggle();
    setAddContactToggle(false);
  };

  const textFieldStyles = makeStyles(theme => ({
    root: {
      '& .MuiInputBase-input.Mui-disabled': {
        color: 'black',
      },
      '& .MuiTextField-root': {
        // background:'black'
      },

      '& .MuiFormLabel-root.Mui-disabled': {
        color: 'rgba(0,0,0,0.6)',
      },
      '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
        '-webkit-text-fill-color': 'rgba(0,0,0,0.75)',
      },
    },
  }));

  const selectFieldStyles = makeStyles(theme => ({
    root: {
      '& .css-hdw1oc': {
        width: '40%',
      },
      '& .MuiSelect-select.MuiInputBase-input.MuiOutlinedInput-input': {
        padding: '7.5px 14px',
      },
    },
  }));

  const [countries, setCountries] = useState([]);

  const [stages, setStages] = useState([]);
  const [stageName, setStageName] = useState([]);
  const [teamMembers, setTeamMembers] = useState([]);

  const data = {
    crmStageName: 'new',
    crmStageId: 0,
    crmContactTitle: '',
    crmContactFirst: '',
    crmContactMiddle: '',
    crmContactLast: '',
    crmLeadReferralSource: '',
    crmDateOfInitialContact: '',
    crmTitle: '',
    crmCompany: '',
    crmIndustry: '',
    crmAddressLine1: '',
    crmAddressLine2: '',
    crmAddressCity: '',
    crmAddressTown: '',
    crmAddressZip: '',
    crmAddressCountry: '',
    crmContactPhone: '',
    crmContactEmail: '',
    crmContactStatus: '',
    crmContactWebsite: '',
    crmLinkedInProfile: '',
    crmBackgroundInfo: '',
    crmSalesRep: 1,
    crmRating: '',
    crmProjectType: '',
    crmProjectDescription: '',
    crmProposalDueDate: '',
    crmBudget: 0,
    crmDeliverable: 0,
    crmDoNotContact: 0,
    crmDoNotEmail: 0,
    CrmTeamId: 0,
  };
  const [obj, setObject] = useState(
    Object.assign(
      {},

      { ...data, crmStageName: currTabInfo ? currTabInfo.label : 'new' },
    ),
  );
  function onChange(args) {
    let newState = { ...obj };
    newState[args.target.name] = args.target.value;
    setObject(newState);
  }
  const getStages = () => {
    let url = 'CRM/Get_CRM_Stages/' + currentTeam.value;
    dispatch({ type: FETCH_START });
    try {
      // customAxios.get('Client/GetClients').then(({ data }) => {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          if (data) {
            var stage = data.map(c => ({ value: c.StageId, label: c.crmStageName, CrmTeamId: c.CrmTeamId }));
            setStages(stage);
            dispatch({ type: FETCH_SUCCESS });
          } else {
            // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
          }
        })
        .catch(error => {});
    } catch (e) {
      // dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };

  const getStageName = () => {
    let newStageName = teamWiseTabs.map(c => ({ value: c.StageId, label: c.label, CrmTeamId: c.CrmTeamId }));
    setStageName(newStageName);
  };
  const dispatch = useDispatch();

  const getTeamMembers = () => {
    let currTeamMembers = SelectedTeamMembers.map(c => ({ value: c.crmUserId, label: c.Email, CrmUserId: c.crmUserId }));
    setTeamMembers(currTeamMembers);
  };

  useEffect(() => {
    if (addContactToggle) {
      let newState = { ...obj };
      newState['crmStageName'] = currTabInfo?.label;
      newState['crmStageId'] = currTabInfo?.StageId;
      newState['CrmTeamId'] = currentTeam?.value;
      setObject(newState);
      getStages();
      getTeamMembers();
      getContries();
      getStageName();
    }
  }, [addContactToggle]);

  const getContries = () => {
    dispatch({ type: FETCH_START });
    customAxios
      .get('/Bank/GetCountries', { headers: authHeader() })
      .then(({ data }) => {
        // console.log('data:', data);
        if (data) {
          var Country = data.map(c => ({
            value: c.ID,
            label: c.CountryName,
          }));

          setCountries(Country);
          dispatch({ type: FETCH_SUCCESS });
        } else {
          console.log('payload: data.error', data.error);
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        }
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
        console.log('Error****:', error.message);
      });
  };
  const onTeamChange = args => {
    let newState = { ...obj };
    newState['CrmTeamId'] = args.value;
    setObject(newState);
    const newArr = stages.filter(instance => instance.CrmTeamId === args.value);
    var users = newArr.map(c => ({ value: c.value, label: c.label, CrmTeamId: c.CrmTeamId }));
    setStageName(users);
  };
  const onStageChange = args => {
    let newState = { ...obj };
    newState['crmStageName'] = args.label;
    newState['crmStageId'] = args.value;
    setObject(newState);
  };
  const onCountryChange = args => {
    let newState = { ...obj };

    newState['crmAddressCountry'] = args.label;
    setObject(newState);
  };

  const onSalesRepChange = args => {
    let newState = { ...obj };
    newState['crmSalesRep'] = args.value;
    setObject(newState);
  };

  return (
    <div className="app-wrapper">
      <SidebarComponent
        id="default1"
        width="700px"
        position="Right"
        ref={infoSidebar}
        z-zIndex="1000"
        className="info_sidebar"
        created={() => infoSidebar.current.hide()}
        style={{ fontFamily: 'Normal', visibility: 'hidden' }}
        close={() => {
          setAddContactToggle(false);
          setObject(data);
        }}>
        <div>
          <IconButton
            aria-label="Close"
            onClick={() => {
              infoSidebar.current.toggle();
              setAddContactToggle(false);
              setObject(data);
            }}
            style={{ padding: '0.25em' }}>
            <CloseIcon />
          </IconButton>
        </div>
        <div className="list-group" style={{ fontSize: '1rem', color: 'black !important' }}>
          <Box sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={0} aria-label="basic tabs example">
                <Tab label="New Contact" {...a11yPropsSideBar(0)} wrapped />
              </Tabs>
            </Box>

            <TabPanelSideBar index={0} value={0}>
              <div>
                <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
                  <CardContent component="div">
                    <div
                      style={{
                        fontSize: '16px',
                        fontFamily: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 1.235,
                        padding: '10px 0 20px 10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                      }}>
                      Add Contact
                    </div>
                    <div
                      style={{
                        fontSize: '16px',
                        fontFamily: 'normal',
                        fontWeight: 'bold',
                        lineHeight: 1.235,
                        padding: '10px 0 20px 10px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        gap: '30px',
                      }}>
                      <FormControl style={{ zIndex: 9999, marginRight: '5px', minWidth: '175px' }} size="small">
                        <p style={{ fontSize: 12 }}>Team Name</p>
                        <Select
                          style={{ zIndex: 9999 }}
                          placeholder={'Select Team..'}
                          options={Teams}
                          value={Teams.find(c => c.value == obj.CrmTeamId)}
                          onChange={onTeamChange}
                        />
                      </FormControl>
                      <FormControl style={{ zIndex: 9999, marginRight: '5px', minWidth: '175px' }} size="small">
                        <p style={{ fontSize: 12, marginBottom: 5 }}>Stage Name</p>
                        <Select
                          style={{ zIndex: 9999, marginRight: '5px' }}
                          placeholder={'Select Stage..'}
                          options={stageName}
                          value={stageName.find(c => c.label == obj.crmStageName)}
                          onChange={onStageChange}
                        />
                      </FormControl>
                      <FormControl style={{ zIndex: 9999, marginRight: '5px', minWidth: '175px' }} size="small">
                        <p style={{ fontSize: 12, marginBottom: 5 }}>SalesRep</p>
                        <Select
                          style={{ zIndex: 9999, marginRight: '5px' }}
                          placeholder={'Select'}
                          options={teamMembers}
                          value={teamMembers.find(c => c.value == obj.crmSalesRep)}
                          onChange={onSalesRepChange}
                        />
                      </FormControl>
                    </div>
                    <Box
                      sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off">
                      <form
                        onSubmit={e => {
                          e.preventDefault();
                          handleSubmit();
                        }}>
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="First Name"
                              id="crmContactFirst"
                              name="crmContactFirst"
                              type="text"
                              value={obj.crmContactFirst}
                              onChange={onChange}
                              required={true}
                              // defaultValue={obj.crmContactFirst}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Middle Name"
                              id="crmContactMiddle"
                              name="crmContactMiddle"
                              type="text"
                              value={obj.crmContactMiddle}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmContactMiddle}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Last Name"
                              id="crmContactLast"
                              name="crmContactLast"
                              type="text"
                              value={obj.crmContactLast}
                              onChange={onChange}
                              required={true}
                              // defaultValue={`${infoSidebarData.crmContactLast}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="Title"
                              id="crmContactTitle"
                              name="crmContactTitle"
                              type="text"
                              value={obj.crmContactTitle}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmContactTitle}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Company"
                              id="crmCompany"
                              name="crmCompany"
                              type="text"
                              value={obj.crmCompany}
                              onChange={onChange}
                              required={true}
                              // defaultValue={`${infoSidebarData.crmCompany}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Industry"
                              id="crmIndustry"
                              name="crmIndustry"
                              type="text"
                              value={obj.crmIndustry}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmCompany}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="Phone"
                              id="crmContactPhone"
                              name="crmContactPhone"
                              type="text"
                              value={obj.crmContactPhone}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmContactPhone}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Email"
                              id="crmContactEmail"
                              name="crmContactEmail"
                              type="text"
                              value={obj.crmContactEmail}
                              onChange={onChange}
                              required={true}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Linkedin"
                              id="crmLinkedInProfile"
                              name="crmLinkedInProfile"
                              type="text"
                              value={obj.crmLinkedInProfile}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmLinkedInProfile}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="Address Line 1"
                              id="crmAddressLine1"
                              name="crmAddressLine1"
                              type="text"
                              value={obj.crmAddressLine1}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmAddressLine1}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Address Line 2"
                              id="crmAddressLine2"
                              name="crmAddressLine2"
                              type="text"
                              value={obj.crmAddressLine2}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmAddressLine2}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Town"
                              id="crmAddressTown"
                              name="crmAddressTown"
                              type="text"
                              value={obj.crmAddressTown}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmAddressTown}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <TextField
                              label="City"
                              id="crmAddressCity"
                              name="crmAddressCity"
                              type="text"
                              value={obj.crmAddressCity}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmAddressCity}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                          <div className="col-md-4">
                            {/* <TextField
                            label="Country"
                            id="crmAddressCountry"
                            name="crmAddressCountry"
                            type="text"
                            value={obj.crmAddressCountry}
                            onChange={onChange}
                            required = {true}
                           
                            // defaultValue={`${infoSidebarData.crmAddressCountry}`}
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            style={{ width: '95%', fontSize: '14px' }}
                            inputProps={{ style: { fontSize: 12 } }}
                          /> */}
                            <FormControl
                              style={{ width: '95%', fontSize: '14px', left: '8px', top: '8px' }}
                              inputprops={{ style: { fontSize: 12 } }}
                              size="small"
                              inputlabelprops={{ shrink: true }}
                              required={true}>
                              <InputLabel required={true} shrink={true} htmlFor="crmAddressCountry">
                                Country
                              </InputLabel>
                              <MSelect
                                id="crmAddressCountry"
                                variant="outlined"
                                style={{ fontSize: '14px', paddingInline: '5px' }}
                                size="small"
                                name="crmAddressCountry"
                                value={obj.crmAddressCountry}
                                onChange={onChange}
                                placeholder="Country"
                                className={selectFieldStyles().root}>
                                {countries.map((item, index) => {
                                  return (
                                    <MenuItem value={item.label} key={index}>
                                      {item.label}
                                    </MenuItem>
                                  );
                                })}
                              </MSelect>
                            </FormControl>
                          </div>
                          <div className="col-md-4">
                            <TextField
                              label="Zipcode"
                              id="crmAddressZip"
                              name="crmAddressZip"
                              type="text"
                              value={obj.crmAddressZip}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmAddressZip}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '95%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <TextField
                              label="Background Info"
                              id="crmBackgroundInfo"
                              name="crmBackgroundInfo"
                              type="text"
                              value={obj.crmBackgroundInfo}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmBackgroundInfo}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '99%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <TextField
                              label="Website"
                              id="crmContactWebsite"
                              name="crmContactWebsite"
                              type="text"
                              value={obj.crmContactWebsite}
                              onChange={onChange}
                              // defaultValue={`${infoSidebarData.crmBackgroundInfo}`}
                              size="small"
                              InputLabelProps={{ shrink: true }}
                              style={{ width: '99%', fontSize: '14px' }}
                              inputProps={{ style: { fontSize: 13 } }}
                            />
                          </div>
                        </div>
                        <div className="row d-flex justify-content-end">
                          <div className="col-md-3 d-flex justify-content-end ">
                            <Button variant="contained" color="primary" type="submit" size="small" className="ml-2 ">
                              Add
                            </Button>
                          </div>
                        </div>
                      </form>
                    </Box>
                  </CardContent>
                </Card>
              </div>
            </TabPanelSideBar>
          </Box>
        </div>
      </SidebarComponent>
    </div>
  );
};

export default AddContactSidebar;
