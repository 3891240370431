/**
 * Version 1.0
 * Vikas W
 * 13.05.2022
 * -
 * This component is the page where the invoices are shown
 */
import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
// import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch } from 'react-redux';
// import {
//   FETCH_ERROR,
//   FETCH_START,
//   FETCH_SUCCESS,
//   // INIT_URL,
//   // SIGNOUT_USER_SUCCESS,
//   // USER_DATA,
//   // USER_TOKEN_SET,
// } from '../../constants/ActionTypes';
// import customAxios from '../../util/Api';
import { AuhMethods } from '../../../../services/auth';
import { CurrentAuthMethod } from '../../../../@jumbo/constants/AppConstants';
// import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';

import Button from '@material-ui/core/Button';
import { Collapse, IconButton } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';
import CmtImage from '../../../../@coremat/CmtImage';
import ContentLoader from '../../../../@jumbo/components/ContentLoader';
import AuthWrapper from '../../../../@jumbo/components/Common/authComponents/AuthWrapper';
import { useLocation } from 'react-router-dom';
import FormControl from '@mui/material/FormControl';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import InputAdornment from '@mui/material/InputAdornment';
import { FormHelperText } from '@mui/material';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
  },
  titleRoot: {
    marginBottom: 14,
    color: theme.palette.text.primary,
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

// function useQuery() {
//   const { search } = useLocation();

//   return React.useMemo(() => new URLSearchParams(search), [search]);
// }

const ChangePassword = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const [open, setOpen] = React.useState(false);
  // const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  const [oldPassword, setOldPassword] = useState('');
  // const [confirmOldPassword, setConfirmOldPassword] = useState('');
  const [oldPassErr, setOldPassErr] = useState(false);
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passErr, setPassErr] = useState(false);
  const [passConfirmErr, setConfirmPassErr] = useState(false);
  const [showPassword, setShowPassword] = React.useState(false);
  const [newShowPassword, setNewShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);
  const handleClickNewShowPassword = () => setNewShowPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const onSubmit = () => {
    dispatch(AuhMethods[CurrentAuthMethod].onChangePassword({ oldPassword, password, confirmPassword, history }));
  };

  const validateOldPass = val => {
    let regPassword = /^\s+$|^$/gi;
    if (regPassword.test(val)) {
      setOldPassErr(true);
    } else {
      setOldPassErr(false);
      setOldPassword(val);
    }
  };

  const validatePass = val => {
    // let regPassword = /^\s+$|^$/gi; //empty space
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setPassErr(true);
    } else {
      setPassErr(false);
      setPassword(val);
    }
  };
  const validateConfirmPass = val => {
    let regPassword = /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$/; //min 8 charector
    // let regPassword = /^(?=.*\d).{8,}$/; //min 8 charector
    if (!regPassword.test(val) && password !== confirmPassword) {
      setConfirmPassErr(true);
    } else {
      setConfirmPassErr(false);
      setConfirmPassword(val);
    }
  };

  useEffect(() => {}, []);

  return (
    <div className="app-wrapper">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.changePassword" />} /> */}
      <AuthWrapper variant={wrapperVariant}>
        {variant === 'default' ? (
          <div className={classes.authThumb}>
            <CmtImage src={'/images/auth/forgot-img.png'} />
          </div>
        ) : null}
        <div className={classes.authContent}>
          <div className={'mb-7'} style={{ textAlign: 'center' }}>
            <CmtImage src={'/images/focus-logo/focus-whitebg.jpg'} width={'200px'} />
          </div>
          <Typography component="div" variant="h1" className={classes.titleRoot}>
            Reset Password
          </Typography>
          <Collapse in={open}>
            <Alert
              variant="outlined"
              severity="success"
              className={classes.alertRoot}
              action={
                <IconButton
                  aria-label="close"
                  color="inherit"
                  size="small"
                  onClick={() => {
                    setOpen(false);
                  }}>
                  <CloseIcon fontSize="inherit" />
                </IconButton>
              }>
              A mail has been sent on your email address with reset password link.
            </Alert>
          </Collapse>
          <form>
            <div className={'mb-5'}>
              {/* <TextField
                type="password"
                label={<IntlMessages id="appModule.oldPassword" />}
                error={oldPassErr}
                fullWidth
                onChange={event => validateOldPass(event.target.value)}
                defaultValue={oldPassword}
                margin="normal"
                variant="outlined"
                helperText={oldPassErr ? 'Enter Old Password' : ''}
                className={classes.textFieldRoot}
              /> */}
              <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  <IntlMessages id="appModule.oldPassword" />
                </InputLabel>
                <OutlinedInput
                  error={oldPassErr}
                  fullWidth
                  onChange={event => validateOldPass(event.target.value)}
                  defaultValue={oldPassword}
                  margin="normal"
                  variant="outlined"
                  helperText={oldPassErr ? 'Enter Old Password' : ''}
                  className={classes.textFieldRoot}
                  id="outlined-adornment-password"
                  type={showPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={<IntlMessages id="appModule.oldPassword" />}
                />
                <FormHelperText id="component-error-text" className="error-password">
                  {oldPassErr ? 'Enter Old Password' : ''}
                </FormHelperText>
              </FormControl>
            </div>

            <div className={'mb-5'}>
              {/* <TextField
                type="password"
                label={<IntlMessages id="appModule.password" />}
                error={passErr}
                fullWidth
                onChange={event => validatePass(event.target.value)}
                defaultValue={password}
                margin="normal"
                variant="outlined"
                helperText={
                  passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''
                }
                className={classes.textFieldRoot}
              /> */}
              <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  <IntlMessages id="appModule.password" />
                </InputLabel>
                <OutlinedInput
                  error={passErr}
                  fullWidth
                  onChange={event => validatePass(event.target.value)}
                  defaultValue={password}
                  margin="normal"
                  variant="outlined"
                  helperText={passErr ? 'Enter your password' : ''}
                  className={classes.textFieldRoot}
                  id="outlined-adornment-password"
                  type={newShowPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickNewShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {newShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={<IntlMessages id="appModule.password" />}
                />
                <FormHelperText id="component-error-text" className="error-password">
                  {passErr ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors' : ''}
                </FormHelperText>
              </FormControl>
            </div>
            <div className={'mb-5'}>
              {/* <TextField
                type="password"
                label={<IntlMessages id="appModule.confirmPassword" />}
                error={passConfirmErr || password !== confirmPassword}
                fullWidth
                onChange={event => validateConfirmPass(event.target.value)}
                defaultValue={confirmPassword}
                margin="normal"
                variant="outlined"
                helperText={
                  passConfirmErr
                    ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                    : password !== confirmPassword
                    ? 'Password & Confirm Password does not match'
                    : ''
                }
                className={classes.textFieldRoot}
              /> */}
              <FormControl sx={{ mt: 1, width: '100%' }} variant="outlined">
                <InputLabel htmlFor="outlined-adornment-password">
                  <IntlMessages id="appModule.confirmPassword" />
                </InputLabel>
                <OutlinedInput
                  error={passConfirmErr || password !== confirmPassword}
                  fullWidth
                  onChange={event => validateConfirmPass(event.target.value)}
                  defaultValue={confirmPassword}
                  margin="normal"
                  variant="outlined"
                  helperText={
                    passConfirmErr
                      ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                      : password !== confirmPassword
                      ? 'Password & Confirm Password does not match'
                      : ''
                  }
                  className={classes.textFieldRoot}
                  id="outlined-adornment-password"
                  type={newShowPassword ? 'text' : 'password'}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickNewShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end">
                        {newShowPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  }
                  label={<IntlMessages id="appModule.confirmPassword" />}
                />
                <FormHelperText id="component-error-text" className="error-password">
                  {passConfirmErr
                    ? 'Password includes uppercase, lowercase, digit, special charecter, min 8 charectors'
                    : password !== confirmPassword
                    ? 'Password & Confirm Password does not match'
                    : ''}
                </FormHelperText>
              </FormControl>
            </div>

            <div className={'mb-5'}>
              <Button onClick={onSubmit} style={{ textTransform: 'none' }} variant="contained" color="primary">
                <IntlMessages id="appModule.changePassword" />
              </Button>
            </div>
          </form>
          <ContentLoader />
        </div>
      </AuthWrapper>
    </div>
  );
};

export default ChangePassword;
