import React from 'react';
import Avatar from '@mui/material/Avatar';
import AvatarGroup from '@mui/material/AvatarGroup';
import Tooltip from '@mui/material/Tooltip';
import { styled } from '@mui/system';
import CancelIcon from '@mui/icons-material/Cancel';
import Badge from '@mui/material/Badge';

const StyledAvatarGroup = styled(AvatarGroup)({
  display: 'grid',
  marginRight: '15px',
});
const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: 22,
  height: 22,
  border: `2px solid ${theme.palette.background.paper}`,
}));

const TaskContributorsAvatar = ({ taskContributors, mentionTeamMembers, setGetDeleteContributorId }) => {
  function stringAvatar(obj) {
    let children = '';
    if (obj.image) {
      children = (
        <img style={{ width: '100%', height: '100%', objectFit: 'cover' }} src={`data:image/jpeg;base64,${obj.image}`} />
      );
    } else {
      let name = obj.label;
      children = `${name.charAt(0)}${name.charAt(1)}`;
    }
    return {
      children: children,
    };
  }

  return (
    <div className="d-flex">
      {taskContributors.map(contributor => {
        const user = mentionTeamMembers?.find(user => user.id === contributor?.ContributorUserId);

        return (
          <StyledAvatarGroup key={user?.id} max={4}>
            <div>
              <Tooltip title={user.label} arrow>
                {/* <Avatar
                  key={user.TMUserId}
                  {...stringAvatar(user.Email)}
                  sx={{ width: 30, height: 30, fontSize: '0.95rem' }}
                /> */}
                <Badge
                  overlap="circular"
                  anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                  badgeContent={
                    <CancelIcon
                      sx={{ color: '#F44336', cursor: 'pointer' }}
                      onClick={() => setGetDeleteContributorId(user.id)}
                    />
                  }>
                  <Avatar {...stringAvatar(user)} />
                </Badge>
              </Tooltip>
            </div>
            {/* <div className="ml-1" style={{ cursor: 'pointer' }}></div> */}
          </StyledAvatarGroup>
        );
      })}
    </div>
  );
};

export default TaskContributorsAvatar;
