import React from 'react';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { Button } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

export const CustomeDatePicker = ({ open, OpenDate, CloseDate, value, handleDateHandler }) => {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DatePicker
        minDate={moment().subtract(2, 'years')}
        open={open}
        onOpen={OpenDate}
        onClose={CloseDate}
        value={value}
        // onChange={handleDueDateChange}
        onChange={event => handleDateHandler(event)}
        // disableOpenPicker
        inputFormat="dd/MM/yyyy"
        clearable={true}
        renderInput={params => (
          <TextField
            {...params}
            InputLabelProps={{
              shrink: true,
            }}
            onClick={OpenDate}
          />
        )}
        componentsProps={{
          actionBar: { actions: ['clear'] },
        }}
      />
    </LocalizationProvider>
  );
};
