import * as React from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import { Button } from '@material-ui/core';
import axios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
// import { TextareaAutosize } from '@mui/base/TextareaAutosize';
// or
// import { TextareaAutosize } from '@mui/base';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: '40vw',
  bgcolor: 'background.paper',
  minHeight: '200px',
  boxShadow: 24,
  p: 4,
};

const invoiceStyles = {
  padding: '9px 24px',
  display: 'flex',
  flexDirection: 'column',
  boxShadow: 'rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px',
  fontSize: '18px',
  gap: '7px',
  fontFamily: 'normal',
  color: '#616161',
  '& p': {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
  },
  '& p:nth-child(1)': {
    width: '50%',
  },
};

const menuItemStyle = {
  fontFamily: 'normal',
};

export default function SalesModal({ handleCloseActionModal }) {
  const dispatch = useDispatch();

  const [clientsData, setClientsData] = React.useState([]);
  const [selectActivity, setSelectActivity] = React.useState('');
  const [selectClient, setSelectClient] = React.useState('');
  const [selectInvoice, setSelectInvoice] = React.useState('');
  const [invoiceData, setInvoiceData] = React.useState([]);
  const [openNotes, setOpenNotes] = React.useState(false);
  const [invoiceDetails, setInvoiceDetails] = React.useState({});

  const fetchClientsData = () => {
    dispatch({ type: FETCH_START });

    axios
      .get('Client/GetClients', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setClientsData(data);
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });

    dispatch({ type: FETCH_START });

    axios
      .get('Invoice/GetInvoices', { headers: authHeader() })
      .then(({ data }) => {
        if (data) {
          setInvoiceData(data);
        }
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const handleActivityChange = event => {
    setSelectActivity(event.target.value);
    if (event.target.value === 'payment') {
      setOpenNotes(false);
    } else {
      setOpenNotes(true);
    }
  };
  const handleClientChange = event => {
    setSelectClient(event.target.value);
    setSelectInvoice('');
  };
  const handleInvoiceChange = event => {
    setSelectInvoice(event.target.value);
    setInvoiceDetails(invoiceData?.find(invoice => invoice.InvoiceId === event.target.value));
  };

  const dateFormater = date => {
    return new Date(date).toLocaleDateString('en-GB', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    });
  };

  return (
    <Box sx={style}>
      <div className="d-flex mb-3" style={{ gap: '14px' }}>
        <FormControl fullWidth>
          <InputLabel sx={menuItemStyle} id="demo-simple-select-label">
            Activity Type
          </InputLabel>
          <Select
            sx={menuItemStyle}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectActivity !== 'payment' ? '' : selectActivity}
            label="Select Client"
            onChange={handleActivityChange}>
            <MenuItem sx={menuItemStyle} value={'payment'} onClick={fetchClientsData}>
              Payment By Customer
            </MenuItem>
            ;
            <MenuItem sx={menuItemStyle} value={'tax'}>
              Tax
            </MenuItem>
            ;
            <MenuItem sx={menuItemStyle} value={'return'}>
              Return
            </MenuItem>
            ;
            <MenuItem sx={menuItemStyle} value={'miscellaneous'}>
              Miscellaneous
            </MenuItem>
            ;
          </Select>
        </FormControl>
        <FormControl fullWidth>
          <InputLabel sx={menuItemStyle} id="demo-simple-select-label">
            Select Client
          </InputLabel>
          <Select
            sx={menuItemStyle}
            disabled={selectActivity !== 'payment'}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectClient}
            label="Select Client"
            onChange={handleClientChange}>
            {clientsData?.map((client, index) => {
              return (
                <MenuItem sx={menuItemStyle} key={client.Id} value={client.ClientName}>
                  {client.ClientName}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>
      </div>

      {/* {selectActivity !== 'payment' && openNotes && (
        <TextareaAutosize
          aria-label="minimum height"
          minRows={8}
          placeholder="Write Notes Here"
          style={{ width: '100%', padding: 5 }}
        />
      )} */}

      {!openNotes && selectActivity === 'payment' && (
        <FormControl fullWidth>
          <InputLabel sx={menuItemStyle} id="demo-simple-select-label">
            Client Invoice
          </InputLabel>
          <Select
            sx={menuItemStyle}
            disabled={selectClient === ''}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            value={selectInvoice}
            label="Select Client"
            onChange={handleInvoiceChange}>
            {invoiceData
              ?.filter(invoice => invoice.ClientName === selectClient)
              .reverse()
              .map((invoice, index) => {
                return (
                  <MenuItem
                    sx={{ display: 'flex', gap: '20px', fontFamily: 'normal' }}
                    key={invoice.InvoiceId}
                    value={invoice.InvoiceId}>
                    <span className="mr-3">Invoice Number - {invoice.InvoiceNumber}</span>
                    <span className="mr-3">Net Amount - €{invoice.NetAmount}</span>
                    <span>Due Date - {dateFormater(invoice.DueDate)}</span>
                  </MenuItem>
                );
              })}
          </Select>
        </FormControl>
      )}

      {selectActivity === 'payment' && selectInvoice !== '' && (
        <Box sx={{ marginTop: '12px' }}>
          <Typography variant="h5" sx={{ fontFamily: 'normal' }} gutterBottom>
            Inovice Details
          </Typography>
          <Box sx={invoiceStyles}>
            <p>Name : {invoiceDetails.ClientName}</p>
            <p>Email : {invoiceDetails.ClientEmail}</p>
            <p>Bank Name : {invoiceDetails.BankName}</p>
            <p>Company Name : {invoiceDetails.CompanyName}</p>
            <p>Product Description : {invoiceDetails.ProductDescription}</p>
            <p>Issue Date : {dateFormater(invoiceDetails.IssueDate)}</p>
            <p>Due Date : {dateFormater(invoiceDetails.DueDate)}</p>
            <p>Net Amount : {invoiceDetails.NetAmount}</p>
            <p>Gross Amount : {invoiceDetails.GrossAmount}</p>
          </Box>
          <div className="mt-3">
            <Button variant="contained" color="primary">
              Confirm
            </Button>
            <Button onClick={handleCloseActionModal}>Cancel</Button>
          </div>
        </Box>
      )}
    </Box>
  );
}
