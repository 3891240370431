import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';
import authHeader from '../../../../../services/auth/jwt/auth-header';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';

const styleObj = {
  fontFamily: 'normal',
};

const styleObjTotal = {
  fontFamily: 'normal',
  fontWeight: 'bold',
};

const TaskTimeHistory = ({ selectedCard }) => {
  const [taskTimeData, setTaskTimeData] = useState(null);
  const [formattedTime, setFormattedTime] = useState(null);
  const dispatch = useDispatch();
  const getTaskTime = () => {
    let url = `Task/GetTaskTimeRegistrations?tmTaskId=${selectedCard.id}`;
    dispatch({ type: FETCH_START });

    try {
      customAxios
        .get(url, { headers: authHeader() })
        .then(({ data }) => {
          // setTaskTimeData(data);
          let totalHours = 0;
          let totalMinutes = 0;

          // Calculate total hours and minutes
          data.forEach(entry => {
            totalHours += entry.TimeHours;
            totalMinutes += entry.TimeMinutes;
          });

          // Adjust total hours if minutes exceed 60
          totalHours += Math.floor(totalMinutes / 60);
          totalMinutes %= 60;

          // Format total hours and minutes in hh:mm format
          const formattedTotal = `${totalHours > 9 ? totalHours : '0' + totalHours}:${totalMinutes
            .toString()
            .padStart(2, '0')}`;

          setTaskTimeData(data);
          console.log(data);
          setFormattedTime(formattedTotal);
        })
        .catch(error => {
          dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
        });
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  };
  useEffect(() => {
    if (selectedCard) {
      getTaskTime();
    }
  }, [selectedCard]);
  return (
    <div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell style={styleObj}>Name</TableCell>
              <TableCell style={styleObj}>Time(hh/mm)</TableCell>
              <TableCell style={styleObj}>Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {taskTimeData?.map((entry, index) => (
              <TableRow key={index}>
                <TableCell style={styleObj}>{`${entry?.FirstName}  ${entry?.LastName}`}</TableCell>
                <TableCell style={styleObj}>{`${
                  entry?.TimeHours > 9 ? entry?.TimeHours : '0' + entry?.TimeHours
                }:${entry.TimeMinutes.toString().padStart(2, '0')}`}</TableCell>
                <TableCell style={styleObj}>{new Date(entry?.LogDateTime)?.toLocaleDateString()}</TableCell>
              </TableRow>
            ))}
            <TableRow>
              <TableCell style={styleObjTotal} colSpan={1}>
                Total Time
              </TableCell>
              <TableCell style={styleObjTotal}>{formattedTime}</TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default TaskTimeHistory;
