import React, { useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';

import AppTextInput from '../../../../@jumbo/components/Common/formElements/AppTextInput';
import CmtCard from '../../../../@coremat/CmtCard';
import { idGenerator } from '../../../../@jumbo/utils/commonHelper';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { kanbanBoard } from '../../../../routes/Pages/Planning/TaskmanagementPage/components/kanbanBoard';
const useStyles = makeStyles(() => ({
  root: {
    marginTop: 5,
    width: 270,
    height: 103.75,
  },
}));

const NewListForm = ({ onCancel, onAdd }) => {
  const classes = useStyles();
  const [title, setTitle] = useState('');

  const onListSave = () => {
    if (title) {
      let id = idGenerator().toString();
      //console.log(kanbanBoard.teamIds);
      onAdd({ id: id, title: title });
      let newStatus = {
        tmTeamId: kanbanBoard.teamIds ? kanbanBoard.teamIds.value : undefined,
        SortOrder: 1,
        tmStatusName: title,
        tmStatusDesc: title,
      };
      customAxios.post('/Task/AddStatus', newStatus, { headers: authHeader() });
    } else {
      onCancel();
    }
  };

  return (
    <CmtCard className={classes.root} style={{ overflow: 'visible' }}>
      <Box p={4} style={{ backgroundColor: '#f0f1f9' }}>
        <Box mb={4} style={{ marginBottom: 8 }}>
          <AppTextInput
            placeholder="Enter Status title..."
            autoFocus
            fullWidth
            multiline
            value={title}
            onChange={e => setTitle(e.target.value)}
            variant="outlined"
          />
        </Box>
        <Box display="flex" alignItems="center">
          <Button variant="contained" style={{ textTransform: 'none' }} color="primary" size="small" onClick={onListSave}>
            Save
          </Button>
          <Box ml={1}>
            <IconButton size="small" onClick={onCancel}>
              <Close fontSize="medium" />
            </IconButton>
          </Box>
        </Box>
      </Box>
    </CmtCard>
  );
};

export default NewListForm;
