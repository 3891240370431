import React, { useEffect, useState } from 'react';
import { Typography, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import Select from 'react-select';
import { useForm, Controller } from 'react-hook-form';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';
import { Button } from '@material-ui/core';

const AdvancedFilterDialog = ({
  openFilterDialog,
  setOpenFilterDialog,
  filterMembers,
  projectListData,
  setFilterData,
  advancedClickFilter,
  onClose,
}) => {
  const { control, handleSubmit, setValue } = useForm();
  const [startDateFilter, setStartDateFilter] = useState(null);
  const [endDateFilter, setEndDateFilter] = useState(null);
  const [projectList, setProjectList] = useState([]);

  const handleStartDateChange = date => {
    setStartDateFilter(date);
    setValue('startDate', date);
  };

  const handleEndDateChange = date => {
    setEndDateFilter(date);
    setValue('endDate', date);
  };

  const handleClearFilter = () => {
    setValue('project', []);
    setValue('members', []);
    setStartDateFilter(null);
    setEndDateFilter(null);
  };

  const handleClose = () => {
    setOpenFilterDialog(false);
    onClose();
  };

  useEffect(() => {
    if (projectListData) {
      const projects = projectListData.map(project => ({
        value: project.pId,
        label: project.pName,
      }));
      setProjectList(projects);
    }
  }, [projectListData]);

  const onSubmit = formData => {
    setFilterData(formData);
    advancedClickFilter(formData);
    setOpenFilterDialog(false);
    onClose();
  };

  return (
    <Dialog maxWidth="sm" open={openFilterDialog} onClose={handleClose}>
      <DialogTitle>
        <Typography sx={{ fontFamily: 'normal' }} variant="h6">
          Advanced Filters
        </Typography>
      </DialogTitle>
      <DialogContent>
        <form id="filterForm" onSubmit={handleSubmit(onSubmit)}>
          {/* Select Project */}
          <div style={{ marginBottom: '1rem' }}>
            <Typography variant="subtitle1">Select Project</Typography>
            <Controller
              name="project"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select options={projectList} isMulti value={value} onChange={onChange} />
              )}
            />
          </div>

          {/* Select Members */}
          <div sx={{ fontFamily: 'normal' }} style={{ marginBottom: '1rem' }}>
            <Typography sx={{ fontFamily: 'normal' }} variant="subtitle1">
              Select Members
            </Typography>
            <Controller
              name="members"
              control={control}
              render={({ field: { onChange, value } }) => (
                <Select options={filterMembers} isMulti value={value} onChange={onChange} />
              )}
            />
          </div>

          {/* Due Date Filters */}
          <div style={{ marginBottom: '1rem' }}>
            <Typography sx={{ fontFamily: 'normal', marginBottom: '0.5rem' }} variant="subtitle1">
              Due Date Filters
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'space-between', gap: '1rem' }}>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography sx={{ fontFamily: 'normal', marginBottom: '0.5rem' }} variant="subtitle2">
                  Start Date
                </Typography>
                <CustomeDatePicker value={startDateFilter} handleDateHandler={handleStartDateChange} />
              </div>
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                <Typography sx={{ fontFamily: 'normal', marginBottom: '0.5rem' }} variant="subtitle2">
                  Due Date
                </Typography>
                <CustomeDatePicker value={endDateFilter} handleDateHandler={handleEndDateChange} />
              </div>
            </div>
          </div>
        </form>
      </DialogContent>

      <DialogActions>
        <Button style={{ textTransform: 'none' }} type="submit" form="filterForm" variant="contained" color="primary">
          Apply
        </Button>
        <Button style={{ textTransform: 'none' }} onClick={handleClearFilter} variant="outlined">
          Clear
        </Button>
        <Button style={{ textTransform: 'none' }} onClick={handleClose} variant="text">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvancedFilterDialog;
