import React from 'react';
import { TableCell, TableRow, Skeleton } from '@mui/material';

import { styled } from '@material-ui/styles';

const CustomTableCell = styled(TableCell)`
  && {
    padding: 8px; /* Set the desired padding here */
  }
`;

const TableSkeletonLoader = ({ row = 5, col = 5 }) =>
  Array.from({ length: row }).map((_, index) => (
    <TableRow key={index}>
      {Array.from({ length: col }).map((item, index) => (
        <CustomTableCell key={index}>
          <Skeleton variant="rectangular" width="100" height="50" />
        </CustomTableCell>
      ))}

      {/* <CustomTableCell>
            <Skeleton variant="rectangular" width={100} height={40} />
          </CustomTableCell>
          <CustomTableCell>
            <Skeleton variant="rectangular" width={100} height={40} />
          </CustomTableCell>
          <CustomTableCell>
            <Skeleton variant="rectangular" width={100} height={40} />
          </CustomTableCell>
          <CustomTableCell>
            <Skeleton variant="text" width={100} height={40} />
          </CustomTableCell>
          <CustomTableCell>
            <Skeleton variant="text" width={100} height={40} />
          </CustomTableCell> */}
    </TableRow>
  ));

export default TableSkeletonLoader;
