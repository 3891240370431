import React, { useRef } from 'react';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';

import EmailRoundedIcon from '@material-ui/icons/EmailRounded';

const PopUp = ({ displayContent, popupContent }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [popUpData, setPopUpData] = React.useState(popupContent);
  const popupData = Object.keys(popupContent);
  const compRef = useRef(null);

  const handleClick = event => {
    if (open) setAnchorEl(null);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div ref={compRef} onClick={handleClick}>
      {/* <EmailRoundedIcon/> */}
      <div aria-describedby={id} variant="contained">
        {displayContent}
      </div>

      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        disableScrollLock={true}
        transformOrigin={{
          horizontal: 500,
          vertical: 10,
        }}
        ref={compRef}
        style={{ width: '800px' }}>
        <div
          className="e-content pb-1"
          onMouseLeave={() => handleClose()}
          style={{
            width: '500px',
            height: '300px',
            border: '1px solid  rgba(0,0,0,.250)',
            borderRadius: '5px',
            overflowY: 'scroll',
            scrollbarWidth: 'thin',
          }}>
          {Object.keys(popupContent).map((item, index) => {
            if (item == 'Id' || item == 'UserId') return;
            return (
              <div
                className="d-flex-column w-100  m-0  px-3 "
                style={{ paddingBlock: '5px 2px', borderRadius: '5px', borderBottom: '1px solid rgba(0,0,0,.125)' }}
                key={index}>
                <div className="  text-capitalize" style={{ minHeight: '35px' }}>
                  <div
                    style={{
                      fontFamily: 'normal',
                      fontSize: '0.7rem',
                      color: 'rgba(0,0,0,0.6)',
                      fontWeight: '400',
                      lineHeight: 1.5,
                      letterSpacing: '0.5px',
                    }}>
                    {item} :{' '}
                  </div>
                  <div
                    style={{
                      fontFamily: 'normal',
                      fontSize: '0.7rem',
                      fontWeight: '400',
                      lineHeight: 1.5,
                      letterSpacing: '0.5px',
                    }}>
                    {' '}
                    {popupContent[item]}{' '}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </Popover>
    </div>
  );
};

export default PopUp;
