/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useEffect, useState } from 'react';
import { ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import IntlMessages from '../../util/IntlMessages';
import InputLabel from '@material-ui/core/InputLabel';
import Drawer from '@mui/material/Drawer';
import { useForm, Controller } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { Box, Select, MenuItem, TextareaAutosize } from '@material-ui/core';

import moment from 'moment';
import { CustomeDatePicker } from 'routes/Pages/components/DatePicker';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
  footerStyle: {
    marginTop: 30,
  },
}));

const AddProject = ({ details, toggle, onClientsClose, filterModalOpen, teams, tmMembers, selectedTeamValue }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);
  const [assignedTo, setAssignedTo] = useState('');
  const [teamMemberList, setTeamMemberList] = useState([]);

  //date picker

  const [open, setOpen] = React.useState(false);
  const [endOpen, setEndOpen] = React.useState(false);

  // selectedTeamValue?.tmTeamName

  const handlestartDateHandler = (dateChange, cardId) => {
    setValue('startDate', dateChange);
    setStartDate(dateChange);
    setOpen(false);

    // handleDueDateChange(dateChange, cardId);
  };

  const handleDueDateHandler = dateChange => {
    setEndDate(dateChange);
    setValue('pDueDate', dateChange);
    setEndOpen(false);
  };

  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    setError,
    clearErrors,
  } = useForm({});

  React.useEffect(() => {
    if (!teams.length) {
      setError('tmTeamId', {
        type: 'custom',
        message: 'In order to create a Project add a team first.',
      });
    } else {
      clearErrors('tmTeamId');
    }
  }, [teams]);

  useEffect(() => {
    if (selectedTeamValue) {
      setSelectedTeam(selectedTeamValue.Id);
      setValue('tmTeamId', selectedTeamValue.Id);
    }
  }, [selectedTeamValue, tmMembers]);

  useEffect(() => {
    let selectedMember = tmMembers?.filter(instance => instance.TeamId === selectedTeam);

    if (selectedTeam && !selectedMember?.length) {
      setError('pAssignedToId', {
        type: 'custom',
        message: 'In order to create a Project add a member inside team.',
      });
    } else {
      clearErrors('pAssignedToId');
      setTeamMemberList(selectedMember);
    }
  }, [selectedTeam, tmMembers]);

  const handleReset = () => {
    reset({
      pName: '',
      pDescription: '',
      startDate: '',
      pDueDate: '',
      pAssignedToId: '',
      tmTeamId: '',
    });
    setStartDate(null);
    setEndDate(null);
    setSelectedTeam('');
    setAssignedTo('');
  };
  const handlePressCancelWithReset = () => {
    onClientsClose();
    handleReset();
  };

  /**
   * This function is called after submiting the form
   *
   * @param formData
   */

  const onSubmit = data => {
    if (!Object.keys(errors).length) {
      let postData = {};
      let url = 'Project/AddProject';
      if (details.edit) {
        url = 'Project/EditProject';
        postData = [
          {
            tableName: 'TM_Projects',
            columnName: 'pName',
            newValue: data['pName'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'pDescription',
            newValue: data['pDescription'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'pStartDate',
            newValue: data['startDate'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'pDueDate',
            newValue: data['pDueDate'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'pAssignedTo',
            newValue: data['pAssignedToId'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'pTeamId',
            newValue: data['tmTeamId'],
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
          {
            tableName: 'TM_Projects',
            columnName: 'ClientId',
            newValue: 0,
            primaryKeyValue: details.pId.toString(),
            tmTeamId: data['tmTeamId'].toString(),
          },
        ];
      } else {
        postData = {
          ClientId: 0,
          pName: data['pName'],
          pDescription: data['pDescription'],
          startDate: new Date(data['startDate']).toJSON(),
          pDueDate: new Date(data['pDueDate']).toJSON(),
          pAssignedToId: Number(data['pAssignedToId']),
          tmTeamId: data['tmTeamId'],
        };
      }

      dispatch({ type: FETCH_START });
      customAxios
        .post(url, postData, { headers: authHeader() })
        .then(data => {
          dispatch({ type: FETCH_SUCCESS });
          onClientsClose();
          handleReset();
        })
        .catch(function(error) {
          if (error.response.status == '400') {
            alert(error.response.data.title);
            var errorList = error.response.data.errors;
            if (errorList) {
              //iterate through errors {} and add key values
              Object.keys(errorList).forEach((key, index) => {
                setError('apiError', {
                  message: errorList[key],
                });
              });
            }
          }
          dispatch({ type: FETCH_ERROR, payload: error.message });
        });
    }
  };

  return (
    <React.Fragment>
      <Drawer anchor={'right'} open={filterModalOpen} onClose={toggle} style={{ width: '100%' }}>
        <Box sx={{ width: '100%' }} className="cardDetails">
          <ModalHeader toggle={handlePressCancelWithReset} className="d-flex">
            <IntlMessages id="projectsection.addProject" />
            <button
              type="button"
              className="close"
              aria-label="Close"
              onClick={handlePressCancelWithReset}
              style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
              <span aria-hidden="true">×</span>
            </button>
          </ModalHeader>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
            <ModalBody>
              {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
              <div className="modal-box-content">
                <div className="col-lg-12 d-flex flex-column order-lg-1 mb-5">
                  <Controller
                    name="pName"
                    control={control}
                    defaultValue={''}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        // label={'Project Name'}
                        label={<IntlMessages id="projectsection.ProjectName" />}
                        margin="none"
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pName"
                    render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                  />
                </div>
                {/* <div className="col-lg-1 d-flex flex-column order-lg-1"></div> */}
                <div className="col-lg-12 d-flex w-full flex-column order-lg-1 mb-5 ">
                  <Controller
                    name="pDescription"
                    control={control}
                    defaultValue={''}
                    rules={{ required: 'This is required' }}
                    render={({ field }) => (
                      <TextareaAutosize
                        {...field}
                        // label={<IntlMessages id="projectsection.ProjectSubject" />}
                        margin="none"
                        aria-label="minimum height"
                        className="project-description-textarea"
                        minRows={2}
                        placeholder="Project Description"
                        style={{ border: 'none', borderBottom: '1px solid gray' }}
                      />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="pDescription"
                    render={({ message }) => <p className={classes.errorMsg}>{message}</p>}
                  />
                </div>
                <div className="flex-row d-flex mt-3">
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    <InputLabel id="demo-simple-select-label">Start Date</InputLabel>
                    <Controller
                      name="startDate"
                      control={control}
                      rules={{ required: 'This is required' }}
                      defaultValue={''}
                      render={({ field }) => {
                        return (
                          // <TextField
                          //   required={true}
                          //   type="date"
                          //   margin="normal"
                          //   style={{ marginTop: '0px' }}
                          //   value={startDate == null ? '' : startDate}
                          //   onChange={e => {
                          //     setValue('startDate', e.target.value);
                          //     setStartDate(e.target.value);
                          //   }}
                          // />
                          <div className="DateContainer">
                            <CustomeDatePicker
                              open={open}
                              OpenDate={() => setOpen(true)}
                              CloseDate={() => setOpen(false)}
                              value={startDate}
                              // onChange={handleDueDateChange}
                              handleDateHandler={handlestartDateHandler}
                            />
                          </div>
                        );
                      }}
                    />
                    {/* )} */}
                    <ErrorMessage
                      errors={errors}
                      name="startDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    <InputLabel id="demo-simple-select-label">End Date</InputLabel>
                    <Controller
                      name="pDueDate"
                      control={control}
                      rules={{ required: 'This is required' }}
                      defaultValue={details.pDueDate ? details.pDueDate : ''}
                      render={({ field }) => (
                        <div className="DateContainer">
                          <CustomeDatePicker
                            open={endOpen}
                            OpenDate={() => setEndOpen(true)}
                            CloseDate={() => setEndOpen(false)}
                            value={endDate}
                            // onChange={handleDueDateChange}
                            handleDateHandler={handleDueDateHandler}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="pDueDate"
                      render={({ message }) => <p className="formInputError">{message}</p>}
                    />
                  </div>
                </div>
                <div className="flex-row d-flex mt-3">
                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    <Controller
                      name="tmTeamId"
                      control={control}
                      rules={
                        !teams.length
                          ? { required: 'In order to create a Project add a team first.' }
                          : { required: 'This is required' }
                      }
                      defaultValue={details.tmTeamId ? details.tmTeamId : ''}
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <>
                            <InputLabel id="demo-simple-select-label">Select Team</InputLabel>
                            <Select
                              value={selectedTeam}
                              defaultValue={selectedTeam}
                              onChange={event => {
                                console.log(event.target.value, 'event');
                                setSelectedTeam(event.target.value);
                                setValue('tmTeamId', event.target.value);
                              }}>
                              {teams.map(team => {
                                return (
                                  <MenuItem value={team.Id} key={team.Id}>
                                    {' '}
                                    {team.tmTeamName}{' '}
                                  </MenuItem>
                                );
                              })}
                            </Select>
                          </>
                        );
                      }}
                    />

                    <ErrorMessage
                      errors={errors}
                      name="tmTeamId"
                      render={({ message }) => (
                        <p className="formInputError" id="addproject.ErrorMember">
                          {message}
                        </p>
                      )}
                    />
                  </div>

                  <div className="col-lg-6 d-flex flex-column order-lg-1 mb-5">
                    {' '}
                    {teamMemberList.length > 0 && (
                      <Controller
                        name="pAssignedToId"
                        control={control}
                        defaultValue={details.pAssignedToId ? details.pAssignedToId : ''}
                        rules={{
                          required:
                            selectedTeam === ''
                              ? 'Select a team'
                              : teamMemberList?.length
                              ? 'This is required'
                              : 'In order to create a Project add a member inside team.',
                        }}
                        render={({ field: { onChange, value, ref } }) => {
                          return (
                            <>
                              <InputLabel id="demo-simple-select-label">Select Assignee</InputLabel>
                              <Select
                                value={assignedTo}
                                onChange={event => {
                                  setAssignedTo(event.target.value);
                                  setValue('pAssignedToId', event.target.value);
                                }}>
                                {teamMemberList.map(team => {
                                  return (
                                    <MenuItem value={team.TMUserId} key={team.TMUserId}>
                                      {' '}
                                      {team.Email}{' '}
                                    </MenuItem>
                                  );
                                })}
                              </Select>
                            </>
                          );
                        }}
                      />
                    )}
                    <ErrorMessage
                      errors={errors}
                      name="pAssignedToId"
                      render={({ message }) => (
                        <p className="formInputError" id="addproject.ErrorTeam">
                          {message}
                        </p>
                      )}
                    />
                  </div>
                </div>
              </div>
            </ModalBody>
            <ModalFooter className={classes.footerStyle}>
              <Button type="submit" variant="contained" color="primary">
                <IntlMessages id="projectsection.addProject" />
              </Button>
              <Button onClick={handlePressCancelWithReset}>Cancel</Button>
            </ModalFooter>
          </form>
        </Box>
      </Drawer>
    </React.Fragment>
  );
};

export default AddProject;
