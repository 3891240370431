/**
 * Version 1.0
 * Vikas W
 * 26.06.2022
 * -
 * This component is shown when we want to add a new invoice.
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
// import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import IntlMessages from '../../util/IntlMessages';
import { useForm, Controller } from 'react-hook-form';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';

/* React multiple email input */
import { ReactMultiEmail, isEmail } from 'react-multi-email';
import 'react-multi-email/style.css';
import FileToBase64Converter from './FileToBase64Converter';

const SendGridEmail = ({ modalSendGrid, detailsSendGrid, toggleSendGrid, sendEmailSendGrid, acctTeamId }) => {
  const [childs, setChilds] = useState([]);
  const [attachementFiles, setAttachementFiles] = useState([]);
  const [emailsTo, setEmailsTo] = useState([]);
  const [emailsCC, setEmailsCC] = useState([]);
  const {
    InvoiceNumber,
    InvoiceId,
    ClientName,
    ClientAddress1,
    ClientAddress2,
    ClientPhoneNumber,
    ClientVATNumber,
    ClientEmail,
    CompanyName,
    CompanyLogoUrl,
    CompanyAddress1,
    CompanyAddress2,
    CompanyPhoneNumber,
    CompanyVATNumber,
    BIC,
    Sort,
    BankName,
    PaymentTerms,
    IBAN,
    InvoicePrefix,
    IsDraft,
    IsPaid,
    IssueDate,
    DueDate,
    child,
  } = detailsSendGrid;

  const dispatch = useDispatch();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});
  const [currenctCode, setCurrencyCode] = useState('€');
  /**
   * Function called when we submit the form
   *
   * @param formData The responses of the form
   */
  const onSubmit = formData => {
    // let InvoiceItems = [];
    let editInvoice = {
      TableName: 'Acct_Invoices',
      ColumnName: 'IsDraft',
      NewValue: '0',
      PrimaryKeyValue: InvoiceId.toString(),
      AcctTeamId: acctTeamId,
      // InvoiceItems: InvoiceItems,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post('/Invoice/EditInvoiceOnly', editInvoice, { headers: authHeader() })
      .then(data => {
        dispatch({ type: FETCH_SUCCESS });
        /* Send Email and close modal */
        sendEmailSendGrid(detailsSendGrid, emailsTo, emailsCC, formData['emailSubject'], attachementFiles);
        setTimeout(() => {
          // toggleSendGrid();
        }, 1000);
      })
      .catch(function(error) {
        // if (error.response.status == '400') {
        //   alert(error);
        //   var errorList = error.response.data.errors;
        //   if (errorList) {
        //     //iterate through errors {} and add key values
        //     Object.keys(errorList).forEach((key, index) => {
        //       setError('apiError', {
        //         message: errorList[key],
        //       });
        //     });
        //   }
        // }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  function totalNetAmount() {
    let netAmountAdded = 0;
    let count = 0;
    if (childs) {
      childs.forEach((data, index) => {
        count = parseInt(+data.Quantity * +data.ProductRate);
        netAmountAdded += count;
      });
    }
    return netAmountAdded.toFixed(2);
  }
  function totalTaxAmount() {
    let taxAmountAdded = 0;
    let count = 0;
    if (childs) {
      childs.forEach((data, index) => {
        if (data.vat !== 0) {
          count = parseInt(+data.Quantity * +data.ProductRate);
          taxAmountAdded += (count * +data.VatRate) / 100;
        }
      });
    }
    return taxAmountAdded.toFixed(2);
  }

  useEffect(() => {
    setChilds(child);
    setEmailsTo([ClientEmail]);
    if (child.length > 0 && child[0]['CurrencyCode']) {
      console.log(child[0]['CurrencyCode']);
      handelCurrencyToggle(child[0]['CurrencyCode']);
    }
  }, [child]);
  function handelCurrencyToggle(code) {
    if (code == 'pound') {
      setCurrencyCode('£');
    } else if (code == 'dollar') {
      setCurrencyCode('$');
    } else if (code == 'rupee') {
      setCurrencyCode('₹');
    } else {
      setCurrencyCode('€');
    }
  }
  return (
    <Modal className="invoice modal-box" size="lg" isOpen={modalSendGrid} toggle={toggleSendGrid}>
      <ModalHeader toggle={toggleSendGrid} className="d-flex">
        {detailsSendGrid.length === 0 ? (
          <div style={{ letterSpacing: '0.5px', textTransform: 'capitalize', fontWeight: '500' }}>
            <IntlMessages id="invoices.saveInvoice" />
          </div>
        ) : (
          <div style={{ letterSpacing: '0.25px', textTransform: 'capitalize', fontWeight: '500' }}>
            {' '}
            <IntlMessages id="appModule.invoice_send_sendgrid" />
          </div>
        )}
        <button
          type="button"
          className="close"
          aria-label="Close"
          onClick={toggleSendGrid}
          style={{ position: 'absolute', right: '19px', top: '18px', background: '#fff', opacity: 1 }}>
          <span aria-hidden="true">×</span>
        </button>
      </ModalHeader>
      <form
        name="myForm"
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        <ModalBody>
          {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
          <div className="modal-box-content">
            <div className="row no-gutters">
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.invoice_to_email" />
                </InputLabel>
                <ReactMultiEmail
                  placeholder="Add multiple email with comma seperated value."
                  emails={emailsTo}
                  onChange={_emails => {
                    setEmailsTo(_emails);
                  }}
                  validateEmail={email => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
              <br />
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.invoice_cc_email" />
                </InputLabel>
                <ReactMultiEmail
                  placeholder="Add multiple email with comma seperated value."
                  emails={emailsCC}
                  onChange={_emails => {
                    setEmailsCC(_emails);
                  }}
                  validateEmail={email => {
                    return isEmail(email); // return boolean
                  }}
                  getLabel={(email, index, removeEmail) => {
                    return (
                      <div data-tag key={index}>
                        {email}
                        <span data-tag-handle onClick={() => removeEmail(index)}>
                          ×
                        </span>
                      </div>
                    );
                  }}
                />
              </div>
              <br />
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.invoice_subject_email" />
                </InputLabel>
                <Controller
                  name="emailSubject"
                  control={control}
                  defaultValue={InvoiceNumber ? 'Invoice No: ' + InvoiceNumber : ''}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      // label={<IntlMessages id="appModule.invoiceNumber" />}
                      // value=""
                      margin="none"
                    />
                  )}
                />
              </div>
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                <FileToBase64Converter files={attachementFiles} setFiles={setAttachementFiles} />
              </div>
              <br />
              <div className="col-lg-12 d-flex flex-column invoice_label mb-4">
                <InputLabel htmlFor="age-native-helper">
                  <IntlMessages id="appModule.invoice_Preview" />
                </InputLabel>

                <div className="invoice_wrapper p-0">
                  <div className="in_title">
                    <h2>Invoice {InvoiceNumber}</h2>
                  </div>
                  <div className="in_name">
                    <p className="in_text_right">
                      {CompanyLogoUrl && <img src={CompanyLogoUrl} width="80" />}
                      <span>
                        Issue Date: {IssueDate}
                        <br />
                        Due Date: {DueDate}
                      </span>
                    </p>
                    <hr />
                    <div className="in_left">
                      <p>
                        <strong style={{ fontSize: '16px' }}>{ClientName}</strong>
                        <br />
                        {ClientAddress1}
                        <br />
                        {ClientAddress2}
                        <br />
                        Phone: - {ClientPhoneNumber}
                        <br />
                        VAT: {ClientVATNumber}
                        <br />
                        Email: {ClientEmail}
                      </p>
                    </div>
                    <div className="in_right">
                      <p>
                        <strong style={{ fontSize: '16px' }}>{CompanyName}</strong>
                        <br />
                        {CompanyAddress1}
                        <br />
                        {CompanyAddress2}
                        <br />
                        Phone: {CompanyPhoneNumber}
                        <br />
                        VAT Number: {CompanyVATNumber}
                        <br />
                      </p>
                    </div>
                  </div>
                  <div className="in_blank_box"></div>

                  <table className="in_blank_box">
                    <thead>
                      <tr style={{ background: 'pink' }}>
                        <th>Product</th>
                        <th>Qty</th>
                        <th>Rate</th>
                        <th>VAT</th>
                        <th>Net Amount</th>
                        <th>Gross Amount</th>
                        <th>Description</th>
                      </tr>
                    </thead>
                    <tbody>
                      {childs &&
                        childs.map((row, index) => (
                          <tr key={index}>
                            <td>{row.Product}</td>
                            <td>{row.Quantity}</td>
                            <td>{row.ProductRate}</td>
                            <td>{row.VatRate}</td>
                            <td>
                              {currenctCode}
                              {row.NetAmount.toFixed(2)}
                            </td>
                            <td>
                              {currenctCode}
                              {row.GrossAmount.toFixed(2)}
                            </td>
                            <td>{row.ProductDescription}</td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  <p>&nbsp;</p>

                  <table className="in_blank_box">
                    <thead>
                      <tr style={{ background: 'pink' }}>
                        <th>Subtotal</th>
                        <th>VAT</th>
                        <th>Total Gross Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          {currenctCode}
                          {totalNetAmount()}
                        </td>
                        <td>
                          {currenctCode}
                          {totalTaxAmount()}
                        </td>
                        <td>
                          {currenctCode}
                          {+totalNetAmount() + +totalTaxAmount()}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <h3>Bank Details:</h3>
                  <table className="in_blank_box">
                    <thead>
                      <tr style={{ background: 'pink' }}>
                        <th>Bank Name</th>
                        <th>IBAN</th>
                        <th>Sort Code</th>
                        <th>BIC</th>
                        <th>Payment Terms</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{BankName}</td>
                        <td>{IBAN}</td>
                        <td>{Sort}</td>
                        <td>{BIC}</td>
                        <td>{PaymentTerms}</td>
                      </tr>
                    </tbody>
                  </table>
                  <p>&nbsp;</p>
                  <p style={{ textAlign: 'center', fontSize: '12px' }}>Copyright Focus247 © 2022</p>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ textTransform: 'none' }}
            // disabled={details.length === 0 ? false : true}
          >
            Send Email
          </Button>
          <Button style={{ textTransform: 'none' }} onClick={toggleSendGrid}>
            Cancel
          </Button>
        </ModalFooter>
      </form>
    </Modal>
  );
};

export default SendGridEmail;
