import React, { useEffect } from 'react';
import ContainerHeader from '../../components/ContainerHeader/index';
import IntlMessages from '../../util/IntlMessages';
import { useDispatch, useSelector } from 'react-redux';
import MailboxesTable from './MailboxesTable';
import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  INIT_URL,
  SIGNOUT_USER_SUCCESS,
  USER_DATA,
  USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import 'bootstrap/dist/css/bootstrap.min.css';

/* Microsoft 365 login */
import Button from '@material-ui/core/Button';
import { Container } from 'react-bootstrap';
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import { useAppContext } from '../../../../microsoft-context/AppContext';

/* Material Tables */
import Paper from '@material-ui/core/Paper';
import { Box } from '@material-ui/core';
import { lighten, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(4),
    backgroundColor: lighten(theme.palette.background.paper, 0.1),
  },
}));

const MicrosoftPage = props => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const app = useAppContext();
  console.log({ app });
  const userData = useSelector(state => state?.auth?.userData);

  // useEffect(() => {
  //   if (userData) {
  //     localStorage.setItem('userData', JSON.stringify(userData));
  //   }
  // }, [userData]);

  return (
    <div className="app-wrapper">
      {/* <ContainerHeader match={props.match} title={<IntlMessages id="pages.MicrosoftPage" />} /> */}
      <Box className={classes.root}>
        <Paper className={classes.paper}>
          <div className="p-5 mb-4 bg-light rounded-3">
            <Container fluid>
              {/* <AuthenticatedTemplate>
                <div>
                  <h4>Welcome {app.user?.displayName || ''}!</h4>
                  <p>
                    <Button variant="contained" color="primary" onClick={() => app.signOut()}>
                      Sign out
                    </Button>
                  </p>
                </div>
              </AuthenticatedTemplate>
              <UnauthenticatedTemplate>
                <Button variant="contained" color="primary" onClick={() => app.signIn()}>
                  Sign in
                </Button>
              </UnauthenticatedTemplate> */}
              <MailboxesTable />
            </Container>
          </div>
        </Paper>
      </Box>
    </div>
  );
};

export default MicrosoftPage;
