import axios from 'axios';

export default axios.create({
  // baseURL: `http://g-axon.work/jwtauth/api/`, //YOUR_API_URL HERE
  baseURL: `https://api.focus247.online/`, //YOUR_API_URL HERE
  headers: {
    'Content-Type': 'application/json',
  },
});

// import axios from 'axios';

// const customAxios = axios.create({
//   // baseURL: `http://g-axon.work/jwtauth/api/`, //YOUR_API_URL HERE
//   baseURL: `http://api.focus247.online/`, //YOUR_API_URL HERE
//   headers: {
//     'Content-Type': 'application/json',
//   },
// });

// customAxios.interceptors.response.use(
//   res => {
//     return res;
//   },
//   err => {
//     console.log(err);
//   },
//   // function(response) {
//   //   debugger;
//   //   return response;
//   // },
//   // function(error) {
//   //   debugger;
//   //   if (401 === error.response.status) {
//   //     // handle error: inform user, go to login, etc
//   //   } else {
//   //     return Promise.reject(error);
//   //   }
//   // },
// );

// export default customAxios;
