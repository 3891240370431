import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Collapse, IconButton, Link } from '@material-ui/core';
import { alpha } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import { Alert } from '@material-ui/lab';
import CloseIcon from '@material-ui/icons/Close';

import CmtImage from '../../../../@coremat/CmtImage';

import IntlMessages from '../../../utils/IntlMessages';
import { AuhMethods } from '../../../../services/auth';
import ContentLoader from '../../ContentLoader';
import { CurrentAuthMethod } from '../../../constants/AppConstants';
import AuthWrapper from './AuthWrapper';
import { setForgetPassMailSent } from '../../../../redux/actions/Auth';

import { NavLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  authThumb: {
    backgroundColor: alpha(theme.palette.primary.main, 0.12),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: 20,
    [theme.breakpoints.up('md')]: {
      width: '50%',
      order: 2,
    },
  },
  authContent: {
    padding: 30,
    [theme.breakpoints.up('md')]: {
      order: 1,
      width: props => (props.variant === 'default' ? '50%' : '100%'),
    },
    [theme.breakpoints.up('xl')]: {
      padding: 50,
    },
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  titleRoot: {
    fontSize: '2rem',
    marginBottom: 14,
    color: theme.palette.text.secondary,
    textAlign: 'center',
  },
  textFieldRoot: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: alpha(theme.palette.common.dark, 0.12),
    },
  },
  alertRoot: {
    marginBottom: 10,
  },
}));

//variant = 'default', 'standard', 'bgColor'
const ConfirmEmail = ({ method = CurrentAuthMethod, variant = 'default', wrapperVariant = 'default' }) => {
  const dispatch = useDispatch();
  const classes = useStyles({ variant });
  const history = useHistory();

  useEffect(() => {
    if (window.location.href.includes('confirm')) {
      const array = window.location.href.split('?');
      const alltoken = array[array.length - 1].split('&');
      let fulltoken = alltoken[0].split('=');
      let fullemail = alltoken[1].split('=');
      const token = fulltoken[1];
      const email = fullemail[1];
      dispatch(AuhMethods[CurrentAuthMethod].onUserEmailVerifying({ email, token, history }));
    }
  }, []);
  return (
    <>
      <div className={classes.authContent}>
        <div className={'mb-7'} style={{ textAlign: 'center' }}>
          <CmtImage src={'/images/focus-logo/focus-logo.png'} width={'150px'} />
        </div>
        <Typography component="div" variant="h1" className={classes.titleRoot}>
          Please wait ..Verifying your email
        </Typography>
      </div>
    </>
  );
};

export default ConfirmEmail;
