import * as React from 'react';
import Timeline from '@mui/lab/Timeline';
import TimelineItem from '@mui/lab/TimelineItem';
import TimelineSeparator from '@mui/lab/TimelineSeparator';
import TimelineConnector from '@mui/lab/TimelineConnector';
import TimelineContent from '@mui/lab/TimelineContent';
import TimelineDot from '@mui/lab/TimelineDot';
import { FETCH_ERROR, FETCH_START, FETCH_SUCCESS } from '../../../constants/ActionTypes';
import customAxios from '../../../util/Api';

import authHeader from '../../../../../services/auth/jwt/auth-header';
import { useDispatch } from 'react-redux';
import { Button, IconButton } from '@material-ui/core';
import { NotesOutlined } from '@material-ui/icons';
import NoteItem from '../NoteItem';

export default function NoteTimelineHistory({ notesData, setNotesData, contactData, getNotesData }) {
  const dispatch = useDispatch();

  function deleteNote(args) {
    let dataObj = {
      ContactId: contactData.crmId,
      NoteId: args.Id,
      crmTeamId: contactData.CrmTeamId,
    };

    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/DeleteCRMNote`, dataObj, {
          headers: authHeader(),
        })
        .then(data => {
          console.log(data, 'data');
          getNotesData();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  function editNote(dataObj) {
    let updateDataObj = { ...dataObj, crmTeamId: contactData.CrmTeamId };
    dispatch({ type: FETCH_START });
    try {
      customAxios
        .post(`CRMNotes/EditCRMNote`, updateDataObj, {
          headers: authHeader(),
        })
        .then(() => {
          getNotesData();
          dispatch({ type: FETCH_SUCCESS });
        })
        .catch(error => {});
    } catch (e) {
      dispatch({ type: FETCH_ERROR, payload: 'Network Error' });
    }
  }

  const handleShowHistory = () => {
    console.log('success');
    getNotesData();
  };

  return (
    <div>
      {notesData === null ? (
        <div className="m-1">
          <Button
            variant="contained"
            style={{ background: '#3f51b5', color: 'white', textTransform: 'none' }}
            onClick={() => handleShowHistory()}>
            Show History
          </Button>
        </div>
      ) : (
        <>
          {' '}
          {notesData?.map((item, index) => {
            return (
              <Timeline key={index}>
                <TimelineItem>
                  <TimelineSeparator>
                    <TimelineConnector />
                    <TimelineDot
                      style={{
                        color: '#fff',
                        background: '#3f51b5',
                      }}>
                      <IconButton size="small" variant="contained">
                        <NotesOutlined style={{ color: 'white' }} />
                      </IconButton>
                    </TimelineDot>
                    <TimelineConnector />
                  </TimelineSeparator>
                  <TimelineContent style={{ width: '400px' }}>
                    <NoteItem value={notesData} editNote={editNote} deleteNote={deleteNote} noteItem={item} />
                  </TimelineContent>
                </TimelineItem>
              </Timeline>
            );
          })}
        </>
      )}
    </div>
  );
}
