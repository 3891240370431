import React, { useState, useRef, useEffect } from 'react';
import { FormControl } from '@mui/material';
import KeyboardArrowDownOutlinedIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import Select from 'react-select';

const priorityList = [
  {
    id: 1,
    name: 'Critical',
    value: '1',
    label: 'Critical',
  },
  {
    id: 2,
    name: 'High',
    value: '2',
    label: 'High',
  },
  {
    id: 3,
    name: 'Medium',
    value: '3',
    label: 'Medium',
  },
  {
    id: 4,
    name: 'Low',
    value: '4',
    label: 'Low',
  },
  {
    id: 5,
    name: 'Lowest',
    value: '5',
    label: 'Lowest',
  },
];
const getPriorityName = id => {
  let name = 'Lowest';
  if (id == 1) {
    name = 'Critical';
  } else if (id == 2) {
    name = 'High';
  } else if (id == 3) {
    name = 'Medium';
  } else if (id == 4) {
    name = 'Low';
  }
  return name;
};
const getPriorityColor = id => {
  let color = '#0000FF';
  if (id == 1) {
    color = '#FF0000';
  } else if (id == 2) {
    color = '#FFA500';
  } else if (id == 3) {
    color = '#FFFF00';
  } else if (id == 4) {
    color = '#008000';
  }
  return color;
};
const getPriorityTextColor = id => {
  let color = '#FFFFFF';
  if (id == 1) {
    color = '#FFFFFF';
  } else if (id == 2) {
    color = '#000000';
  } else if (id == 3) {
    color = '#000000';
  } else if (id == 4) {
    color = '#FFFFFF';
  }
  return color;
};
const customStyle = {
  option: (provided, isSelected) => ({
    ...provided,
    // color: isSelected ? 'red' : 'blue',
    width: 300,
  }),
  container: () => ({
    width: 300,
  }),
  control: () => ({}),
  menu: () => ({}),
  menuList: () => ({}),
};
const PriorityListDropdown = ({ currentPriority, cardData, priorityChangeHandler }) => {
  const [openPriorityMenu, setOpenPriorityMenu] = useState(false);
  const selectRef = useRef(null);
  const nodeRef = useRef(null);
  const [priority, setPriority] = useState(currentPriority);

  const handleSelectFocus = () => {
    setOpenPriorityMenu(!openPriorityMenu);
  };
  useEffect(() => {
    if (selectRef.current) {
      selectRef.current.focus();
    }
  }, [selectRef.current]);
  // useEffect(() => {
  //   if (openPriorityMenu) {
  //     selectRef.current.focus();
  //   }
  // }, openPriorityMenu);
  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  const handleClickOutside = event => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      setOpenPriorityMenu(false);
    }
  };
  const choosePriority = args => {
    setOpenPriorityMenu(false);
    setPriority(args);
    priorityChangeHandler(args, cardData.id);
  };
  return (
    <FormControl style={{ width: '100%' }} size="small">
      <div className="d-flex flex-row">
        <div style={{ width: '300px' }}>
          <div className="project-span ">
            {currentPriority ? (
              <div
                style={{
                  display: 'flex',
                  cursor: 'pointer',
                  flexDirection: 'row',
                  alignItems: 'center',
                  backgroundColor: getPriorityColor(currentPriority),
                  width: 'fit-content',
                  padding: '3px 12px',
                  borderRadius: '13px',
                  color: getPriorityTextColor(currentPriority),
                }}>
                <span onClick={handleSelectFocus} style={{ marginRight: '5px' }}>
                  {getPriorityName(currentPriority)}
                </span>
                <KeyboardArrowDownOutlinedIcon onClick={handleSelectFocus} />
              </div>
            ) : (
              <div
                className="selected-border teams-container"
                style={{
                  alignItems: 'center',
                  padding: '3px 12px',
                  fontSize: '14px',
                }}
                onClick={handleSelectFocus}>
                Priority Not Set
              </div>
            )}
            <div ref={nodeRef} className="select-drop">
              {openPriorityMenu && (
                <Select
                  ref={selectRef}
                  // styles={customDropdownStyles}
                  autoFocus={true}
                  openMenuOnFocus={true}
                  style={customStyle}
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  menuIsOpen={openPriorityMenu}
                  placeholder={'Select Priority..'}
                  options={priorityList}
                  value={priorityList.find(option => (option.value == priority ? priority.value : cardData.PriorityId))}
                  onChange={choosePriority}
                  // onBlur={false}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </FormControl>
  );
};

export default PriorityListDropdown;
