import React, { useState } from 'react';
import {
  Badge,
  Popover,
  List,
  ListItem,
  ListItemText,
  ListItemAvatar,
  Typography,
  Divider,
  IconButton,
  Checkbox,
} from '@mui/material';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';

const FilterMenu = ({
  userDetails,
  avatarClickFilter,
  SelectedTeamMembers,
  setCurrentValue,
  openAdvancedFilter,
  setOpenFilterDialog,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [quickFilterAnchorEl, setQuickFilterAnchorEl] = useState(null);
  const [memberFilterAnchorEl, setMemberFilterAnchorEl] = useState(null);
  const [selectedMembers, setSelectedMembers] = useState([]);

  const handleMenuOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setQuickFilterAnchorEl(null);
    setMemberFilterAnchorEl(null);
  };

  const handleQuickFilterOpen = event => {
    setQuickFilterAnchorEl(event.currentTarget);
  };

  const handleQuickFilterClose = () => {
    setQuickFilterAnchorEl(null);
  };

  const handleMemberFilterOpen = event => {
    setMemberFilterAnchorEl(event.currentTarget);
  };

  const handleMemberFilterClose = () => {
    setMemberFilterAnchorEl(null);
  };

  const handleMemberSelection = member => {
    if (selectedMembers.includes(member)) {
      setSelectedMembers(selectedMembers.filter(m => m !== member));
    } else {
      setSelectedMembers([...selectedMembers, member]);
    }
  };

  const applyMemberFilter = () => {
    avatarClickFilter(selectedMembers, 'Filter member wise');
    handleMemberFilterClose();
  };

  const resetFilter = () => {
    avatarClickFilter([]);
    setSelectedMembers([]);
    setCurrentValue(null);
    handleMenuClose();
  };

  // Add any additional reset logic here if required (e.g., resetting states)

  const dueDateThisWeek = () => {
    let user = SelectedTeamMembers.find(member => member.TMUserId === userDetails.UserId);
    avatarClickFilter(user, 'Tasks due this week');
    // avatarClickFilter(undefined, 'Incomplete tasks');

    handleQuickFilterClose();
  };

  const filterIncompleteTasks = () => {
    let user = SelectedTeamMembers.find(member => member.TMUserId === userDetails.UserId);
    avatarClickFilter(user, 'Incomplete tasks');
    // avatarClickFilter(undefined, 'Incomplete tasks');

    handleQuickFilterClose(); // Close the quick filter menu
  };

  const hanldeAdvanceFilter = () => {
    setOpenFilterDialog(preV => !preV);
  };

  const filterOptions = [
    { label: 'Quick filter', action: handleQuickFilterOpen },
    { label: 'Advanced filter', action: hanldeAdvanceFilter },
    { label: 'Filter member wise', action: handleMemberFilterOpen },
    { label: 'Reset Filter', action: resetFilter },
  ];

  const quickFilterOptions = [
    { name: 'Tasks due this week', action: dueDateThisWeek },
    { name: 'Incomplete tasks', action: filterIncompleteTasks },
  ];

  return (
    <div>
      {/* Badge with Filter Button */}
      <Badge badgeContent={0} color="primary">
        <IconButton color="primary" onClick={handleMenuOpen}>
          <FilterAltIcon fontSize="small" />
        </IconButton>
      </Badge>

      {/* Main Filter Menu */}
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: '200px',
            borderRadius: '6px',
            padding: 0,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}>
        <List sx={{ padding: 0 }}>
          {filterOptions.map((option, index) => (
            <React.Fragment key={index}>
              <ListItem
                onClick={event => {
                  option.action(event);
                }}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '6px 12px',
                  cursor: 'pointer',
                }}>
                <ListItemAvatar>
                  <FiberManualRecordIcon style={{ height: '10px', width: '10px', color: '#bdbdbd' }} />
                </ListItemAvatar>
                <ListItemText>
                  <Typography color="text.primary" sx={{ fontSize: '0.8rem', fontFamily: 'normal' }}>
                    {option.label}
                  </Typography>
                </ListItemText>
              </ListItem>
              {index < filterOptions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Popover>

      {/* Quick Filter Popover */}
      <Popover
        open={Boolean(quickFilterAnchorEl)}
        anchorEl={quickFilterAnchorEl}
        onClose={handleQuickFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: '200px',
            borderRadius: '6px',
            padding: 0,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}>
        <List sx={{ padding: 0 }}>
          {quickFilterOptions.map((option, index) => (
            <React.Fragment key={index}>
              <ListItem
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  padding: '6px 12px',
                  cursor: 'pointer',
                }}
                onClick={option.action}>
                <ListItemAvatar>
                  <FiberManualRecordIcon style={{ height: '10px', width: '10px', color: '#bdbdbd' }} />
                </ListItemAvatar>
                <ListItemText>
                  <Typography color="text.primary" sx={{ fontSize: '0.8rem', fontFamily: 'normal' }}>
                    {option.name}
                  </Typography>
                </ListItemText>
              </ListItem>
              {index < quickFilterOptions.length - 1 && <Divider />}
            </React.Fragment>
          ))}
        </List>
      </Popover>

      {/* Member Filter Popover */}
      <Popover
        open={Boolean(memberFilterAnchorEl)}
        anchorEl={memberFilterAnchorEl}
        onClose={handleMemberFilterClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        PaperProps={{
          sx: {
            minWidth: '250px',
            borderRadius: '6px',
            padding: 0,
            boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
          },
        }}>
        <List sx={{ padding: 0 }}>
          {SelectedTeamMembers.map((member, index) => (
            <ListItem
              key={index}
              style={{
                display: 'flex',
                flexDirection: 'row',
                padding: '6px 12px',
                cursor: 'pointer',
              }}>
              <Checkbox checked={selectedMembers.includes(member)} onChange={() => handleMemberSelection(member)} />
              <ListItemText>
                <Typography color="text.primary" sx={{ fontSize: '0.8rem', fontFamily: 'normal' }}>
                  {member.Email}
                </Typography>
              </ListItemText>
            </ListItem>
          ))}
          <Divider />
          <ListItem
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              padding: '6px 12px',
              cursor: 'pointer',
            }}
            onClick={applyMemberFilter}>
            <Typography color="primary" sx={{ fontSize: '0.8rem', fontFamily: 'normal' }}>
              Apply Filter
            </Typography>
          </ListItem>
        </List>
      </Popover>
    </div>
  );
};

export default FilterMenu;
