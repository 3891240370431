import React from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  outlookImg: {
    marginRight: '10px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
});
const ITEM_HEIGHT = 48;
const BankAccountRowMenu = ({ rowMenu = [], menuActionHandler, rowId }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);
  const classes = useRowStyles();
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSelectMenu = menuObj => {
    console.log(menuObj);
    menuActionHandler(menuObj.id, rowId);
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton aria-label="more" aria-controls="long-menu" aria-haspopup="true" onClick={handleClick}>
        <MoreHorizIcon />
      </IconButton>
      <Menu
        id="long-menu"
        anchorEl={anchorEl}
        keepMounted
        open={menuOpen}
        disableScrollLock={true}
        onClose={handleMenuClose}
        PaperProps={{
          style: {
            maxHeight: ITEM_HEIGHT * 4.5,
            width: '20ch',
          },
        }}>
        {rowMenu.map(menu => {
          let MenuIcon = menu.icon;
          return (
            <MenuItem
              key={menu.id}
              onClick={() => {
                handleSelectMenu(menu);
              }}>
              {MenuIcon ? <MenuIcon className={classes.pointer} /> : ''}
              {menu.label ? menu.label : ''}
            </MenuItem>
          );
        })}
        {/* <MenuItem
              onClick={() => {
                handleSelectMenu("delete")
                
              }}>
              <DeleteIcon className={classes.pointer} /> Delete
            </MenuItem> */}
      </Menu>
    </>
  );
};

export default BankAccountRowMenu;
