import React, { useEffect, useState } from 'react';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import { Typography, Card, CardContent, AvatarGroup, Tooltip } from '@mui/material';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import { makeStyles } from '@material-ui/styles';
import { fontSize } from '@mui/system';
import { Cursor } from '@amcharts/amcharts4/charts';
import Modal from '@mui/material/Modal';
import ShortcutTwoToneIcon from '@mui/icons-material/ShortcutTwoTone';
import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import { Button } from '@material-ui/core';
const ListViewStyles = makeStyles(theme => ({
  root: {
    background: 'white',

    '& .MuiList-root': {
      padding: 0,
    },
    '& .MuiListItem-root': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      cursor: 'pointer',
      paddingTop: '4px',
      paddingBottom: '4px',
      paddingRight: '8px',
      paddingLeft: '8px',
      '& .MuiListItemAvatar-root': {
        minWidth: '40px',
      },
      '& .MuiAvatar-root': {
        width: '30px',
        height: '30px',
        fontSize: '0.95rem',
      },
    },

    modal: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: 400,
      bgcolor: 'background.paper',
      border: 0,
      boxShadow: 24,
      borderRadius: '5px',

      '&:focus-visible': {
        outline: 'none',
      },
    },
  },
}));
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: 0,
  boxShadow: 24,
  outline: 'none',
  borderRadius: '5px',
  borderColor: 'hsl(0, 0%, 80%)',
};
Array.prototype.move = function(from, to) {
  this.splice(to, 0, this.splice(from, 1)[0]);
};

const QuickFilter = ({
  SelectedTeamMembers,
  avatarClickFilter,
  anchorEl,
  setAnchorEl,
  id,
  open,
  onClose,
  openFilterDialog,
  setOpenFilterDialog,
  setCurrentValue,
}) => {
  const [quickAnchorEl, setQuickAnchorEl] = React.useState(null);
  const [avatarIndex, setAvatarIndex] = useState(0);

  useEffect(() => {
    let isActive = false;
    let currIndex = 0;
    SelectedTeamMembers.map((item, index) => {
      if (item.ClassName === 'avatar-selected') {
        currIndex = index;
        isActive = true;
      }
    });

    if (isActive) {
      setAvatarIndex(currIndex);
    } else {
      setAvatarIndex(0);
    }
  }, [SelectedTeamMembers]);

  const onCloseQuick = event => {
    // console.log('on avatar click')
    setQuickAnchorEl(null);
  };

  // const handleClose = () => {
  //   setAnchorEl(!anchorEl);
  // };
  function stringAvatar(name) {
    return {
      // sx: {
      //   bgcolor: stringToColor(name),
      // },
      children: `${name.charAt(0)}${name.charAt(1)}`,
    };
  }
  const classes = ListViewStyles();
  const Quickopen = Boolean(quickAnchorEl);
  const QuickId = Quickopen ? 'simple-popover-one' : undefined;

  const token = JSON.parse(localStorage.getItem('token'));
  const userName = token?.userName;

  const setFilter = avatar => {
    let newItem = SelectedTeamMembers.filter(member => member.Email === userName);
    avatarClickFilter(newItem[0], avatar.name);
    onClose();
    onCloseQuick();
    setCurrentValue(1);
  };
  const getUpdateOrder = ar => {
    if (ar.length <= 4) return ar;
    // let newArr  = ar.splice(0, 0, ar.splice(avatarIndex, 1)[0])
    let newArr = [...ar];
    if (avatarIndex) {
      let curr = [...ar];
      curr.splice(0, 0, curr.splice(avatarIndex, 1)[0]);
      newArr = [...curr];
    }

    return newArr;
  };

  const avatarClickAction = (e, args, index) => {
    if (SelectedTeamMembers.length <= 4) {
      avatarClickFilter(args);
    } else {
      // handleClick(e);
    }
    // handleClick(e);
  };

  const handleClick = (avatar, event, index) => {
    if (avatar.label === 'Quick filter') {
      console.log('clearing');
      setQuickAnchorEl(event.currentTarget);
    } else if (avatar.label === 'Advanced filter') {
      setOpenFilterDialog(!openFilterDialog);
    }
    // setCurrentValue(index + 1);
  };

  const handleClick2 = () => {
    console.log('leaving');
    onCloseQuick();
    // setCurrentValue(index + 1);
  };

  const resetFilter = () => {
    avatarClickFilter([]);
    setCurrentValue(null);
  };

  const filterName = [
    {
      name: 'Tasks assigend to me',
      value: 'Tasks assigend to me',
    },
    {
      name: 'Tasks due this week',
      value: 'Tasks due this week',
    },
    {
      name: 'Incomplete tasks',
      value: 'Incomplete tasks',
    },
  ];

  const AddFilter = [
    {
      label: 'Quick filter',
      value: 'Quick filter',
    },
    {
      label: 'Advanced filter',
      value: 'Advanced filter',
    },
    {
      label: 'Reset Filter',
      value: 'Reset Filter',
    },
  ];

  return (
    <div>
      {/* <Modal
        open={anchorEl}
        onClose={handleClose}
        aria-labelledby="child-modal-title"
        aria-describedby="child-modal-description"> */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={onClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}>
        {/* <Box sx={style} className="filterModal"> */}
        <List
          sx={{
            padding: 0,
            cursor: 'pointer',
            // marginTop: '-12px',
            width: '250px',
          }}>
          {SelectedTeamMembers &&
            AddFilter.map((avatar, index) => {
              return (
                <ListItem
                  key={index}
                  divider={true}
                  aria-describedby={QuickId}
                  onMouseEnter={avatar.label === 'Quick filter' ? e => handleClick(avatar, e, index) : undefined}
                  onClick={avatar.label === 'Advanced filter' ? e => handleClick(avatar, e, index) : resetFilter}
                  style={{ display: 'flex', flexDirection: 'row', padding: '0.5rem' }}>
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <ListItemAvatar sx={{ minWidth: '20px', margin: 'auto' }}>
                      <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: '#bdbdbd', margin: 'auto' }} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography color="text.primary" sx={{ fontSize: '0.75rem', fontFamily: 'normal' }}>
                        {avatar.label}
                      </Typography>
                    </ListItemText>
                  </div>
                </ListItem>
              );
            })}
        </List>
      </Popover>
      <Popover
        id={QuickId}
        open={Quickopen}
        anchorEl={quickAnchorEl}
        onClose={onCloseQuick}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}>
        <List
          onMouseLeave={handleClick2}
          sx={{
            padding: 0,
            cursor: 'pointer',
            // marginTop: '-12px',
            width: '250px',
          }}>
          {SelectedTeamMembers &&
            filterName.map((avatar, index) => {
              return (
                <ListItem
                  key={index}
                  divider={true}
                  onClick={() => setFilter(avatar)}
                  style={{ display: 'flex', flexDirection: 'row', padding: '0.5rem' }}>
                  <div style={{ display: 'flex', flexDirection: 'row', marginLeft: '1rem' }}>
                    <ListItemAvatar sx={{ minWidth: '20px' }}>
                      <FiberManualRecordIcon style={{ height: '15px', width: '15px', color: '#bdbdbd', margin: 'auto' }} />
                    </ListItemAvatar>
                    <ListItemText>
                      <Typography color="text.primary" sx={{ fontSize: '0.75rem', fontFamily: 'normal' }}>
                        {avatar.name}
                      </Typography>
                    </ListItemText>
                  </div>
                </ListItem>
              );
            })}
        </List>
      </Popover>
      {/* </Box> */}

      {/* </Modal> */}
    </div>
  );
};

export default QuickFilter;
