import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';

import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { getStore } from '../../../../redux/store';
import { getDateText, getFormattedDate } from '@jumbo/utils/dateHelper';
import { Backdrop, Box, Button, Fade, Modal, TextField } from '@mui/material';
import { CalendarPicker } from '@mui/x-date-pickers';

const AddNewTask = ({
  type,
  name,
  id,
  fullWidth,
  size,
  value,
  onChange,
  onBlur,
  helperText,
  selectedCard,
  variant,
  error,
  AssignedTo,
  tDueDate,
  laneId,
  StatusId,
  tmTeamId,
  ProjectId,
  inputTeamChangeHandler,
  dueDate,
  handleDueDateChange,
  TeamId,
  selectMember,
  onCancel,
  ...rest
}) => {
  const [openTeam, setOpenTeam] = useState(false);
  const [assignTaskTeam, setAssignTaskTeam] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState(null);
  const [assigned, setAssigned] = React.useState([]);
  const [tmMember, setTMMember] = React.useState(null);
  const [updateDate, setUpdateDate] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const nodeRef = useRef(null);

  // const dispatch = useDispatch();
  const dispatch = getStore().dispatch;

  const customDropdownStyles = {
    menuList: () => ({
      overflow: 'hidden',
    }),
  };
  const store = getStore().getState();
  const { tmTeamMembers } = store.common;
  const { tmSelectedTeam } = store.common;
  // const { tmDatePickerPlag } = store.common;

  // console.log(tmDatePickerPlag, 'tmDatePickerPlag');

  const returnAvatarName = teamId => {
    const teamObj = tmTeamMembers.filter(c => c.TMUserId == teamId);
    if (teamObj.length > 0) {
      return teamObj[0].Email;
    }
    return 'None';
  };
  const chooseTeamHandler = args => {
    const member = tmTeamMembers.filter(member => member.Email === args.label);
    setTMMember(member);
    setOpenTeam(false);
    setAssigned(args.value);
    inputTeamChangeHandler(member);
  };
  const handleDueDateHandler = dateChange => {
    handleDueDateChange(new Date(dateChange));
    setOpen(prevstate => {
      return false;
    });
  };

  useEffect(() => {
    if (tmTeamMembers.length > 0) {
      var users = tmTeamMembers.map(c => ({
        value: c.TMUserId,
        label: c.Email,
        TeamId: c.TeamId,
        IsAdmin: c.IsAdmin,
      }));
      setAssignTaskTeam(users);
    }
    if (tmSelectedTeam) {
      setSelectedTeam(tmSelectedTeam);
    }
  }, [tmTeamMembers, tmSelectedTeam]);

  useEffect(() => {
    if (AssignedTo) {
      setAssigned(AssignedTo);
    }
  }, [AssignedTo]);

  const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
  };
  const handleClose = () => {
    setOpen(prevstate => {
      return false;
    });
  };
  const handleClickOutside = event => {
    if (nodeRef.current && !nodeRef.current.contains(event.target)) {
      return setOpenTeam(false);
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClickOutside, true);
    return () => {
      document.removeEventListener('click', handleClickOutside, true);
    };
  }, []);
  return (
    <div>
      <TextField
        {...rest}
        autoFocus
        type={type}
        name={name}
        id={id || name}
        size={size}
        fullWidth={fullWidth}
        value={value}
        variant={variant}
        onChange={onChange}
        // onBlur={onBlur}
        error={error || helperText !== ''}
        helperText={helperText}
      />
      <div style={{ marginTop: 15, display: 'flex', fontSize: 12, alignItems: 'center' }}>
        <p className="customAvatar" onClick={() => setOpenTeam(!openTeam)}>
          {assignTaskTeam && returnAvatarName(selectMember).substring(0, 2)}
        </p>
        <span className="dropdown-s" ref={nodeRef}>
          {openTeam && (
            <label style={{ position: 'absolute', bottom: 68, left: 44, width: '76%', zIndex: 9 }}>
              <Select
                styles={customDropdownStyles}
                menuIsOpen={openTeam}
                placeholder={'Select Team..'}
                options={assignTaskTeam}
                value={assignTaskTeam.find(c => c.id == selectMember)}
                onChange={chooseTeamHandler}
              />
            </label>
          )}
        </span>
        <div
          className="task-list-duedate"
          // style={{ display: 'flex', fontSize: 12, alignItems: 'center' }}
          onClick={() => {
            setOpen(true);
          }}>
          <CalendarMonthIcon
            style={{
              width: 27,
              height: 27,
              color: '#BDBDBD',
              cursor: 'pointer',
              marginTop: 0,
              paddingRight: 5,
              boxSizing: 'content-box',
            }}
          />
        </div>
        <Modal
          aria-labelledby="transition-modal-title"
          aria-describedby="transition-modal-description"
          open={open}
          onClose={handleClose}
          closeAfterTransition
          BackdropComponent={Backdrop}
          BackdropProps={{
            timeout: 500,
          }}>
          <Fade in={open}>
            <Box sx={modalStyle}>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <CalendarPicker date={dueDate} onChange={handleDueDateHandler} />
              </LocalizationProvider>
            </Box>
          </Fade>
        </Modal>
        {updateDate ? (
          <span className="card-date" style={{ marginRight: 5 }}>
            {getDateText(getFormattedDate(updateDate, 'MMM DD, YYYY'))}
          </span>
        ) : (
          dueDate && (
            <span className="card-date" style={{ marginRight: 5 }}>
              {getDateText(getFormattedDate(dueDate, 'MMM DD, YYYY'))}
            </span>
          )
        )}
      </div>
      <div style={{ marginTop: '1rem' }}>
        <Button
          type="submit"
          placeholder="Save"
          style={{ backgroundColor: '#1976d2', color: 'white', textTransform: 'none' }}
          onClick={onBlur}>
          Save
        </Button>
        <Button
          type="submit"
          placeholder="Cancel"
          onClick={onCancel}
          style={{ textTransform: 'none' }}
          // style={{ backgroundColor: '#bdbdbd', color: 'white' }}
        >
          Cancel
        </Button>
      </div>
    </div>
  );
};

AddNewTask.prototype = {
  type: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
  fullWidth: PropTypes.bool,
  value: PropTypes.string,
  variant: PropTypes.string,
  size: PropTypes.string,
  on: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.bool,
  AssignedTo: PropTypes.string,
  tDueDate: PropTypes.bool,
  laneId: PropTypes.string,
  StatusId: PropTypes.string,
  tmTeamId: PropTypes.string,
  ProjectId: PropTypes.string,
};

AddNewTask.defaultProps = {
  type: 'text',
  fullWidth: true,
  size: 'small',
  error: false,
  helperText: '',
  // id: '',
};

export default AddNewTask;
