/**
 * Version 2.0
 * Vikas W
 * 19.05.2022
 * -
 * This component appears when we want to add a new contact in the tab "Clients Overview"
 */
import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

import { useForm } from 'react-hook-form';

import {
  FETCH_ERROR,
  FETCH_START,
  FETCH_SUCCESS,
  // INIT_URL,
  // SIGNOUT_USER_SUCCESS,
  // USER_DATA,
  // USER_TOKEN_SET,
} from '../../constants/ActionTypes';
import customAxios from '../../util/Api';
import authHeader from '../../../../services/auth/jwt/auth-header';
import { Box, Card, CardContent, Dialog, DialogActions, DialogTitle, Menu, MenuItem } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import PictureAsPdf from '@material-ui/icons/PictureAsPdf';
import DescriptionIcon from '@mui/icons-material/Description'; //word excel text
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions'; //html

import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile'; //any other
import { DeleteOutline } from '@material-ui/icons';
import DownloadIcon from '@mui/icons-material/Download';

const useStyles = makeStyles(theme => ({
  errorMsg: {
    color: 'rgb(211, 47, 47)',
  },
  marginErr: {
    marginTop: 6,
    color: 'rgb(211, 47, 47)',
  },
}));
const textFieldStyles = makeStyles(theme => ({
  root: {
    '& .MuiInputBase-input.Mui-disabled': {
      color: 'black',
    },
    '& .MuiTextField-root': {
      // background:'black'
    },

    '& .MuiFormLabel-root.Mui-disabled': {
      color: 'rgba(0,0,0,0.6)',
    },
    '& .MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled': {
      '-webkit-text-fill-color': 'rgba(0,0,0,0.75)',
    },
  },
}));

const ContactAttachment = ({ info, attachmentData, setAttachmentData, getContactAttachment }) => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const {
    register,
    control,
    formState: { errors },
    handleSubmit,
    setError,
  } = useForm({});

  /**
   * This function is called after submiting the form
   *
   * @param formData
   */
  const onSubmit = data => {
    const formData = new FormData();
    const imagefile = document.querySelector('#file');
    console.log(imagefile.files[0], 'imagefile.files[0]');

    if (imagefile.files.length > 0) {
      formData.append('file', imagefile.files[0]);
    }
    // formData.append('CompanyLogoName', 'CompanyLogoName');
    // formData.append('ImageName', 'string');
    formData.append('CRM_ContactId', info.crmId);
    formData.append('crmTeamId', info.CrmTeamId);

    let postData = [];
    let url = 'ContactAttachment/AddContactAttachment';
    //  if (details.edit) {
    //    url = '/Company/EditCompany';
    //    formData['UserId'] = details.UserId;
    //    postData = [
    //      {
    //        TableName: 'Acct_Company',
    //        ColumnName: 'AddressLine1',
    //        NewValue: data['AddressLine1'],
    //        PrimaryKeyValue: details.Id.toString(),
    //      },

    //    ];
    //  } else {
    //    postData = formData;
    //  }
    postData = formData;
    console.log(formData, postData, 'odfjldjflsdjfkljdfklj');
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(data => {
        getContactAttachment();
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        if (error.response.status == '400') {
          alert(error.response.data.title);
          var errorList = error.response.data.errors;
          if (errorList) {
            //iterate through errors {} and add key values
            Object.keys(errorList).forEach((key, index) => {
              setError('apiError', {
                message: errorList[key],
              });
            });
          }
        }
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };
  useEffect(() => {
    console.log(info);
  });
  /**
   * Get the countries and add them to the combobox
   */

  const { getRootProps, getInputProps } = useDropzone({
    // accept: 'image/*',
    onDrop: acceptedFiles => {},
  });

  const deleteItem = itemInfo => {
    let url = 'ContactAttachment/DeleteContactAttachment';
    let postData = {
      ContactAttachmentId: itemInfo.Id,
      CRM_ContactId: itemInfo.crmContactId,
      crmTeamId: info.CrmTeamId,
    };
    dispatch({ type: FETCH_START });
    customAxios
      .post(url, postData, { headers: authHeader() })
      .then(() => {
        // getContactAttachment();
        let newData = attachmentData.filter(item => item.Id != itemInfo.Id);
        setAttachmentData(newData);
        dispatch({ type: FETCH_SUCCESS });
      })
      .catch(function(error) {
        dispatch({ type: FETCH_ERROR, payload: error.message });
      });
  };

  const downloadItem = (itemInfo, fileType) => {
    let blobArr = base64ToArrayBuffer(itemInfo.FileBytes);
    const blob = new Blob([blobArr], { type: `application/${fileType ? fileType : getFileType(itemInfo.AttachmentName)}` });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = itemInfo.AttachmentName;
    link.click();
  };
  const base64ToArrayBuffer = base64 => {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  };

  const getFileType = fName => {
    let itemName = fName.toLowerCase().split('.');
    let fileType = itemName[itemName.length - 1];
    let fileName = fName.substring(0, fName.length - fileType.length - 1);
  };
  return (
    <div>
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ paddingBottom: '4px', paddingTop: '2px', paddingLeft: '4px', paddingRight: '4px' }}>
        {errors.apiError && <div className="alert alert-danger mt-3 mb-0">{errors.apiError?.message}</div>}
        <div className="modal-box-content">
          <div className="row no-gutters">
            <div className="col-md-12 mb-3">
              <div>
                <input id="file" required={true} type="file" name="attachment" className="dropzone-card w-100 mb-2" />

                <div className="w-100 d-flex justify-content-end">
                  <Button size="small" variant="contained" style={{ color: 'white', background: '#3f51b5' }} type="submit">
                    Add
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>

      <Card variant="outlined" sx={{ minWidth: 275 }} style={{ marginBottom: '1rem' }}>
        <CardContent component="div">
          <Box
            component="form"
            sx={{
              '& .MuiTextField-root': { m: 1 },
            }}
            className={textFieldStyles().root}
            noValidate
            autoComplete="off">
            {attachmentData.map((item, index) => {
              return (
                <div className="mb-3 " key={index}>
                  <div style={{ width: 'fit-content' }}>
                    <GetAttachmentPreview itemData={item} deleteItem={deleteItem} downloadItem={downloadItem} />
                  </div>
                </div>
              );
            })}
          </Box>
        </CardContent>
      </Card>
    </div>
  );
};
const ITEM_HEIGHT = 48;
const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
  pointer: {
    cursor: 'pointer',
  },
  outlookImg: {
    marginRight: '10px',
    cursor: 'pointer',
    width: '24px',
    height: '24px',
  },
});
const GetAttachmentPreview = ({ itemData, deleteItem, downloadItem }) => {
  const AllImageType = ['jpeg', 'gif', 'png', 'apng', 'svg', 'bmp', 'avif', 'jpg', 'jfif', 'pjpeg', 'pjp', 'webp'];
  const [open, setOpen] = React.useState(false);
  const handelDialogOpen = () => {
    setOpen(true);
  };
  const handelDialogClose = () => {
    setOpen(false);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const menuOpen = Boolean(anchorEl);

  const handleClick = e => {
    setAnchorEl(e.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const getItemType = name => {
    let itemName = name.toLowerCase().split('.');
    let fileType = itemName[itemName.length - 1];
    let fileName = name.substring(0, name.length - fileType.length - 1);

    let fileIcon;
    let imgFile = false;
    if (isImage(fileType)) {
      imgFile = true;
      fileIcon = <img src={`data:image/${fileType};base64,${itemData.FileBytes}`} alt="image" height="100%" />;
    } else if (fileType == 'pdf') {
      fileIcon = <PictureAsPdf style={{ color: 'red', fontSize: '2.5rem' }} />;
    } else if (fileType == 'docx' || fileType == 'doc') {
      fileIcon = <DescriptionIcon style={{ color: 'blue', fontSize: '2.5rem' }} />;
    } else if (fileType == 'xlsx') {
      fileIcon = <DescriptionIcon style={{ color: 'green', fontSize: '2.5rem' }} />;
    } else if (fileType == 'html') {
      fileIcon = <IntegrationInstructionsIcon style={{ color: 'grey', fontSize: '2.5rem' }} />;
    } else {
      fileIcon = <InsertDriveFileIcon style={{ color: 'grey', fontSize: '2.5rem' }} />;
    }

    return getFileItemDisplay(fileIcon, fileName, fileType, itemData, imgFile);
  };
  const isImage = imgType => {
    let imgs = AllImageType.filter(item => item == imgType);
    return imgs.length > 0;
  };

  const getFileItemDisplay = (fileIcon, fileName, fileType, fileInfo, imgFile) => {
    return (
      <div>
        <div
          className={`d-flex mb-0 align-items-center ${imgFile ? 'flex-column' : ''}`}
          style={{
            gap: '5px',
            paddingBlock: '5px',
            paddingInline: '15px',
            borderRadius: '5px',
            border: '1px solid rgba(0,0,0,0.4)',
          }}>
          {imgFile ? (
            <div className="d-flex justify-content-center" style={{ width: '300px', height: '200px' }}>
              {fileIcon}
            </div>
          ) : (
            <div style={{ width: '50px' }}> {fileIcon}</div>
          )}

          <div className="d-flex justify-content-start w-100">
            <div className="d-flex  flex-column justify-content-start">
              <div style={{ fontSize: '16px', color: 'rgba(0,0,0,0.8)' }}>
                <span>{fileName}</span>
                <span>.{fileType}</span>
              </div>
              <div style={{ fontSize: '12px' }}>
                <span className="mr-2 subSpanButton" onClick={() => downloadItem(fileInfo, fileType)}>
                  Download
                </span>{' '}
                <span className="subSpanButton" onClick={handelDialogOpen}>
                  Delete
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div style={{ cursor: 'pointer' }}>
      {getItemType(itemData.AttachmentName)}
      <div>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={menuOpen}
          onClose={handleMenuClose}
          PaperProps={{
            style: {
              maxHeight: ITEM_HEIGHT * 4.5,
              width: '15ch',
              border: '1px solid rgba(0,0,0,0.4)',
            },
          }}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: -40,
            horizontal: 'left',
          }}>
          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              downloadItem(itemData);
            }}>
            <DownloadIcon /> Download
          </MenuItem>

          <MenuItem
            onClick={() => {
              setAnchorEl(null);
              // deleteItem(itemData)
              handelDialogOpen();
            }}>
            <DeleteOutline /> Delete
          </MenuItem>
        </Menu>
      </div>
      <div>
        <div>
          <Dialog
            open={open}
            onClose={handelDialogClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description">
            <DialogTitle id="alert-dialog-title">{'The Attachmnet will be deleted permanently'}</DialogTitle>

            <DialogActions>
              <Button onClick={handelDialogClose}>Cancel</Button>
              <Button
                onClick={() => {
                  deleteItem(itemData);
                  handelDialogClose();
                }}
                autoFocus>
                Ok
              </Button>
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </div>
  );
};

export default ContactAttachment;
